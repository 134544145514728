import { useEffect } from 'react'

const Snackbar = ({ snackbarText, showSnackbar }) => {
    
    const snackbarHandler = () => {
        var x = document.getElementById('snackbar')
        x.className = 'show'
        setTimeout(() => {
            x.className = x.className.replace('show', '')
        }, 4000)
    }
    
    useEffect(() => {
        if (showSnackbar) snackbarHandler()
    }, [showSnackbar])
    
    return (
        <div id='snackbar'>{snackbarText}</div>
    )
}

export default Snackbar
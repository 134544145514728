// Dummy data
export const data = [
  // ["Year", "Consumed", "Alloted", "Profit"],
  // ["Active", 1000, 400, 200],
  // ["Inactive", 1170, 460, 250],
  // ["Deactivated", 660, 1120, 300],
  // ["2017", 1030, 540, 350],

  ["Year", "Consumed", "Alloted"],
  ["Jan", 500, 200],
  ["Feb", 600, 700],
  ["Mar", 400, 800],
  ["April", 200, 300],
  ["May", 300, 500],
  ["Jun", 250, 900],
  ["Jul", 850, 650],
  ["Aug", 600, 600],
  ["Sep", 900, 200],
  ["Oct", 500, 700],
  ["Nov", 450, 150],
  ["Dec", 100, 200]
]

export const dataOptions = {
  title: "Alloted & Consumed Analysis",
  colors: ['#F03D3E', '#7DE495']
}

export const barData = [
  [
    "Element",
    "MB Used",

    { role: "style" },
    {
      sourceColumn: 0,
      role: "annotation",
      type: "string",
      calc: "stringify",
    },
  ],
  ["Jan", 500, "color: #106437", 'SIM1'],
  ["Feb", 600, "color: #106437", 'SIM2'],
  ["Mar", 400, "color: #106437", 'SIM3'],
  ["April", 200, "color: #106437", 'SIM4'],
  ["May", 300, "color: #106437", 'SIM5'],
  ["Jun", 250, "color: #106437", 'SIM6'],
  ["Jul", 850, "color: #106437", 'SIM7'],
  ["Aug", 600, "color: #106437", 'SIM8'],
  ["Sep", 900, "color: #106437", 'SIM9'],
  ["Oct", 500, "color: #106437", 'SIM10'],
  ["Nov", 450, "color: #106437", 'SIM11'],
  ["Dec", 300, "color: #106437", 'SIM12'],
]

export const barOptions = {
  title: "Data Usage",
  colors: ['#106437'],

  chart: {
    title: "Data Usage",
    subtitle: "MB Used"
  },
}

export const pieData = [
  ["Task", "Hours per Day"],
  ["Active", 12],
  ["Inactive", 8],
  ["Deactivated", 4] // CSS-style declaration
]

export const pieOptions = {
  title: "SIM Card Usage",
  pieHole: 0.4,
  is3D: false,
  colors: ['#7DE495', '#F03D3E', '#E1E1E1']
}

export const geoData = [
  ["Country", "Popularity"],
  ["Germany", 200],
  ["United States", 300],
  ["Brazil", 400],
  ["Canada", 500],
  ["France", 600],
  ["Nigeria", 700],
]




export const usedData =
  [
    {
      id: '1',
      deviceName: 'Gentro Device',
      iccid: 3763876837640,
      status: 'Active'
    },
    {
      id: '2',
      deviceName: 'Top Device',
      iccid: 3763876837640,
      status: 'Inactive'
    },
    {
      id: '3',
      deviceName: 'Ad Device',
      iccid: 3763876837640,
      status: 'Deactivated'
    }
  ]

export const simData =
  [
    {
      id: '1',
      status: 'active',
      iccid: '8355592898245637485',
      imsi: 233986956396598,
      imei: 98265289586259,
      plan: 'Regular',
      usage: '20MB',
      label: 'SIM1'
    },
    {
      id: '2',
      status: 'inactive',
      iccid: '8355592898245637485',
      imsi: 233986956396598,
      imei: 98265289586259,
      plan: 'Regular',
      usage: '20MB',
      label: 'SIM1'
    },
    {
      id: '3',
      status: 'suspended',
      iccid: '8355592898245637485',
      imsi: 233986956396598,
      imei: 98265289586259,
      plan: 'Premium',
      usage: '20MB',
      label: 'SIM1'
    },
    {
      id: '4',
      status: 'suspended',
      iccid: '8355592898245637485',
      imsi: 233986956396598,
      imei: 98265289586259,
      plan: 'Regular',
      usage: '20MB',
      label: 'SIM1'
    },
    {
      id: '5',
      status: 'inactive',
      iccid: '8355592898245637485',
      imsi: 233986956396598,
      imei: 98265289586259,
      plan: 'Regular',
      usage: '20MB',
      label: 'SIM1'
    },
    {
      id: '6',
      status: 'active',
      iccid: '8355592898245637485',
      imsi: 233986956396598,
      imei: 98265289586259,
      plan: 'Regular',
      usage: '20MB',
      label: 'SIM1'
    },
    {
      id: '7',
      status: 'suspended',
      iccid: '8355592898245637485',
      imsi: 233986956396598,
      imei: 98265289586259,
      plan: 'Premium',
      usage: '20MB',
      label: 'SIM1'
    }
  ]

export const purchasedSimData = [
  {
    id: '1',
    invoiceNumber: '0000063876',
    numberOfSim: 1,
    amount: 100.0,
    date: '03/08/2022',
    paymentStatus: 'PAID'
  },
  {
    id: '2',
    invoiceNumber: '0000063876',
    numberOfSim: 1,
    amount: 100.0,
    date: '02/08/2022',
    paymentStatus: 'PAID'
  },
  {
    id: '3',
    invoiceNumber: '0000063876',
    numberOfSim: 1,
    amount: 100.0,
    date: '01/08/2022',
    paymentStatus: 'PENDING'
  },
  {
    id: '4',
    invoiceNumber: '0000063876',
    numberOfSim: 1,
    amount: 100.0,
    date: '01/08/2022',
    paymentStatus: 'PAID'
  },
]

export const simPlanData = [
  {
    id: '1',
    status: 'active',
    name: 'SIM1',
    dataUsage: '10',
    smsUsage: '10',
    country: 'Nigeria',
    network: 'MTN NG'
  },
  {
    id: '2',
    status: 'inactive',
    name: 'SIM2',
    dataUsage: '10',
    smsUsage: '10',
    country: 'Nigeria',
    network: 'MTN NG'
  },
  {
    id: '3',
    status: 'active',
    name: 'SIM3',
    dataUsage: '10',
    smsUsage: '10',
    country: 'Nigeria',
    network: 'MTN NG'
  },
  {
    id: '4',
    status: 'active',
    name: 'SIM4',
    dataUsage: '10',
    smsUsage: '10',
    country: 'Nigeria',
    network: 'MTN NG'
  },
  {
    id: '5',
    status: 'suspended',
    name: 'SIM5',
    dataUsage: '10',
    smsUsage: '10',
    country: 'Nigeria',
    network: 'MTN NG'
  },
]

export const loraPlanData = [
  {
    id: '1',
    status: 'active',
    deviceName: 'Device1',
    euid: 'ACD45717817661',
    lastConnected: '10/09/2022 17:59',
    plan: 'Regular'
  },
  {
    id: '2',
    status: 'suspended',
    deviceName: 'Device2',
    euid: 'ACD45717817661',
    lastConnected: '10/09/2022 17:59',
    plan: 'Premium'
  },
  {
    id: '3',
    status: 'suspended',
    deviceName: 'Device3',
    euid: 'ACD45717817661',
    lastConnected: '10/09/2022 17:59',
    plan: 'Regular'
  },
  {
    id: '4',
    status: 'inactive',
    deviceName: 'Device4',
    euid: 'ACD45717817661',
    lastConnected: '10/09/2022 17:59',
    plan: 'Super'
  },
  {
    id: '5',
    status: 'active',
    deviceName: 'Device5',
    euid: 'ACD45717817661',
    lastConnected: '10/09/2022 17:59',
    plan: 'Business'
  },
  {
    id: '6',
    status: 'inactive',
    deviceName: 'Device6',
    euid: 'ACD45717817661',
    lastConnected: '10/09/2022 17:59',
    plan: 'Diamond'
  },
]

export const gatewayItem = [
  {
    id: 'lagos',
    gateWay: 'Lagos',
    checked: false,
    subGateWayItem: [
      {
        id: 'ikeja',
        subGateWay: 'Ikeja',
        checked: false,
      },
      {
        id: 'vi',
        subGateWay: 'Victoria Island',
        checked: false,
      },
      {
        id: 'lere',
        subGateWay: 'Surulere',
        checked: false,
      }
    ]
  },
  {
    id: 'oyo',
    gateWay: 'Oyo',
    checked: false,
    subGateWayItem: [
      {
        id: 'ibadan',
        subGateWay: 'Ibadan',
        checked: false,
      },
      {
        id: 'bodija',
        subGateWay: 'Bodija',
        checked: false,
      }
    ]
  },
]

export const historyData = [
  {
    id: '1',
    invoiceNumber: '1234567',
    amount: 100,
    date: '19/08/2022',
    email: 'paxian.solutions@gamil.com',
    status: 'Successful'
  },
  {
    id: '2',
    invoiceNumber: '1234567',
    amount: 850,
    date: '19/08/2022',
    email: 'paxian.solutions@gamil.com',
    status: 'Successful'
  },
  {
    id: '3',
    invoiceNumber: '1234567',
    amount: 150,
    date: '19/08/2022',
    email: 'paxian.solutions@gamil.com',
    status: 'Pending'
  },
  {
    id: '4',
    invoiceNumber: '1234567',
    amount: 50,
    date: '19/08/2022',
    email: 'paxian.solutions@gamil.com',
    status: 'Successful'
  },
  {
    id: '5',
    invoiceNumber: '1234567',
    amount: 1000,
    date: '19/08/2022',
    email: 'paxian.solutions@gamil.com',
    status: 'Successful'
  },
  {
    id: '6',
    invoiceNumber: '1234567',
    amount: 500,
    date: '19/08/2022',
    email: 'paxian.solutions@gamil.com',
    status: 'Pending'
  },
  {
    id: '7',
    invoiceNumber: '1234567',
    amount: 100,
    date: '19/08/2022',
    email: 'paxian.solutions@gamil.com',
    status: 'Successful'
  },
]

export const userData = [
  {
    id: '1',
    status: 'active',
    name: 'Emoroghene Umukoro',
    email: 'e_umukoro@gmail.com',
    userAccess: 'Admin'
  },
  {
    id: '2',
    status: 'suspended',
    name: 'Chukwuanu1 Akpo',
    email: 'chukwuanu1@gmail.com',
    userAccess: 'Super Admin'
  },
  {
    id: '3',
    status: 'inactive',
    name: 'Pinky & Brain',
    email: 'pinky_brain@gmail.com',
    userAccess: 'Admin'
  },
  {
    id: '4',
    status: 'active',
    name: 'Elsa Anna',
    email: 'e.a@gmail.com',
    userAccess: 'Admin'
  },
  {
    id: '5',
    status: 'suspended',
    name: 'Ovodidi Oghenetega',
    email: 'oo@gmail.com',
    userAccess: 'Super Admin'
  },
  {
    id: '6',
    status: 'inactive',
    name: 'John Smith',
    email: 'jonh.smith@gmail.com',
    userAccess: 'User'
  },
]

export const groupData = [
  {
    id: '1',
    status: 'active',
    name: 'SIM1',
    iccid: '94187986414988',
    imsi: '2856989568946'
  },
  {
    id: '2',
    status: 'suspended',
    name: 'SIM2',
    iccid: '94187986414988',
    imsi: '2856989568946'
  },
  {
    id: '3',
    status: 'inactive',
    name: 'SIM3',
    iccid: '94187986414988',
    imsi: '2856989568946'
  },
  {
    id: '4',
    status: 'active',
    name: 'SIM4',
    iccid: '94187986414988',
    imsi: '2856989568946'
  },
  {
    id: '5',
    status: 'suspended',
    name: 'SIM5',
    iccid: '94187986414988',
    imsi: '2856989568946'
  },
  {
    id: '6',
    status: 'inactive',
    name: 'SIM6',
    iccid: '94187986414988',
    imsi: '2856989568946'
  },
]

export const groups = [
  {
    id: '1',
    planName: 'Business SIMs',
    desc: 'The SIM cards on this group are connected for business use.',
    numberOfsims: 50
  },
  {
    id: '2',
    planName: 'Personal SIMs',
    desc: 'The SIM cards on this group are connected for personal use.',
    numberOfsims: 30
  },
  {
    id: '3',
    planName: 'Enterprise SIMs',
    desc: 'The SIM cards on this group are connected for enterprise use.',
    numberOfsims: 20
  },
  {
    id: '4',
    planName: 'Commercial SIMs',
    desc: 'The SIM cards on this group are connected for commercial use.',
    numberOfsims: 5
  },
  {
    id: '5',
    planName: 'Gentro SIMs',
    desc: 'The SIM cards on this group are connected for Gentro use.',
    numberOfsims: 10
  },
  {
    id: '6',
    planName: 'Client SIMs',
    desc: 'The SIM cards on this group are connected for client use.',
    numberOfsims: 15
  },
]

export const plans = [
  {
    id: '1',
    size: 50,
    units: 'MB',
    subscription: 'Monthly Renewal',
    type: 'Regular Plan'
  },
  {
    id: '2',
    size: 100,
    units: 'GB',
    subscription: 'Monthly Renewal',
    type: 'Super Plan'
  },
  {
    id: '3',
    size: 200,
    units: 'MB',
    subscription: 'Monthly Renewal',
    type: 'Diamond Plan'
  },
  {
    id: '4',
    size: 50,
    units: 'GB',
    subscription: 'Monthly Renewal',
    type: 'Premium Plan'
  },
]

export const networkData = [
  {
    id: '1',
    label: 'MTN NG',
    isChecked: false
  },
  {
    id: '2',
    label: 'Airtel Ng',
    isChecked: false
  },
  {
    id: '3',
    label: 'Glo',
    isChecked: false
  },
  {
    id: '4',
    label: '9Mobile',
    isChecked: false
  },
]

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isSimItemDropdown: false,
    isManageSimSelected: false,
    isSimPurchaseSelected: false,
    isSimInventorySelected: false,
    isSimClicked: false,
    windowDimension: {
        winWidth: window.innerWidth,
        winHeight: window.innerHeight
    },
    dashItem: '',
    simItem: '',
    manageSimItem: '',
    purchaseSimItem: '',
    simInventoryItem: '',
    simPlansItem: '',
    loraItem: '',
    loraPlansItem: '',
    walletItem: '',
    userItem: '',
    groupItem: '',
    settingsItem: '',

    myPlansClicked: false,
    createNewPlansClicked: false,
    editPlan: false,
    isDeletePlan: false,

    mySimPlanStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',
    createNewPlanStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',

    simSpecsStyle: 'ml-3 mb-3 btn btn-color-dark btn-control text-white shadow-sm',
    shippingAddressStyle: 'ml-3 mb-3 btn btn-color-dark btn-control text-white shadow-sm',
    checkoutStyle: 'mr-3 ml-3 mb-3 btn btn-color-dark btn-control text-white shadow-sm',

    simSpecsNextClicked: false,
    shippingAddressConfirmed: false,
    isCheckout: false,

    isViewClicked: false,
    viewData: {},
    isProfileDropdown: false,

    isOnline: true,
    exchangeRate: 429.18,

    walletStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',
    fundWalletStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',
    historyStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',

    isWalletClicked: false,
    isFundWalletClicked: false,
    isHistoryClicked: false,

    fundWalletText: 'FUND WALLET',

    isPaynowClicked: false,

    currency: '₦',
    curreny_text: 'NGN',

    email: '',

    usersStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',
    createNewUserStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',

    allUsersClicked: false,
    createNewUsersClicked: false,

    myGroupStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',
    createNewGroupStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',

    isGroupClicked: false,
    isCreateNewGroupClicked: false,
    isViewGroupClicked: false,
    editGroup: false,

    viewGroupData: {},

    isDeleteGroup: false,

    peronslProfileStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',
    buinessProfileStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',
    passwordStyle: 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3',

    personalProfileClicked: false,
    businessProfileClicked: false,
    passwordSettingsClicked: false,

    networkData: [],
    gatewayArray: [],
    checkedGateway: ''
}

export const appSlice = createSlice({
    name: 'app',
    initialState: { value: initialState },
    reducers: {
        setCheckedGateway: (state, action) => {
            state.value.checkedGateway = action.payload
        },
        setNetworkData: (state, action) => {
            state.value.networkData = action.payload
        },
        setGatewaykData: (state, action) => {
            state.value.gatewayArray = action.payload
        },

        setPersonalProfile: (state, action) => {
            state.value.peronslProfileStyle = 'btn btn-color btn-control text-light mr-3 mb-3'
            state.value.buinessProfileStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
            state.value.passwordStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'

            state.value.personalProfileClicked = true
            state.value.businessProfileClicked = false
            state.value.passwordSettingsClicked = false
        },
        setBusinessProfile: (state, action) => {
            state.value.peronslProfileStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
            state.value.buinessProfileStyle = 'btn btn-color btn-control text-light mr-3 mb-3'
            state.value.passwordStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'

            state.value.personalProfileClicked = false
            state.value.businessProfileClicked = true
            state.value.passwordSettingsClicked = false
        },
        setPasswordSettings: (state, action) => {
            state.value.peronslProfileStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
            state.value.buinessProfileStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
            state.value.passwordStyle = 'btn btn-color btn-control text-light mr-3 mb-3'

            state.value.personalProfileClicked = false
            state.value.businessProfileClicked = false
            state.value.passwordSettingsClicked = true
        },

        setDeleteGroup: (state, action) => {
            state.value.isDeleteGroup = action.payload
        },

        setViewGroupData: (state, action) => {
            state.value.viewGroupData = action.payload
        },

        setEditGroupClicked: (state, action) => {
            state.value.editGroup = action.payload
        },
        setViewGroupClicked: (state, action) => {
            state.value.isViewGroupClicked = action.payload
        },

        setMyGroup: (state, action) => {
            state.value.myGroupStyle = 'btn btn-color btn-control text-light mr-3 mb-3'
            state.value.createNewGroupStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'

            state.value.isGroupClicked = true
            state.value.isCreateNewGroupClicked = false
        },
        setCreateNewGroup: (state, action) => {
            state.value.createNewGroupStyle = 'btn btn-color btn-control text-light mr-3 mb-3'
            state.value.myGroupStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'

            state.value.isCreateNewGroupClicked = true
            state.value.isGroupClicked = false
        },

        setUsers: (state, action) => {
            state.value.usersStyle = 'btn btn-color btn-control text-light mr-3 mb-3'
            state.value.createNewUserStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'

            state.value.allUsersClicked = true
            state.value.createNewUsersClicked = false
        },
        setNewUser: (state, action) => {
            state.value.usersStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
            state.value.createNewUserStyle = 'btn btn-color btn-control text-light mr-3 mb-3'

            state.value.allUsersClicked = false
            state.value.createNewUsersClicked = true
        },

        setEmail: (state, action) => {
            state.value.email = action.payload
        },

        setPaynowClicked: (state, action) => {
            state.value.isPaynowClicked = action.payload
        },

        setCurrency: (state, action) => {
            state.value.currency = action.payload
            if (state.value.currency == '₦') state.value.curreny_text = 'NGN'
            else state.value.curreny_text = 'USD'
        },

        setWallet: (state, action) => {
            state.value.walletStyle = 'mr-3 mb-3 btn btn-color btn-control text-white shadow-sm'

            state.value.fundWalletText = initialState.fundWalletText

            state.value.isPaynowClicked = false

            state.value.isWalletClicked = true
            state.value.isFundWalletClicked = false
            state.value.isHistoryClicked = false

            state.value.fundWalletStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
            state.value.historyStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
        },
        setFundWallet: (state, action) => {
            state.value.fundWalletStyle = 'mr-3 mb-3 btn btn-color btn-control text-white shadow-sm'

            state.value.fundWalletText = action.payload

            // state.value.paynowClicked = action.payload

            state.value.isWalletClicked = false
            state.value.isFundWalletClicked = true
            state.value.isHistoryClicked = false

            state.value.walletStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
            state.value.historyStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
        },
        setHistory: (state, action) => {
            state.value.historyStyle = 'mr-3 mb-3 btn btn-color btn-control text-white shadow-sm'

            state.value.fundWalletText = initialState.fundWalletText

            state.value.isPaynowClicked = false

            state.value.isWalletClicked = false
            state.value.isFundWalletClicked = false
            state.value.isHistoryClicked = true

            state.value.walletStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
            state.value.fundWalletStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
        },

        setExchangeRate: (state, action) => {
            state.value.exchangeRate = action.payload
        },

        setNetworkStatus: (state, action) => {
            state.value.isOnline = action.payload
        },

        setProfileDropdown: (state, action) => {
            state.value.isProfileDropdown = action.payload
        },

        setSimItemDropdown: (state, action) => {
            state.value.isSimItemDropdown = action.payload
        },
        setViewClicked: (state, action) => {
            state.value.isViewClicked = action.payload
        },
        setViewPlanData: (state, action) => {
            state.value.viewData = action.payload
        },

        setSimSpecsStyle: (state, action) => {
            state.value.simSpecsStyle = 'ml-3 mb-3 btn btn-color btn-control text-white shadow-sm'
            state.value.shippingAddressStyle = 'ml-3 mb-3 btn btn-color-dark btn-control text-white shadow-sm'
            state.value.checkoutStyle = 'mr-3 ml-3 mb-3 btn btn-color-dark btn-control text-white shadow-sm'

            state.value.simSpecsNextClicked = true
            state.value.shippingAddressConfirmed = false
            state.value.isCheckout = false

        },
        setShippingAddressStyle: (state, action) => {
            state.value.simSpecsStyle = 'ml-3 mb-3 btn btn-color-dark btn-control text-white shadow-sm'
            state.value.shippingAddressStyle = 'ml-3 mb-3 btn btn-color btn-control text-white shadow-sm'
            state.value.checkoutStyle = 'mr-3 ml-3 mb-3 btn btn-color-dark btn-control text-white shadow-sm'

            state.value.simSpecsNextClicked = false
            state.value.shippingAddressConfirmed = true
            state.value.isCheckout = false

        },
        setCheckoutStyle: (state, action) => {
            state.value.simSpecsStyle = 'ml-3 mb-3 btn btn-color-dark btn-control text-white shadow-sm'
            state.value.shippingAddressStyle = 'ml-3 mb-3 btn btn-color-dark btn-control text-white shadow-sm'
            state.value.checkoutStyle = 'mr-3 ml-3 mb-3 btn btn-color btn-control text-white shadow-sm'

            state.value.simSpecsNextClicked = false
            state.value.shippingAddressConfirmed = false
            state.value.isCheckout = true

        },

        setMyPlans: (state, action) => {
            state.value.mySimPlanStyle = 'btn btn-color btn-control text-light mr-3 mb-3'
            state.value.createNewPlanStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'

            state.value.myPlansClicked = true
            state.value.createNewPlansClicked = false

            state.value.editPlan = false
        },
        setCreateNewPlans: (state, action) => {
            state.value.mySimPlanStyle = 'btn btn-light btn-control text-dark shadow-sm mr-3 mb-3'
            state.value.createNewPlanStyle = 'btn btn-color btn-control text-light mr-3 mb-3'

            state.value.myPlansClicked = false
            state.value.createNewPlansClicked = true

            state.value.editPlan = false
        },
        setEditPlan: (state, action) => {
            state.value.editPlan = true
            state.value.createNewPlansClicked = false
            state.value.myPlansClicked = false
        },
        setDeletePlan: (state, action) => {
            state.value.isDeletePlan = action.payload
        },


        setDashItem: (state, action) => {
            state.value.dashItem = '#CFF4D2'
            state.value.simItem = ''
            state.value.manageSimItem = ''
            state.value.purchaseSimItem = ''
            state.value.simInventoryItem = ''
            state.value.simPlansItem = ''
            state.value.loraItem = ''
            state.value.loraPlansItem = ''
            state.value.walletItem = ''
            state.value.userItem = ''
            state.value.groupItem = ''
            state.value.settingsItem = ''
        },
        setSimItem: (state, action) => {
            state.value.dashItem = ''
            state.value.simItem = '#CFF4D2'

            // state.value.manageSimItem = ''
            // state.value.purchaseSimItem = ''
            // state.value.simInventoryItem = ''

            state.value.simPlansItem = ''
            state.value.loraItem = ''
            state.value.loraPlansItem = ''
            state.value.walletItem = ''
            state.value.userItem = ''
            state.value.groupItem = ''
            state.value.settingsItem = ''
        },
        setManageSimItem: (state, action) => {
            state.value.dashItem = ''
            // state.value.simItem = ''

            state.value.manageSimItem = '#CFF4D2'
            state.value.purchaseSimItem = ''
            state.value.simInventoryItem = ''

            state.value.simPlansItem = ''
            state.value.loraItem = ''
            state.value.loraPlansItem = ''
            state.value.walletItem = ''
            state.value.userItem = ''
            state.value.groupItem = ''
            state.value.settingsItem = ''
        },
        setPurchaseSimItem: (state, action) => {
            state.value.dashItem = ''
            // state.value.simItem = ''

            state.value.manageSimItem = ''
            state.value.purchaseSimItem = '#CFF4D2'
            state.value.simInventoryItem = ''

            state.value.simPlansItem = ''
            state.value.loraItem = ''
            state.value.loraPlansItem = ''
            state.value.walletItem = ''
            state.value.userItem = ''
            state.value.groupItem = ''
            state.value.settingsItem = ''
        },
        setSimInventoryItem: (state, action) => {
            state.value.dashItem = ''
            // state.value.simItem = ''

            state.value.manageSimItem = ''
            state.value.purchaseSimItem = ''
            state.value.simInventoryItem = '#CFF4D2'

            state.value.simPlansItem = ''
            state.value.loraItem = ''
            state.value.loraPlansItem = ''
            state.value.walletItem = ''
            state.value.userItem = ''
            state.value.groupItem = ''
            state.value.settingsItem = ''
        },
        setSimPlansItem: (state, action) => {
            state.value.dashItem = ''
            state.value.simItem = ''
            state.value.manageSimItem = ''
            state.value.purchaseSimItem = ''
            state.value.simInventoryItem = ''
            state.value.simPlansItem = '#CFF4D2'
            state.value.loraItem = ''
            state.value.loraPlansItem = ''
            state.value.walletItem = ''
            state.value.userItem = ''
            state.value.groupItem = ''
            state.value.settingsItem = ''
        },
        setLoraItem: (state, action) => {
            state.value.dashItem = ''
            state.value.simItem = ''
            state.value.manageSimItem = ''
            state.value.purchaseSimItem = ''
            state.value.simInventoryItem = ''
            state.value.simPlansItem = ''
            state.value.loraItem = '#CFF4D2'
            state.value.loraPlansItem = ''
            state.value.walletItem = ''
            state.value.userItem = ''
            state.value.groupItem = ''
            state.value.settingsItem = ''
        },
        setLoraPlansItem: (state, action) => {
            state.value.dashItem = ''
            state.value.simItem = ''
            state.value.manageSimItem = ''
            state.value.purchaseSimItem = ''
            state.value.simInventoryItem = ''
            state.value.simPlansItem = ''
            state.value.loraItem = ''
            state.value.loraPlansItem = '#CFF4D2'
            state.value.walletItem = ''
            state.value.userItem = ''
            state.value.groupItem = ''
            state.value.settingsItem = ''
        },
        setWalletItem: (state, action) => {
            state.value.dashItem = ''
            state.value.simItem = ''
            state.value.manageSimItem = ''
            state.value.purchaseSimItem = ''
            state.value.simInventoryItem = ''
            state.value.simPlansItem = ''
            state.value.loraItem = ''
            state.value.loraPlansItem = ''
            state.value.walletItem = '#CFF4D2'
            state.value.userItem = ''
            state.value.groupItem = ''
            state.value.settingsItem = ''
        },
        setUserItem: (state, action) => {
            state.value.dashItem = ''
            state.value.simItem = ''
            state.value.manageSimItem = ''
            state.value.purchaseSimItem = ''
            state.value.simInventoryItem = ''
            state.value.simPlansItem = ''
            state.value.loraItem = ''
            state.value.loraPlansItem = ''
            state.value.walletItem = ''
            state.value.userItem = '#CFF4D2'
            state.value.groupItem = ''
            state.value.settingsItem = ''
        },
        setGroupItem: (state, action) => {
            state.value.dashItem = ''
            state.value.simItem = ''
            state.value.manageSimItem = ''
            state.value.purchaseSimItem = ''
            state.value.simInventoryItem = ''
            state.value.simPlansItem = ''
            state.value.loraItem = ''
            state.value.loraPlansItem = ''
            state.value.walletItem = ''
            state.value.userItem = ''
            state.value.groupItem = '#CFF4D2'
            state.value.settingsItem = ''
        },
        setSettingsItem: (state, action) => {
            state.value.dashItem = ''
            state.value.simItem = ''
            state.value.manageSimItem = ''
            state.value.purchaseSimItem = ''
            state.value.simInventoryItem = ''
            state.value.simPlansItem = ''
            state.value.loraItem = ''
            state.value.loraPlansItem = ''
            state.value.walletItem = ''
            state.value.userItem = ''
            state.value.groupItem = ''
            state.value.settingsItem = '#CFF4D2'
        },

        getDimensions: (state, action) => {
            state.value.windowDimension = action.payload
        },
        setManageSimSelected: (state, action) => {
            state.value.isManageSimSelected = action.payload
            state.value.isSimPurchaseSelected = false
            state.value.isSimInventorySelected = false
        },
        setSimPurchaseSelected: (state, action) => {
            state.value.isSimPurchaseSelected = action.payload
            state.value.isSimInventorySelected = false
            state.value.isManageSimSelected = false
        },
        setSimInventorySelected: (state, action) => {
            state.value.isSimInventorySelected = action.payload
            state.value.isManageSimSelected = false
            state.value.isSimPurchaseSelected = false
        },
        setSimClicked: (state, action) => {
            state.value.isSimClicked = action.payload
        }
    }
})

export const {
    setSimItemDropdown,
    setManageSimSelected,
    setSimPurchaseSelected,
    setSimInventorySelected,

    setSimClicked,
    getDimensions,

    setDashItem,
    setSimItem,
    setManageSimItem,
    setPurchaseSimItem,
    setSimInventoryItem,
    setSimPlansItem,
    setLoraItem,
    setLoraPlansItem,
    setWalletItem,
    setUserItem,
    setGroupItem,
    setSettingsItem,
    setMyPlans,
    setCreateNewPlans,
    setEditPlan,
    setDeletePlan,

    setSimSpecsStyle,
    setShippingAddressStyle,
    setCheckoutStyle,

    setViewClicked,
    setViewPlanData,
    setProfileDropdown,

    setNetworkStatus,
    setExchangeRate,

    setWallet,
    setFundWallet,
    setHistory,

    setPaynowClicked,

    setCurrency,

    setEmail,

    setUsers,
    setNewUser,

    setMyGroup,
    setCreateNewGroup,

    setEditGroupClicked,
    setViewGroupClicked,
    
    setViewGroupData,

    setDeleteGroup,

    setPersonalProfile,
    setBusinessProfile,
    setPasswordSettings,

    setNetworkData,
    setGatewaykData,
    setCheckedGateway

} = appSlice.actions

export default appSlice.reducer
import { motion } from 'framer-motion'
import CheckBox from '../../../common/CheckBox'
import { historyData } from '../../data/dataJson'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CurrencyFormat from 'react-currency-format'
import Spinner from '../../../common/Spinner'
import SuccessModal from '../../../common/SuccessModal'

const History = () => {

  const { exchangeRate, curreny_text } = useSelector(state => state.app.value)

  const [value, setValue] = useState('')
  const [isHistory, setHistory] = useState(false)
  const [isDownloadClicked, setDownloadCLicked] = useState(false)
  const [isDeactivated, setDeactivated] = useState(false)
  const [successModal, showSuccessModal] = useState(false)
  
  const onBackClickHandler = () => {
    if(!isDownloadClicked) setHistory(true)
  }

  const onViewHandler = (data) => {
    setHistory(false)
  }

  const onDownloadClicked = () => {
    setDownloadCLicked(true)

    setTimeout(() => {
      showSuccessModal(true)
      setDownloadCLicked(false)
    }, 3000);
  }

  const onModalHideHandler = () => {
    showSuccessModal(false)
    setHistory(true)
  }

  const DownloadButton = () => {
    return (
      <div>
        {isDownloadClicked
          ?
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}
            className="mr-4 mb-4 btn btn-light text-white"
            style={{ height: '3rem', width: '10rem', borderRadius: '10px' }}
          >
            <Spinner />
          </motion.button>
          :
          <div
            className="mb-4 mr-4 btn btn-color text-white"
            style={{ height: '3rem', width: '10rem', borderRadius: '10px' }}
            onClick={onDownloadClicked}
          >
            <p className="mt-1">Download</p>
          </div>
        }
      </div>
    )
  }

  useEffect(() => {
    setHistory(true)
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className='container-fluid'
    >
      <SuccessModal
        message='Successfully downloaded!'
        show={successModal}
        onHideHandler={onModalHideHandler}
      />

      <div className='shadow-sm p-2 bg-white text-dark rounded mt-5' style={{ height: '100%' }}>
        <div className='container-fluid'>
          <div className='container-fluid'>

            {/* History */}
            {
              isHistory &&
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.5 } }}
                exit={{ opacity: 0 }}
              >
                <div className='text-left mt-3 mb-3'>
                  <h5 className="font-weight-bold">Transaction History</h5>
                </div>

                <table className='table shadow-sm p-1 mb-4 mt-5 bg-white text-dark rounded'>
                  <thead>
                    <tr>
                      <th scope='col'></th>
                      <th scope='col'></th>
                      <th scope='col'>INVOICE NO</th>
                      <th scope='col'>AMOUNT ({curreny_text})</th>
                      <th scope='col'>DATE</th>
                      <th scope='col'>EMAIL</th>
                      <th scope='col'>STATUS</th>
                      <th scope='col'></th>
                    </tr>
                  </thead>

                  {
                    historyData.map(datum => (
                      <tbody key={datum.id}>
                        <tr>
                          <td></td>
                          <td>
                            <CheckBox
                              id={datum.id}
                              value={value}
                              onCheckedHandler={() => { }}
                            />
                          </td>
                          <td>{datum.invoiceNumber}</td>
                          <td>
                            <CurrencyFormat
                              value={curreny_text == 'NGN' ? datum.amount * exchangeRate : datum.amount}
                              displayType={'text'}
                              isNumericString={true}
                              thousandSeparator={true}
                              renderText={(value) => <div>{value}</div>}
                            />
                          </td>
                          <td>{datum.date}</td>
                          <td>{datum.email}</td>
                          <td>{datum.status}</td>
                          <td>
                            <div
                              className='btn button-style'
                              style={{ height: '35px', width: '5rem', fontSize: '15px' }}
                              onClick={() => onViewHandler(datum)}
                            >
                              VIEW
                            </div>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    ))
                  }
                </table>
              </motion.div>
            }

            {/* Reciept */}
            {
              !isHistory &&
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.5 } }}
                exit={{ opacity: 0 }}
              >
                <div className="text-left mt-3 mb-3">
                  <h5 className='font-weight-bold'>Transaction Reciept</h5>
                </div>
                <div className="row text-left mt-4">
                  <div className="col-md-3 txt-color-light">
                    <h5>Transaction Date</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>19/09/2022 23:00</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Reciept Number</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>19386568936981853</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Name</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>Paxian Pi</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Email</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>paxian.solutions@gmail.com</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Transaction Type</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>Wallet funds</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Status</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>Successful</h5>
                  </div>
                </div>

                <div className="text-left mt-5">
                  <div
                    className="mb-4 mr-4 btn button-style text-dark"
                    style={{ height: '3rem', width: '10rem', borderRadius: '10px' }}
                    onClick={onBackClickHandler}
                  >
                    <p className="mt-1">Back</p>
                  </div>

                  <span className='btn'>
                    <DownloadButton />
                  </span>

                </div>
              </motion.div>
            }

          </div>
        </div>
      </div>

    </motion.div>
  )
}

export default History
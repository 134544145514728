import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FloatingLabelTextInput from '../common/FloatingLabelTextInput'
import logoGreen from '../assets/logo_green.png'
import checkEmail from '../assets/check_email.png'
import success from '../assets/success_verification.png'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import Spinner from '../common/Spinner'


const ForgotPassword = () => {

  const { errorEmail, errorPassword } = useSelector((state) => state.error.value)

  const [email, setEmail] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [isSuccessfulReset, setIsSuccessfulReset] = useState('')

  const [isDeactivated, setDeactivated] = useState(false)

  const [isSendClicked, setIsSendClicked] = useState(false)
  const [isLinkSent, setIsLinkSent] = useState(false)
  const [isLinkClicked, setIsLinkClicked] = useState(false)

  const navigate = useNavigate()

  const cancelHandler = () => {
    navigate('/')
  }

  const sendHandler = () => {
    setIsSendClicked(true)
    setDeactivated(true)

    setTimeout(() => {
      setIsSendClicked(false)
      setDeactivated(false)
      setIsLinkSent(true)
    }, 3000);
  }

  const SendButton = () => {
    return (
      <div>
        <div className='row'>
          <div className='col-6'>
            <button className="btn btn-color-light btn-block btn-control text-dark mt-5 mb-5" onClick={cancelHandler}>
              Cancel
            </button>
          </div>
          <div className='col-6'>
            {isSendClicked
              ?
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0 }}
                className="btn btn-light btn-block text-light mt-5"
                disabled={isDeactivated}
              >
                {/* <Spinner text='Logging in...' textStyle='ml-3 text-dark text-center'/> */}
                <Spinner />
              </motion.button>
              :
              <button className="btn btn-color btn-block btn-control text-light mt-5" onClick={sendHandler}>
                Send
              </button>
            }
          </div>
        </div>
      </div>
    )
  }

  const newPasswordSendHandler = () => {
    setIsSendClicked(true)
    setDeactivated(true)

    setTimeout(() => {
      setIsSuccessfulReset(true)

      setTimeout(() => {
        navigate('/')

      }, 3000);
    }, 3000);
  }
  
  const NewPasswordSendButton = () => {
    return (
      <div>
        <div className='row'>
          <div className='col-6'>
            <button className="btn btn-color-light btn-block btn-control text-dark mt-5 mb-5" onClick={cancelHandler}>
              Cancel
            </button>
          </div>
          <div className='col-6'>
            {isSendClicked
              ?
              <motion.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1 } }}
                exit={{ opacity: 0 }}
                className="btn btn-light btn-block text-light mt-5"
                disabled={isDeactivated}
              >
                {/* <Spinner text='Logging in...' textStyle='ml-3 text-dark text-center'/> */}
                <Spinner />
              </motion.button>
              :
              <button className="btn btn-color btn-block btn-control text-light mt-5" onClick={newPasswordSendHandler}>
                Send
              </button>
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0 }}
      className="container-fluid"
    >
      <div className="row">
        <div className="col-md-6 bg-login" id='mobile_only' />
        <div className="col-md-6 mt-5">
          <div className='text-left ml-3 mb-5'>
            <img src={logoGreen} alt='logo' />
          </div>


          {
            isLinkSent &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 2 } }}
              exit={{ opacity: 0 }}
            >
              {
                !isLinkClicked && <>
                  <div className='text-center mb-2 txt-color'>
                    <h5>Check Your Email</h5>
                  </div>

                  <div className='text-center mb-2'>
                    <img src={checkEmail} alt='logo' />
                  </div>

                  <div className='text-center mt-5 mb-5 txt-color'>
                    <small onClick={() => setIsLinkClicked(true)}>A reset link has been sent, click on the link to continue!</small>
                  </div>
                </>
              }

            </motion.div>

          }

          {
            !isLinkSent && <div>
              <div className='text-left ml-3 mb-1'>
                <h4>Forgot password?</h4>
              </div>
              <div className='text-left ml-3 mb-5'>
                <small>Enter your registered email address below, to recieve a reset link!</small>
              </div>
              <div className='container m-auto'>
                <FloatingLabelTextInput
                  label='Email'
                  type='email'
                  value={email}
                  onChangeHandler={(e) => setEmail(e.target.value)}
                  disabled={isDeactivated}
                  error={errorEmail}
                />

                <SendButton />
              </div>
            </div>
          }

          {
            isLinkClicked &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 2 } }}
              exit={{ opacity: 0 }}
            >
              {
                isLinkSent && <>
                  {
                    !isSuccessfulReset && <>
                      <div className='text-left ml-3 mb-5'>
                        <h4>Reset password?</h4>
                      </div>

                      <div className='container m-auto'>

                        <FloatingLabelTextInput
                          label='New Password'
                          type='password'
                          value={newPassword}
                          onChangeHandler={(e) => setNewPassword(e.target.value)}
                          disabled={isDeactivated}
                          error={errorPassword}
                        />

                        <FloatingLabelTextInput
                          label='Confirm New Password'
                          type='password'
                          value={confirmNewPassword}
                          onChangeHandler={(e) => setConfirmNewPassword(e.target.value)}
                          disabled={isDeactivated}
                          error={errorPassword}
                        />

                        <NewPasswordSendButton />
                      </div>
                    </>
                  }
                </>
              }
            </motion.div>
          }

          {
            isSuccessfulReset && <>
              {
                isLinkClicked && <>
                  {
                    isLinkSent && <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1, transition: { duration: 2 } }}
                      exit={{ opacity: 0 }}
                    >
                      <div className='text-center mt-5 mb-5'>
                        <img src={success} alt='logo' />
                      </div>

                      <div className='text-center mb-3 text-success'>
                        <h5>Successful</h5>
                      </div>
                    </motion.div>
                  }
                </>
              }

            </>
          }

        </div>
      </div>
    </motion.div>
  )
}

export default ForgotPassword
import React from 'react'
import Sidebar from '../components/layout/Sidebar'

const PageWithSidebar = ({ children }) => {
    return (
        <div className="row flex-nowrap">
            <Sidebar />
            <div className="col py-3 bg-light">
                {/* Content area... */}

                {children}
            </div>
        </div>
    )
}

export default PageWithSidebar
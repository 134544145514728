import axios from 'axios'
import { setCheckedGateway, setGatewaykData, setNetworkData } from '../features/appSlice'

export const getNetworks = (dispatch) => {
    axios
        .get('https://banking-api-with-nodejs.herokuapp.com/api/network/get-all')
        .then(res => dispatch(setNetworkData(res.data)))
        .catch(err => dispatch(setNetworkData({})))
}

export const getGateway = (dispatch) => {
    axios
        .get('https://banking-api-with-nodejs.herokuapp.com/api/gateway/get-all')
        .then(res => dispatch(setGatewaykData(res.data)))
        .catch(err => dispatch(setGatewaykData({})))
}

export const modifyCheckedGateway = (dispatch, checkedValue) => {
    axios
        .post('https://banking-api-with-nodejs.herokuapp.com/api/gateway/modify', checkedValue)
        .then(res => {
            // console.log(res.data['location'][0])
            dispatch(setCheckedGateway(res.data['location'][0]['city']))
        })
        .catch(err => console.log(err))
}
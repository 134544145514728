import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FloatingLabelTextInput from '../common/FloatingLabelTextInput'
import logoGreen from '../assets/logo_green.png'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import Spinner from '../common/Spinner'
import { EMAIL } from '../app/constants'

const Login = () => {

    const { errorEmail, errorPassword } = useSelector((state) => state.error.value)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isDeactivated, setDeactivated] = useState(false)

    const [isLoginClicked, setIsLoginClicked] = useState(false)

    const navigate = useNavigate()

    const forgotPasswordHandler = () => {
        navigate('/forgot-password')
    }

    const signupHandler = () => {
        navigate('/sign-up')
    }

    const loginHandler = () => {
        setIsLoginClicked(true)
        setDeactivated(true)

        setTimeout(() => {
            setIsLoginClicked(false)
            setDeactivated(false)

            navigate('/dashboard')
        }, 3000);
    }

    const LoginButton = () => {
        return (
            <div>
                {isLoginClicked
                    ?
                    <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0 }}
                        className="btn btn-light btn-block text-light"
                        disabled={isDeactivated}
                        style={{height: '3rem'}}
                    >
                        {/* <Spinner text='Logging in...' textStyle='ml-3 text-dark text-center'/> */}
                        <Spinner />
                    </motion.button>
                    :
                    <button className="btn btn-color btn-block btn-control text-light" onClick={loginHandler} style={{height: '3rem'}}>
                        Login
                    </button>
                }
            </div>
        )
    }

    useEffect(() => {
        if (localStorage.getItem(EMAIL) != '') {
          setEmail(localStorage.getItem(EMAIL))
        }
    }, [])
    
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
            className="container-fluid"
        >
            <div className="row">
                <div className="col-md-7 bg-login" id='mobile_only' style={{ position: 'sticky', top: '0px' }} />
                <div className="col-md-5 mt-5">
                    <div className='text-left ml-3 mb-5'>
                        <img src={logoGreen} alt='logo' />
                    </div>
                    <div className='text-left ml-3 mb-5'>
                        <h4>Login to your account</h4>
                    </div>
                    <div className='container m-auto'>
                        <FloatingLabelTextInput
                            label='Email'
                            type='email'
                            value={email}
                            onChangeHandler={(e) => setEmail(e.target.value)}
                            disabled={isDeactivated}
                            error={errorEmail}
                        />
                        
                        <FloatingLabelTextInput
                            label='Password'
                            type='password'
                            value={password}
                            onChangeHandler={(e) => setPassword(e.target.value)}
                            disabled={isDeactivated}
                            error={errorPassword}
                        />
                        <div className='text-left mt-2 mb-5 txt-color'>
                            <h5 className='btn bg-white txt-color' onClick={forgotPasswordHandler}>Forgot Password?</h5>
                        </div>

                        <LoginButton />
                        
                        <div className='text-left mt-3 mb-5 text-dark'>
                            <span className='btn bg-white txt-color'>Don't have an account?</span>
                            <span className='btn bg-white txt-color' onClick={signupHandler}>Signup</span>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Login
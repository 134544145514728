import { motion } from 'framer-motion'
import { useState } from 'react'
import { userData } from '../../data/dataJson'
import active from '../../../assets/active.png'
import inactive from '../../../assets/inactive.png'
import suspended from '../../../assets/suspended.png'
import SelectListGroup from '../../../common/SelectListGroup'
import DialogModal from '../../../common/DialogModal'
import Spinner from '../../../common/Spinner'
import ModalAction from '../../../common/ModalAction'



const AllUsers = () => {
    
    const [isDeactivated, setDeactivated] = useState(false)
    const [showEditDialog, setEditDialog] = useState(false)
    const [selectedRole, setRole] = useState('')
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [isConfirmed, setConfirmed] = useState(false)
    const [alertModal, setAlertModal] = useState(false)
    const [alertMsg, setAlertMsg] = useState(false)

    const userRightsOptions = [
        { label: 'Select Role', value: 'null' },
        { label: 'Super Admin', value: 'Super Admin' },
        { label: 'Admin', value: 'Admin' },
        { label: 'Staff', value: 'Staff' }
    ]

    const onBackClickHandler = () => {
        setAlertMsg('Please remove this button... It\'s very useless!')
        setAlertModal(true)
        setTimeout(() => setAlertModal(false), 4000)
    }
    
    const onRemoveUserHandler = (data) => {
        setAlertMsg('UI not available!')
        setAlertModal(true)
        setTimeout(() => setAlertModal(false), 4000)
    }

    const onCancelModal = () => {
        if (isDeactivated) return
        setEditDialog(false)
    }

    const onEditUserHandler = (data) => {
        setEmail(data.email)
        setUsername(data.name)
        setRole(data.userAccess)

        setEditDialog(true)
    }

    const onSaveChangesHandler = () => {
        setConfirmed(true)
        setDeactivated(true)

        setTimeout(() => {
            setDeactivated(false)
            setConfirmed(false)
            setTimeout(() => setEditDialog(false), 1000);
        }, 3000);
    }


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
        >
            <ModalAction
                body={alertMsg}
                show={alertModal}
                onHideHandler={() => setAlertModal(false)}
            />

            <DialogModal
                show={showEditDialog}
                onHideHandler={onCancelModal}
            >
                <div className="px-3 mb-2">
                    <div className='text-left mt-4'>
                        <small>NAME</small>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder='Username'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        style={{ height: '3rem', borderRadius: '10px' }}
                        disabled={isDeactivated}
                    />

                    <div className='text-left mt-3'>
                        <small>EMAIL</small>
                    </div>
                    <div className='input-group rounded'>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ height: '3rem', borderRadius: '10px' }}
                            disabled={isDeactivated}
                        />
                    </div>

                    <div className='text-left mt-3'>
                        <small>USER ROLE</small>
                    </div>
                    <SelectListGroup
                        borderRadius='10px'
                        bootstrapClass='form-control form-control-lg bg-transparent text-dark'
                        placeholder='Select Role'
                        name='selectRole'
                        value={selectedRole}
                        onChange={(e) => {
                            if (e.target.value === 'null') {
                                setRole(null)

                                return
                            }

                            if (e.target.value === 'Super Admin') setRole('Super Admin')
                            if (e.target.value === 'Admin') setRole('Admin')
                            if (e.target.value === 'Staff') setRole('Staff')
                        }}
                        options={userRightsOptions}
                        disabled={isDeactivated}
                    />

                    <div className='container-fluid'>
                        <div className='row mt-5'>
                            <div
                                className='col-md-5 mr-auto mb-3 btn button-style text-dark'
                                style={{ height: '3rem', borderRadius: '10px' }}
                                onClick={onCancelModal}
                            >
                                <p className='mt-1'>Cancel</p>
                            </div>

                            {/* <SaveChangesButton /> */}

                            {isConfirmed
                                ?
                                <motion.button
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1, transition: { duration: 1 } }}
                                    exit={{ opacity: 0 }}
                                    className="col-md-5 ml-auto mb-3 btn btn-light text-white"
                                    disabled={isDeactivated}
                                    style={{ height: '3rem', borderRadius: '10px' }}
                                >
                                    <Spinner />
                                </motion.button>
                                :
                                <div
                                    className='col-md-5 ml-auto mb-3 btn btn-color text-white'
                                    style={{ height: '3rem', borderRadius: '10px' }}
                                    onClick={onSaveChangesHandler}
                                >
                                    <p className='mt-1'>Save Changes</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>

            </DialogModal>

            <div className='p-3 mt-3 ml-3 mr-3 mb-1 bg-white text-dark rounded' style={{ height: '100%' }}>
                <div className='container-fluid'>
                    <div className='container-fluid mt-4'>
                        <div className='container-fluid'>
                            <h5 className="font-weight-bold" style={{ fontSize: '20px' }}>All Users</h5>
                        </div>
                    </div>

                    {/*  */}
                    
                    <div className='container-fluid mt-5'>
                        <table className='table shadow-sm p-1 mb-1 bg-white text-dark rounded'>
                            <thead>
                                <tr>
                                    <th scope='col'></th>
                                    <th scope='col'></th>
                                    <th scope='col'>NAME</th>
                                    <th scope='col'>EMAIL</th>
                                    <th scope='col'>USER ACCESS</th>
                                    <th scope='col'></th>
                                    <th scope='col'></th>

                                </tr>
                            </thead>

                            {
                                userData.map(datum => (
                                    <tbody key={datum.id}>
                                        <tr>
                                            <td></td>
                                            {
                                                datum.status === 'active' &&
                                                <td>
                                                    <img
                                                        className='rounded-circle'
                                                        src={active}
                                                        alt='company logo'
                                                        style={{ width: '20px', marginRight: '8px' }}
                                                        title='status'
                                                    />
                                                </td>
                                            }
                                            {
                                                datum.status === 'inactive' &&
                                                <td>
                                                    <img
                                                        className='rounded-circle'
                                                        src={inactive}
                                                        alt='company logo'
                                                        style={{ width: '20px', marginRight: '8px' }}
                                                        title='status'
                                                    />
                                                </td>
                                            }
                                            {
                                                datum.status === 'suspended' &&
                                                <td>
                                                    <img
                                                        className='rounded-circle'
                                                        src={suspended}
                                                        alt='company logo'
                                                        style={{ width: '20px', marginRight: '8px' }}
                                                        title='status'
                                                    />
                                                </td>
                                            }
                                            <td>{datum.name}</td>
                                            <td>{datum.email}</td>
                                            <td>{datum.userAccess}</td>
                                            <td>
                                                <div
                                                    className='mr-3 btn text-success button-hover'
                                                    style={{ borderColor: 'green' }}
                                                    onClick={() => onEditUserHandler(datum)}
                                                >
                                                    <span>Edit</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    className='mr-3 btn text-danger button-hover'
                                                    style={{ borderColor: 'red' }}
                                                    onClick={() => onRemoveUserHandler(datum)}
                                                >
                                                    <span>Remove</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))
                            }
                        </table>


                    </div>

                    {/*  */}

                    <div className='container-fluid'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='mr-auto'></div>
                                <button
                                    className='mb-3 mt-3 ml-auto btn btn-color text-light'
                                    style={{ width: '10rem' }}
                                    onClick={onBackClickHandler}
                                >
                                    <span>Back</span>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </motion.div>
    )
}

export default AllUsers
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    errorEmail: '',
    errorPassword: ''
}

export const errorSlice = createSlice({
    name: "error",
    initialState: { value: initialState },
    reducers: {
        setErrorEmail: (state, action) => {
            state.value.errorEmail = action.payload;
        },
        setErrorPasswoord: (state, action) => {
            state.value.errorPassword = action.payload;
        }
    }
});

export const { setErrorEmail, setErrorPasswoord } = errorSlice.actions;

export default errorSlice.reducer;
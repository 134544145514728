import React, { useState } from 'react'
import { motion } from 'framer-motion'
import SelectListGroup from '../../../common/SelectListGroup'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setPurchaseSimItem, setSimSpecsStyle } from '../../../features/appSlice'
import iconPurchaseSim from '../../../assets/purchase-sim.png'
import SimSpecification from './purchaseSimActivities/SimSpecification'
import { useSelector } from 'react-redux'
import ShippingAddress from './purchaseSimActivities/ShippingAddress'
import Checkout from './purchaseSimActivities/Checkout'

const PurchaseSIMs = () => {

    const { simSpecsStyle, shippingAddressStyle, checkoutStyle, simSpecsNextClicked, shippingAddressConfirmed, isCheckout } = useSelector(state => state.app.value)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPurchaseSimItem())
        dispatch(setSimSpecsStyle())
    }, [])
    
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
            className="container-fluid"
        >
            <div className="container-fluid">
                <div className='container-fluid'>
                    <div className="mb-1 bg-light text-dark rounded mt-5">
                        <div className="row">
                            <button className="ml-3 mb-3 mr-auto btn btn-color btn-control text-light">
                                <img className='mr-1' src={iconPurchaseSim} alt='icon' width='27px' />
                                <span>PURCHASE SIM</span>
                            </button>
                            <button className={simSpecsStyle}>
                                <span>SIM Specification</span>
                            </button>
                            <button className={shippingAddressStyle}>
                                <span>Shipping Address</span>
                            </button>
                            <button className={checkoutStyle}>
                                <span>Checkout</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            
            
            {/* <div className='container-fluid'>
                <div className='container-fluid'>
                    <div className='p-1 mb-1 bg-light text-dark rounded mt-5' style={{ height: '3rem' }}>
                        <div className='row'>
                            <button className='ml-3 mb-3 mr-auto btn btn-color btn-control text-light'>
                                <img className='mr-1' src={iconPurchaseSim} alt='icon' width='27px' />
                                <span>PURCHASE SIM</span>
                            </button>
                            
                            <button className={simSpecsStyle}>
                                <span>SIM Specification</span>
                            </button>
                            <button className={shippingAddressStyle}>
                                <span>Shipping Address</span>
                            </button>
                            <button className={checkoutStyle}>
                                <span>Checkout</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}

            {simSpecsNextClicked && <SimSpecification />}
            {shippingAddressConfirmed && <ShippingAddress />}
            {isCheckout && <Checkout />}

        </motion.div>
    )
}

export default PurchaseSIMs
import React from 'react'
import classnames from 'classnames'

const FloatingLabelTextInput = ({ label, height, className, style, width, value, borderRadius, type, error, onChangeHandler, disabled }) => {
  return (
    
    <div id="float-label">
      <input
        className={
          classnames(`${className == null ? 'form-control form-control-lg bg-transparent text-dark' : className}`, { "is-invalid": error })
        }
        type={type}
        value={value}
        onChange={onChangeHandler}
        disabled={disabled}
        style={style == null ? { borderRadius: borderRadius ? borderRadius : '10px', height: height, width: width } : style}
      />
      <label htmlFor="text" className={value !== '' ? 'Active' : ''}>{label}</label>
      {error && <div className='invalid-feedback'>{error}</div>}
      <br />
    </div>
  
  )
}

export default FloatingLabelTextInput
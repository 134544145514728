import React from 'react'

const CheckBox = ({ id, value, label, onCheckedHandler, checked }) => {
    return (
        // <div>
        //     <input
        //         type="checkbox"
        //         className='form-check-input'
        //         name='current'
        //         value={value}
        //         onChange={onCheckedHandler}
        //         id='current'
        //         checked={checked}
        //     />
        // </div>
        
        <div className='custom-control custom-checkbox'>
            <input
                type="checkbox"
                className='custom-control-input'
                name={id}
                value={value}
                onChange={onCheckedHandler}
                id={id}
                checked={checked}
            />
            <label className="custom-control-label" htmlFor={id}>{label}</label>
        </div>
    )
}

export default CheckBox
import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'
import AnimatedRoutes from './components/layout/AnimatedRoutes'
import ScreenDimensions from './components/layout/ScreenDimensions';

const App = () => {
  return (
    <div className="App">
      <Router>
        <ScreenDimensions>
          <AnimatedRoutes />
        </ScreenDimensions>
      </Router>
    </div>
  );
}

export default App;

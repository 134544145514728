import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import FloatingLabelTextInput from '../../../common/FloatingLabelTextInput'
import useNetworkStatus from '../../../common/NetworkHook'
import { setWallet } from '../../../features/appSlice'
import { usePaystackPayment } from 'react-paystack'
import Snackbar from '../../../common/Snackbar'
import ModalAction from '../../../common/ModalAction'
import { EMAIL, isValidEmail, PHONE_NUMBER } from '../../../app/constants'
import classnames from 'classnames'

const FundWallet = () => {

  const { exchangeRate, currency } = useSelector(state => state.app.value)
  const { isOnline } = useNetworkStatus()

  const dispatch = useDispatch()

  const [isDeactivated, setDeactivated] = useState(false)
  const [email, setEmail] = useState('')
  const [amount, setAmount] = useState('')

  const [errorAmount, setErrorAmount] = useState('')
  const [errorEmail, setErrorEmail] = useState('')

  const onCancelHandler = () => {
    dispatch(setWallet())
  }

  const onCreateHandler = () => {

  }

  const config = {
    reference: 'gentroconnect_' + (new Date()).getTime().toString(),
    metadata: {
      custom_fields: [
        {
          'display_name': 'Description',
          'variable_name': 'Description',
          'value': 'Wallet funds'
        },

        {
          'display_name': 'Phone Number',
          'variable_name': 'Phone Number',
          'value': localStorage.getItem(PHONE_NUMBER)
        },

        {
          'display_name': 'Email',
          'variable_name': 'Email',
          'value': email
        }
      ]
    },
    email: email,
    amount: amount * 100,
    publicKey: 'pk_test_c6654b538da3016e1c45af299bd86f97f2401750'
    // publicKey: 'pk_test_49be27292bc7c5cc6f1959257d5e2a8056658510'    // For Paxian Solutions
  }

  // name this function whatever you want
  const onSuccess = (reference) => {
    console.log(reference)

    setTimeout(() => {
      dispatch(setWallet())
    }, 1500)
  }

  // name this function whatever you want
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log('closed')
  }

  const PaystackHook = () => {

    const initializePayment = usePaystackPayment(config)

    return (
      <div
        className="col-md-5 ml-auto mb-4 btn btn-color text-white"
        style={{ height: '3rem', borderRadius: '10px' }}
        onClick={() => {
          if (email == '') {
            setErrorEmail('Email field cannot be empty!')
            return
          }

          if (!isValidEmail(email)) {
            setErrorEmail('Invalid email!')
            return
          }

          if (amount == '') {
            setErrorAmount('Enter an amount, please!')
            return
          }

          if (amount == 0) {
            setErrorAmount('Invalid amount!')
            return
          }
          
          if (amount < 0) {
            setErrorAmount('Nonsense! Make sure to enter a positive value.')
            return
          }


          initializePayment(onSuccess, onClose)
        }}
      >
        <p className="mt-1">Make Payment</p>
      </div>
    )
  }

  useEffect(() => {
    if (localStorage.getItem(EMAIL) != '') {
      setEmail(localStorage.getItem(EMAIL))
    }
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className='container-fluid'
    >
      <div className='shadow-sm p-3 mb-1 bg-white text-dark rounded mt-5' style={{ height: '100%' }}>
        <div className='col-md-6 mt-3'>

          <div className='text-left mt-4'>
            <small>Email (Changable)</small>
          </div>
          <div className='input-group rounded'>
            <input
              type="email"
              className={classnames('form-control text-truncate', { "is-invalid": errorEmail })}
              placeholder='Email'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
                setErrorEmail('')
              }}
              style={{ height: '3rem', borderRadius: '10px' }}
              disabled={isDeactivated}
            />
            <div className='invalid-feedback'>{errorEmail}</div>
          </div>
          
          {/* <FloatingLabelTextInput
            style={{ width: 'auto', borderRadius: '10px' }}
            label='Email (Changable)'
            type='email'
            borderRadius='8px'
            value={email}
            onChangeHandler={(e) => {
              setEmail(e.target.value)
              setErrorEmail('')
            }}
            disabled={isDeactivated}
            error={errorEmail}
          />

          <FloatingLabelTextInput
            style={{ width: 'auto', borderRadius: '10px' }}
            type='number'
            borderRadius='8px'
            label={
              currency == '₦'
                ? amount == '' ? 'Enter Naira Amount (NGN)' : 'Amount (NGN)'
                : amount == '' ? 'Enter Dollar Amount (USD)' : 'Amount (USD)'
            }
            value={amount}
            onChangeHandler={(e) => {
              setAmount(e.target.value)
              setErrorAmount('')
            }}
            disabled={isDeactivated}
            error={errorAmount}
          /> */}

          <div className='text-left mt-4'>
            <small>
              {
                currency == '₦'
                  ? amount == '' ? 'Enter Naira Amount (NGN)' : 'Amount (NGN)'
                  : amount == '' ? 'Enter Dollar Amount (USD)' : 'Amount (USD)'
              }
            </small>
          </div>
          <div className='input-group rounded'>
            <input
              type="number"
              className={classnames('form-control', { "is-invalid": errorAmount })}
              placeholder='Amount'
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value)
                setErrorAmount('')
              }}
              style={{ height: '3rem', borderRadius: '10px' }}
              disabled={isDeactivated}
            />
            <div className='invalid-feedback'>{errorAmount}</div>
          </div>

          <div className='container-fluid'>
            <div className='row mt-3'>
              <div
                className='col-md-5 mr-auto mb-4 btn button-style text-dark'
                style={{ height: '3rem', borderRadius: '10px' }}
                onClick={onCancelHandler}
              >
                <p className='mt-1'>Cancel</p>
              </div>

              {
                isOnline
                  ? <PaystackHook />
                  : <div
                    className='col-md-5 ml-auto mb-4 btn button-style text-dark'
                    style={{ height: '3rem', borderRadius: '10px' }}
                  >
                    <p className='mt-1'>Network Error</p>
                  </div>
              }
            </div>
          </div>

        </div>

      </div>

    </motion.div>
  )
}

export default FundWallet
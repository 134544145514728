import React, { useState } from 'react'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactCountryFlag from "react-country-flag"

import { useNavigate } from 'react-router-dom'
import FloatingLabelTextInput from '../common/FloatingLabelTextInput'
import logoGreen from '../assets/logo_green.png'
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import Spinner from '../common/Spinner'
import SelectListGroup from '../common/SelectListGroup'
import { useDispatch } from 'react-redux';
import { setEmail } from '../features/appSlice';
import { EMAIL, FIRST_NAME, isValidEmail, LAST_NAME, PHONE_NUMBER } from '../app/constants';
import { setErrorEmail, setErrorPasswoord } from '../features/errorSlice';


const Signup = () => {
  
  const { errorEmail, errorPassword } = useSelector((state) => state.error.value)
  // const { email } = useSelector((state) => state.app.value)
  
  const dispatch = useDispatch()

  const [errorFirstName, setErrorFirstName] = useState('')
  const [errorLastName, setErrorLastName] = useState('')
  const [errorPhone, setErrorPhone] = useState('')
  
  const [firstName, setFirstName] = useState('')
  const [middleName, setMiddlenName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [country, setCountry] = useState('')
  const [language, setLanguage] = useState('')
  const [nationality, setNationality] = useState('')
  const [isDeactivated, setDeactivated] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const [isSignupClicked, setIsLoginClicked] = useState(false)

  const navigate = useNavigate()

  const loginHandler = () => {
    navigate('/')
  }
  
  const signupHandler = () => {
    
    if (firstName == '') {
      setErrorFirstName('Firstname is required!')
      return
    }
    
    if (lastName == '') {
      setErrorLastName('Lastname is required!')
      return
    }
    
    if (email == '') {
      dispatch(setErrorEmail('Enter your email, please!'))
      return
    }
    
    if (!isValidEmail(email)) {
      dispatch(setErrorEmail('Invalid email!'))
      return
    }
    
    if (phone == '') {
      setErrorPhone('Enter your phone number, please!')
      return
    }

    localStorage.setItem(FIRST_NAME, firstName)
    localStorage.setItem(LAST_NAME, lastName)
    localStorage.setItem(EMAIL, email)
    localStorage.setItem(PHONE_NUMBER, `+${phone}`)
    
    setIsLoginClicked(true)
    setDeactivated(true)

    setTimeout(() => {
      setIsLoginClicked(false)
      setDeactivated(false)
      
      navigate('/phone-verification')
    }, 3000);
  }
  
  console.log(`+${phone}`)

  const SignupButton = () => {
    return (
      <div>
        {isSignupClicked
          ?
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1 } }}
            exit={{ opacity: 0 }}
            className="btn btn-light btn-block text-light"
            disabled={isDeactivated}
            style={{ height: '3rem' }}
          >
            {/* <Spinner text='Logging in...' textStyle='ml-3 text-dark text-center'/> */}
            <Spinner />
          </motion.button>
          :
          <button className="btn btn-color btn-block btn-control text-light" onClick={signupHandler} style={{ height: '3rem' }}>
            Signup
          </button>
        }
      </div>
    );
  };

  const languageOptions = [
    { label: 'Select Language', value: 'null' },
    { label: 'English', value: 'english' },
    { label: 'French', value: 'french' },
    { label: 'Spanish', value: 'spanish' },
    { label: 'Italy', value: 'italy' }
  ]

  const nationalityOptions = [
    { label: 'Nationality', value: 'null' },
    { label: 'Nigerian', value: 'nigerian' },
    { label: 'Non Nigerian', value: 'nonNigerian' }
  ]

  return (
    // <div>




    //   <ReactCountryFlag
    //     countryCode="ng"
    //     svg
    //     cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
    //     cdnSuffix="svg"
    //     title="ng"
    //     style={{
    //       width: '1.2em',
    //       height: '1.2em',
    //     }}
    //   />


    // </div>

    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className="container-fluid"
    >
      <div className="row">

        <div className="col-md-5 mt-5">
          <div className='text-left ml-3 mb-5'>
            <img src={logoGreen} alt='logo' />
          </div>
          <div className='text-left ml-3 mb-5'>
            <h4>Signup to your account</h4>
          </div>
          <div className='container-fluid m-auto'>
            <FloatingLabelTextInput
              label='First Name'
              type='text'
              value={firstName}
              onChangeHandler={(e) => {
                setFirstName(e.target.value)
                setErrorFirstName('')
              }}
              disabled={isDeactivated}
              error={errorFirstName}
            />
            <FloatingLabelTextInput
              label='Middle Name'
              type='text'
              value={middleName}
              onChangeHandler={(e) => setMiddlenName(e.target.value)}
              disabled={isDeactivated}
              error={''}
            />
            <FloatingLabelTextInput
              label='Last Name'
              type='text'
              value={lastName}
              onChangeHandler={(e) => {
                setLastName(e.target.value)
                setErrorLastName('')
              }}
              disabled={isDeactivated}
              error={errorLastName}
            />
            <FloatingLabelTextInput
              label='Company Name'
              type='text'
              value={companyName}
              onChangeHandler={(e) => setCompanyName(e.target.value)}
              disabled={isDeactivated}
              error={''}
            />
            <FloatingLabelTextInput
              label='Email'
              type='email'
              value={email}
              onChangeHandler={(e) => {
                setEmail(e.target.value)
                dispatch(setErrorEmail(''))
              }}
              disabled={isDeactivated}
              error={errorEmail}
            />

            <FloatingLabelTextInput
              label='Password'
              type='password'
              value={password}
              onChangeHandler={(e) => {
                setPassword(e.target.value)
                dispatch(setErrorPasswoord(''))
              }}
              disabled={isDeactivated}
              error={errorPassword}
            />

            <div className='row'>
              <div className='col-md-6 mb-4'>
                <CountryDropdown
                  className='form-control form-control-lg bg-transparent text-dark'
                  value={country}
                  disabled={isDeactivated}
                  onChange={setCountry}
                  style={{ borderRadius: '10px' }} />

                {/* <RegionDropdown
                    country={country}
                    value={region}
                    onChange={setRegion} /> */}
              </div>
              <div className='col-md-6 mb-2'>
                <SelectListGroup
                  borderRadius='10px'
                  bootstrapClass='form-control form-control-lg bg-transparent text-dark'
                  placeholder='Language'
                  name='language'
                  value={language}
                  onChange={(e) => {
                    if (e.target.value === 'null') {
                      setLanguage('Select Language')

                      return
                    }

                    if (e.target.value === 'english') setLanguage('english')
                    if (e.target.value === 'french') setLanguage('french')
                    if (e.target.value === 'spanish') setLanguage('spanish')
                    if (e.target.value === 'italy') setLanguage('italy')
                  }}
                  options={languageOptions}
                  // info={answerInfo}
                  disabled={isDeactivated}
                />
              </div>
            </div>
            
            <PhoneInput
              inputStyle={{ width: '100%', height: '3rem', borderRadius: '10px' }}
              country={'ng'}
              value={phone}
              onChange={setPhone}
              enableSearch={true}
              onClick={() => setErrorPhone('')}
              disabled={isDeactivated}
            />
            <small className='text-danger'>{errorPhone}</small>

            <SelectListGroup
              bootstrapClass='form-control form-control-lg bg-transparent mt-4 text-dark'
              placeholder='Nationality'
              name='nationality'
              value={nationality}
              onChange={(e) => {
                if (e.target.value === 'null') {
                  setNationality('Nationality')

                  return
                }

                if (e.target.value === 'nigerian') setNationality('nigerian')
                if (e.target.value === 'nonNigerian') setNationality('nonNigerian')
              }}
              options={nationalityOptions}
              // info={answerInfo}
              disabled={isDeactivated}
            />

            <div className='text-left mt-2 mb-4'>
              <span>
                <input type="radio" value={isChecked} onChange={() => setIsChecked(true)} checked={isChecked} />
              </span>
              <span> I agree to the terms and conditions</span>
            </div>

            <SignupButton />

            <div className='text-left mt-3 mb-5 text-dark'>
              <span className='btn bg-white txt-color'>Already have an account? </span>
              <span className='btn bg-white txt-color' onClick={loginHandler}>Login</span>
            </div>
          </div>
        </div>
        <div className="col-md-7 bg-login" id='mobile_only' style={{ position: 'sticky', top: '0px' }} />
      </div>
    </motion.div>
  )
}

export default Signup
import { AnimatePresence } from 'framer-motion'
import { Routes, Route, useLocation } from 'react-router-dom'
import Login from '../../auth/Login'
import Signup from '../../auth/Signup'
import ForgotPassword from '../../auth/ForgotPassword'
import PhoneVerification from '../../auth/PhoneVerification'
import Dashboard from '../pages/Dashboard'
import Sims from '../pages/Sims'
import SimPlan from '../pages/SimPlan'
import LoRa from '../pages/LoRa'
import LoRaPlans from '../pages/LoRaPlans'
import Wallet from '../pages/Wallet'
import User from '../pages/User'
import Groups from '../pages/Groups'
import Settings from '../pages/Settings'
import SendSMS from '../pages/simActivities/SendSMS'
import DynamicHeight from '../../common/TestDynamicHeigth'

const AnimatedRoutes = () => {

    const location = useLocation()

    return (
        <AnimatePresence location={location} key={location.pathname}>
            <Routes>
                {/* <Route exact path='/' element={<DynamicHeight />} /> */}
                <Route exact path='/' element={<Login />} />
                <Route exact path='/sign-up' element={<Signup />} />
                <Route exact path='/phone-verification' element={<PhoneVerification />} />
                <Route exact path='/forgot-password' element={<ForgotPassword />} />
                <Route exact path='/dashboard' element={<Dashboard />} />
                <Route exact path='/sim-cards' element={<Sims />} />
                <Route exact path='/send-sms' element={<SendSMS />} />
                <Route exact path='/sim-plans' element={<SimPlan />} />
                <Route exact path='/lora' element={<LoRa />} />
                <Route exact path='/lora-plans' element={<LoRaPlans />} />
                <Route exact path='/wallet' element={<Wallet />} />
                <Route exact path='/user' element={<User />} />
                <Route exact path='/groups' element={<Groups />} />
                <Route exact path='/settings' element={<Settings />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes
import React from 'react'
import { useState } from 'react'
import { motion } from 'framer-motion'
import SelectListGroup from '../../../../common/SelectListGroup'
import { useDispatch } from 'react-redux'
import { setShippingAddressStyle } from '../../../../features/appSlice'
import Loading from '../../../../common/Loading'

const SimSpecification = () => {

  const dispatch = useDispatch()

  const [quantity, setQuantity] = useState(0)
  const [plan, setPlan] = useState('')

  const onNextHandler = () => {
    dispatch(setShippingAddressStyle())
  }

  const quantityOption = [
    { label: 'Select Quantity', value: 'null' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' }
  ]

  const planOption = [
    { label: 'Select Plan', value: 'null' },
    { label: 'Regular', value: 'regular' },
    { label: 'Premium', value: 'premium' },
    { label: 'Business', value: 'business' },
    { label: 'Personal', value: 'personal' },
    { label: 'Truck', value: 'truck' },
    { label: 'Car', value: 'car' }
  ]

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className='container-fluid'
    >
      <div className='container-fluid'>
        <div className='shadow-sm p-1 mb-1 bg-white text-dark rounded mt-1' style={{ height: '100%' }}>
          <div className="row mr-auto ml-auto">

            <div className="col-md-6 mt-5">
              <div className='mb-2 pl-5'>
                <div className='text-left mt-2 mb-3'>
                  <h5 className="font-weight-bold" style={{ fontSize: '25px' }}>SIM Specification</h5>
                </div>
                
                <div className='text-left mt-5'>
                  <small>Quantity</small>
                </div>
                <SelectListGroup
                  borderRadius='10px'
                  bootstrapClass='form-control bg-transparent text-dark'
                  placeholder='Quantity'
                  name='quantity'
                  value={quantity}
                  onChange={(e) => {
                    if (e.target.value === 'null') {
                      setQuantity('Select Quantity')

                      return
                    }

                    if (e.target.value === '1') setQuantity('1')
                    if (e.target.value === '2') setQuantity('2')
                    if (e.target.value === '3') setQuantity('3')
                    if (e.target.value === '4') setQuantity('4')
                  }}
                  options={quantityOption}
                  // info={answerInfo}
                  disabled={false}
                />

                <div className='text-left mt-3'>
                  <small>SIM Plan</small>
                </div>
                <SelectListGroup
                  borderRadius='10px'
                  bootstrapClass='form-control bg-transparent text-dark'
                  placeholder='Quantity'
                  name='quantity'
                  value={plan}
                  onChange={(e) => {
                    if (e.target.value === 'null') {
                      setPlan('Select Plan')

                      return
                    }

                    if (e.target.value === 'regular') setPlan('regular')
                    if (e.target.value === 'premium') setPlan('premium')
                    if (e.target.value === 'business') setPlan('business')
                    if (e.target.value === 'personal') setPlan('personal')
                    if (e.target.value === 'truck') setPlan('truck')
                    if (e.target.value === 'car') setPlan('car')
                  }}
                  options={planOption}
                  // info={answerInfo}
                  disabled={false}
                />
                
                <div className='container-fluid'>
                  <div className='row mt-5'>
                    {/* <div
                      className='col-md-5 mr-auto mb-3 btn button-style text-dark'
                      style={{ height: '3rem', borderRadius: '10px' }}
                    // onClick={onCancelHandler}
                    >
                      <p className='mt-1'>Cancel</p>
                    </div> */}
                    <div
                      className='col-md-12 ml-auto mb-3 btn btn-color text-white'
                      style={{ height: '3rem', borderRadius: '10px' }}
                      onClick={onNextHandler}
                    >
                      <p className='mt-1'>Next</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-md-6" id='mobile_only' >
              <div className='bg-purchase m-5' />
            </div>

          </div>
        </div>
      </div>

    </motion.div>
  )
}

export default SimSpecification
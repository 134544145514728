import React from 'react'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import CheckBox from '../../../common/CheckBox'
import { simPlanData, loraPlanData } from '../../data/dataJson'
import active from '../../../assets/active.png'
import inactive from '../../../assets/inactive.png'
import suspended from '../../../assets/suspended.png'
import { useDispatch } from 'react-redux'
import { setCreateNewPlans, setDeletePlan, setMyPlans, setViewClicked } from '../../../features/appSlice'
import warningInfo from '../../../assets/warning-info.png'
import newPlan from '../../../assets/newPlan.png'
import DialogModal from '../../../common/DialogModal'
import SelectListGroup from '../../../common/SelectListGroup'
import Spinner from '../../../common/Spinner'
import SuccessModal from '../../../common/SuccessModal'
import { useEffect } from 'react'
import ModalAction from '../../../common/ModalAction'
import FloatingLabelTextInput from '../../../common/FloatingLabelTextInput'

const ViewPlan = ({ planType }) => {

    const { isDeletePlan, viewData } = useSelector(state => state.app.value)
    // console.log(viewData)

    const dispatch = useDispatch()
    
    const [isDeactivated, setDeactivated] = useState(false)

    const [value, setValue] = useState('')
    const [planId, setPlanId] = useState('')
    const [password, setPassword] = useState('')
    const [types, setTypes] = useState('')
    const [alertModal, setAlertModal] = useState(false)
    const [alertMessage, setAlertMessage] = useState('')
    const [showWarningDialog, setWarningDialog] = useState(false)
    const [showPasswordDialog, setPasswordDialog] = useState(false)
    const [showNewPlanDialog, setShowNewPlanDialog] = useState(false)

    const [showCreateNewPlanDialog, setCreateNewPlanDialog] = useState(false)
    const [showDeletePlanWarningDialog, setDeletePlanWarningDialog] = useState(false)

    const [selectedPlan, setSelectedPlan] = useState(null)

    const [isConfirmed, setConfirmed] = useState(false)
    
    const [successModal, showSuccessModal] = useState(false)

    const planOptions = [
        { label: 'Select Plan', value: 'null' },
        { label: 'Regular Plan', value: 'Regular Plan' },
        { label: 'Super Plan', value: 'Super Plan' },
        { label: 'Premium Plan', value: 'Premium Plan' },
        { label: 'Diamond Plan', value: 'Diamond Plan' }
    ]

    const onRemovePlanHandler = (planID) => {
        setWarningDialog(true)

        setPlanId(planID)
        // setPlanName(viewData)
    }

    const onContinueHandler = () => {
        dispatch(setCreateNewPlans())
        dispatch(setViewClicked(false))

        dispatch(setDeletePlan(false))
    }

    const onCreateNewPlankHandler = () => {
        setDeletePlanWarningDialog(true)

        setCreateNewPlanDialog(false)
        setShowNewPlanDialog(false)
        dispatch(setDeletePlan(false))
    }

    const onCancelClickHandler = () => {
        if (isDeactivated) return
        
        setWarningDialog(false)
        setPasswordDialog(false)
        setShowNewPlanDialog(false)
        setCreateNewPlanDialog(false)
        setDeletePlanWarningDialog(false)

        dispatch(setDeletePlan(false))
        setSelectedPlan(null)
    }
    
    const onConfirmPassword = () => {
        setShowNewPlanDialog(true)
        setPasswordDialog(false)
    }

    const onConfirmRemoveOrDeleteHandler = () => {
        if (isDeletePlan) {
            setTimeout(() => {
                setPasswordDialog(true)
                setWarningDialog(false)
            }, 1000);

            return
        }

        setShowNewPlanDialog(true)
        setWarningDialog(false)
    }

    const onSelectNewPlanHandler = () => {
        setCreateNewPlanDialog(true)

        setShowNewPlanDialog(false)
    }

    const onConfirmNewPlanHandler = () => {
        if (selectedPlan === null || selectedPlan === viewData.planName) {
            setAlertModal(true)
            if (selectedPlan === null) setAlertMessage('You have NOT selected any plan!')
            if (selectedPlan === viewData.planName) setAlertMessage('Please select a different plan!')

            setTimeout(() => {
                setAlertModal(false)
            }, 3000);
            
            return
        }

        setConfirmed(true)
        setDeactivated(true)

        setTimeout(() => {
            setConfirmed(false)
            showSuccessModal(true)
            setDeactivated(false)

            setCreateNewPlanDialog(false)
        }, 3000);
    }

    const onBackClickHandler = () => {
        // dispatch(setMySimPplans())
        dispatch(setViewClicked(false))
    }

    const onModalHideHandler = () => {
        showSuccessModal(false)
        dispatch(setDeletePlan(false))
        setSelectedPlan(null)
    }

    const ConfirmButton = () => {
        return (
            <div>
                {isConfirmed
                    ?
                    <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0 }}
                        className="mr-3 mb-3 btn btn-light text-white"
                        disabled={isDeactivated}
                        style={{ width: '8rem', borderRadius: '5px' }}
                    >
                        <Spinner />
                    </motion.button>
                    :
                    <button
                        className='mr-3 mb-3 btn btn-color text-white'
                        style={{ width: '8rem', borderRadius: '5px' }}
                        onClick={onConfirmNewPlanHandler}
                    >
                        <span>Confirm</span>
                    </button>
                }
            </div>
        )
    }

    useEffect(() => {
        if (planType === 'sim') setTypes('SIMs')
        else setTypes('devices')
    }, [])

    useEffect(() => {
        if (isDeletePlan) setWarningDialog(true)
    }, [isDeletePlan])

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
        >
            <ModalAction
                body={alertMessage}
                show={alertModal}
                onHideHandler={() => setAlertModal(false)}
            />

            <SuccessModal
                message={
                    isDeletePlan
                        ? `All ${types} has been Successfully moved to ${selectedPlan}.`
                        : `${planId} was Successfuly added to ${selectedPlan}.`
                }
                show={successModal}
                onHideHandler={onModalHideHandler}
            />

            <DialogModal
                show={showWarningDialog}
            >
                <img src={warningInfo} alt='success logo' width='30%' />
                {
                    isDeletePlan
                        ? <div>You are about to delete {viewData.planName}!</div>
                        : <div>You are about to remove {planId} from {viewData.planName}!</div>
                }
                <div className='continer-fluid mt-3 ml-3 mr-auto'>
                    <button
                        className='mr-3 mb-3 btn btn-light text-danger text-white'
                        style={{ borderColor: 'red' }}
                        onClick={onCancelClickHandler}
                    >
                        <span>Cancel</span>
                    </button>

                    <button
                        className='mr-3 mb-3 btn btn-danger text-white'
                        onClick={onConfirmRemoveOrDeleteHandler}
                    >
                        <span>{isDeletePlan ? 'Delete' : 'Remove'}</span>
                    </button>
                </div>
            </DialogModal>

            <DialogModal
                show={showPasswordDialog}
            >
                <div>Enter your password to confirm this action!</div>

                <div className='px-5 mt-4'>
                    {/* <div className='text-left txt-color-light'>
                        <small>All Plans</small>
                    </div> */}
                    <FloatingLabelTextInput
                        label='Password'
                        type='password'
                        borderRadius='5px'
                        value={password}
                        onChangeHandler={(e) => setPassword(e.target.value)}
                        disabled={isDeactivated}
                    />
                </div>

                <div className='continer-fluid mt-3 ml-3 mr-auto'>
                    <button
                        className='mr-3 mb-3 btn btn-light text-danger text-white'
                        style={{ borderColor: 'red' }}
                        onClick={onCancelClickHandler}
                    >
                        <span>Cancel</span>
                    </button>

                    <button
                        className='mr-3 mb-3 btn btn-danger text-white'
                        onClick={onConfirmPassword}
                    >
                        <span>Confirm</span>
                    </button>
                </div>
            </DialogModal>

            <DialogModal
                show={showNewPlanDialog}
            >
                <img src={newPlan} alt='success logo' width='30%' />
                {
                    isDeletePlan
                        ? <div>Move all {types} into a different plan OR create a new plan for all {types}!</div>
                        : <div>Select a new plan for {planId} or create a new one!</div>
                }

                <div className='continer-fluid mt-3 ml-3 mr-auto'>
                    {
                        isDeletePlan
                            ? <button
                                className='mr-3 mb-3 btn button-style text-dark'
                                onClick={onCreateNewPlankHandler}
                            >
                                <span>Create New Plan</span>
                            </button>
                            : <button
                                className='mr-3 mb-3 btn button-style text-dark'
                                onClick={onCancelClickHandler}
                            >
                                <span>Cancel</span>
                            </button>
                    }
                    <button
                        className='mr-3 mb-3 btn btn-color text-white'
                        onClick={onSelectNewPlanHandler}
                    >
                        <span>Select A Plan</span>
                    </button>
                </div>
            </DialogModal>

            <DialogModal
                show={showDeletePlanWarningDialog}
            >
                <div>{viewData.planName} will be deleted!</div>

                <div className='continer-fluid mt-3 ml-3 mr-auto'>
                    <button
                        className='mr-3 mb-3 btn button-style text-dark'
                        onClick={onCancelClickHandler}
                    >
                        <span>Cancel</span>
                    </button>

                    <button
                        className='mr-3 mb-3 btn btn-color text-white'
                        onClick={onContinueHandler}
                    >
                        <span>Yes, Continue</span>
                    </button>
                </div>
            </DialogModal>

            <DialogModal
                show={showCreateNewPlanDialog}
            >
                {
                    isDeletePlan
                        ? <div>Move all {types} into seleted plan!</div>
                        : <div>Select a new plan for {planId}</div>
                }

                <div className='px-5'>
                    <div className='text-left txt-color-light'>
                        <small>All Plans</small>
                    </div>
                    <SelectListGroup
                        borderRadius='10px'
                        bootstrapClass='form-control form-control-lg bg-transparent text-dark'
                        placeholder='Select Plan'
                        name='select Plan'
                        value={selectedPlan}
                        onChange={(e) => {
                            if (e.target.value === 'null') {
                                setSelectedPlan(null)

                                return
                            }

                            if (e.target.value === 'Regular Plan') setSelectedPlan('Regular Plan')
                            if (e.target.value === 'Super Plan') setSelectedPlan('Super Plan')
                            if (e.target.value === 'Premium Plan') setSelectedPlan('Premium Plan')
                            if (e.target.value === 'Diamond Plan') setSelectedPlan('Diamond Plan')
                        }}
                        options={planOptions}
                        // info={answerInfo}
                        disabled={isDeactivated}
                    />
                </div>

                <div className='continer-fluid mt-3 ml-3 mr-auto'>
                    <button
                        className='mr-3 mb-3 btn button-style text-dark'
                        onClick={onCancelClickHandler}
                    >
                        <span>Cancel</span>
                    </button>

                    <span className='btn'>
                        <ConfirmButton />
                    </span>
                </div>
            </DialogModal>
            
            
            <div className='p-3 mb-1 bg-white text-dark rounded' style={{ height: '100%' }}>
                <div className='container-fluid'>
                    <div className='container-fluid mt-4'>
                        <div className='container-fluid'>
                            <h5 className="font-weight-bold">{viewData.planName}</h5>
                        </div>
                    </div>
                    
                    <div className='container-fluid m-3 text-center'>
                        <div className='row'>
                            <div className='mb-2 ml-auto mr-auto'>
                                <div
                                    className='shadow p-1 mb-1 bg-white text-dark mt-5'
                                    style={{ height: '84px', width: '200px', borderRadius: '15px' }}
                                >
                                    {
                                        planType === 'sim'
                                            ?
                                            <>
                                                <h5 className='font-weight-bold text-center mt-3'>
                                                    50<small>MB</small>
                                                </h5>
                                                <p className='txt-color-light'>Monthly Data</p>
                                            </>
                                            : <>
                                                <h5 className='font-weight-bold text-center mt-3'>
                                                    50<small>K</small>
                                                </h5>
                                                <p className='txt-color-light'>Messages</p>
                                            </>
                                    }

                                </div>
                            </div>

                            <div className='mb-2 ml-auto mr-auto'>
                                <div
                                    className='shadow p-1 mb-1 bg-white text-dark mt-5'
                                    style={{ height: '84px', width: '200px', borderRadius: '15px' }}
                                >
                                    {
                                        planType === 'sim'
                                            ? <>
                                                <h5 className='font-weight-bold text-center mt-3'>
                                                    30<small>SIMs</small>
                                                </h5>
                                                <p className='txt-color-light'>SIM Cards</p>
                                            </>
                                            : <>
                                                <h5 className='font-weight-bold text-center mt-3'>
                                                    30
                                                </h5>
                                                <p className='txt-color-light'>Devices/Units</p>
                                            </>
                                    }

                                </div>
                            </div>

                            <div className='mb-2 ml-auto mr-auto'>
                                <div
                                    className='shadow p-1 mb-1 bg-white text-dark mt-5'
                                    style={{ height: '84px', width: '200px', borderRadius: '15px' }}
                                >
                                    {planType === 'sim'
                                        ? <>
                                            <h5 className='font-weight-bold text-center mt-3'>
                                                100<small>SMS</small>
                                            </h5>
                                            <p className='txt-color-light'>Monthly SMS</p>
                                        </>
                                        : <>
                                            <h5 className='font-weight-bold text-center mt-3'>
                                                2
                                            </h5>
                                            <p className='txt-color-light'>Gateways</p>
                                        </>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>

                    {/*  */}

                    <div className='container-fluid mt-5 text-center'>
                        {
                            planType === 'sim' &&
                            <table className='table shadow-sm p-1 mb-1 bg-white text-dark rounded'>
                                <thead>
                                    <tr>
                                        <th scope='col'></th>
                                        <th scope='col'></th>
                                        <th scope='col'>STATUS</th>
                                        <th scope='col'>NAME</th>
                                        <th scope='col'>DATA USAGE (MB)</th>
                                        <th scope='col'>SMS USAGE</th>
                                        <th scope='col'>COUNTRY</th>
                                        <th scope='col'>NETWORK</th>
                                        <th scope='col'></th>

                                    </tr>
                                </thead>

                                {
                                    simPlanData.map(datum => (
                                        <tbody key={datum.id}>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <CheckBox
                                                        id={datum.id}
                                                        value={value}
                                                        onCheckedHandler={() => { }}
                                                    />
                                                </td>
                                                {
                                                    datum.status === 'active' &&
                                                    <td>
                                                        <img
                                                            className='rounded-circle'
                                                            src={active}
                                                            alt='company logo'
                                                            style={{ width: '20px', marginRight: '8px' }}
                                                            title='status'
                                                        />
                                                    </td>
                                                }
                                                {
                                                    datum.status === 'inactive' &&
                                                    <td>
                                                        <img
                                                            className='rounded-circle'
                                                            src={inactive}
                                                            alt='company logo'
                                                            style={{ width: '20px', marginRight: '8px' }}
                                                            title='status'
                                                        />
                                                    </td>
                                                }
                                                {
                                                    datum.status === 'suspended' &&
                                                    <td>
                                                        <img
                                                            className='rounded-circle'
                                                            src={suspended}
                                                            alt='company logo'
                                                            style={{ width: '20px', marginRight: '8px' }}
                                                            title='status'
                                                        />
                                                    </td>
                                                }
                                                <td>{datum.name}</td>
                                                <td>{datum.dataUsage}</td>
                                                <td>{datum.smsUsage}</td>
                                                <td>{datum.country}</td>
                                                <td>{datum.network}</td>
                                                <td>
                                                    <div
                                                        className='mr-3 btn text-danger button-hover'
                                                        style={{ borderColor: 'red' }}
                                                        onClick={() => onRemovePlanHandler(datum.name)}
                                                    >
                                                        <span>Remove</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))
                                }
                            </table>
                        }

                        {/*  */}

                        {
                            planType === 'lora' &&
                            <table className='table shadow-sm p-1 mb-1 bg-white text-dark rounded'>
                                <thead>
                                    <tr>
                                        <th scope='col'></th>
                                        <th scope='col'></th>
                                        <th scope='col'>STATUS</th>
                                        <th scope='col'>DEVICE NAME</th>
                                        <th scope='col'>EUID</th>
                                        <th scope='col'>LAST CONNECTED</th>
                                        <th scope='col'>PLAN</th>
                                        <th scope='col'></th>

                                    </tr>
                                </thead>

                                {
                                    loraPlanData.map(datum => (
                                        <tbody key={datum.id}>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <CheckBox
                                                        id={datum.id}
                                                        value={value}
                                                        onCheckedHandler={() => { }}
                                                    />
                                                </td>
                                                {
                                                    datum.status === 'active' &&
                                                    <td>
                                                        <img
                                                            className='rounded-circle'
                                                            src={active}
                                                            alt='company logo'
                                                            style={{ width: '20px', marginRight: '8px' }}
                                                            title='status'
                                                        />
                                                    </td>
                                                }
                                                {
                                                    datum.status === 'inactive' &&
                                                    <td>
                                                        <img
                                                            className='rounded-circle'
                                                            src={inactive}
                                                            alt='company logo'
                                                            style={{ width: '20px', marginRight: '8px' }}
                                                            title='status'
                                                        />
                                                    </td>
                                                }
                                                {
                                                    datum.status === 'suspended' &&
                                                    <td>
                                                        <img
                                                            className='rounded-circle'
                                                            src={suspended}
                                                            alt='company logo'
                                                            style={{ width: '20px', marginRight: '8px' }}
                                                            title='status'
                                                        />
                                                    </td>
                                                }
                                                <td>{datum.deviceName}</td>
                                                <td>{datum.euid}</td>
                                                <td>{datum.lastConnected}</td>
                                                <td>{datum.plan}</td>
                                                <td>
                                                    <div
                                                        className='mr-3 btn text-danger button-hover'
                                                        style={{ borderColor: 'red' }}
                                                        onClick={() => onRemovePlanHandler(datum.deviceName)}
                                                    >
                                                        <span>Remove</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))
                                }
                            </table>
                        }

                    </div>

                    {/*  */}

                    <div className='container-fluid'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='mr-auto'></div>
                                <button
                                    className='mb-3 mt-3 ml-auto btn btn-color text-light'
                                    style={{ width: '10rem' }}
                                    onClick={onBackClickHandler}
                                >
                                    <span>Back</span>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </motion.div>
    )
}

export default ViewPlan
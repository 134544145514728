import React from 'react'
import { Modal } from 'react-bootstrap'
import successPlanIcon from '../assets/success-plan.png'

const SuccessModal = ({ show, onHideHandler, message }) => {
    return (
        <div>
            <Modal show={show} onHide={onHideHandler} centered>
                <Modal.Body className='text-center'>
                    <img src={successPlanIcon} alt='success logo' width='40%' />
                    <div>{message}</div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SuccessModal
import React from 'react'
import { Modal } from 'react-bootstrap'

const DialogModal = ({ show, onHideHandler, children }) => {
    return (
        <div>
            <Modal show={show} onHide={onHideHandler} centered>
                <Modal.Body className='text-center'>
                    {children}
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default DialogModal
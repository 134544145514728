import React from 'react'
import { useState } from 'react'
import { motion } from 'framer-motion'
import SelectListGroup from '../../../../common/SelectListGroup'
import { useDispatch } from 'react-redux'
import { setCheckoutStyle, setShippingAddressStyle, setSimSpecsStyle } from '../../../../features/appSlice'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import PhoneInput from 'react-phone-input-2'
import useNetworkStatus from '../../../../common/NetworkHook'

const ShippingAddress = () => {
  
  const { isOnline } = useNetworkStatus()

  console.log(isOnline)

  const dispatch = useDispatch()

  const [quantity, setQuantity] = useState(0)
  const [plan, setPlan] = useState('')

  const [country, setCountry] = useState('')
  const [region, setRegion] = useState('')
  const [isDeactivated, setDeactivated] = useState(false)
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  
  const onCancelHandler = () => {
    dispatch(setSimSpecsStyle())
  }
  
  const onNextHandler = () => {
    dispatch(setCheckoutStyle())
  }

  const quantityOption = [
    { label: 'Select Quantity', value: 'null' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' }
  ]

  const planOption = [
    { label: 'Select Plan', value: 'null' },
    { label: 'Regular', value: 'regular' },
    { label: 'Premium', value: 'premium' },
    { label: 'Business', value: 'business' },
    { label: 'Personal', value: 'personal' },
    { label: 'Truck', value: 'truck' },
    { label: 'Car', value: 'car' }
  ]

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className='container-fluid'
    >
      <div className='container-fluid'>
        <div className='shadow-sm p-2 mb-1 bg-white text-dark rounded mt-1' style={{ height: '100%' }}>

          <div className='text-left mt-3 ml-3'>
            <h5 className="font-weight-bold" style={{ fontSize: '25px' }}>Shipping Information</h5>
          </div>

          <div className="row mr-auto ml-auto">

            <div className="col-md-6 mb-2">


              <div className='text-left mt-4'>
                <small>Address</small>
              </div>
              <div className='input-group rounded'>
                <input
                  type="text"
                  className="form-control"
                  placeholder='Enter your address...'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  style={{ height: '3rem', borderRadius: '10px' }}
                  disabled={isDeactivated}
                />
                {/* <button
                  type="button"
                  className="btn btn-color-dark"
                  style={{ height: '3rem', borderRadius: '0px 10px 10px 0px' }}
                >
                  {size}
                </button> */}
              </div>

              <div className='text-left mt-3'>
                <small>Country</small>
              </div>
              <CountryDropdown
                className='form-control form-control-lg bg-transparent text-dark'
                value={country}
                disabled={isDeactivated}
                onChange={setCountry}
                style={{ borderRadius: '10px' }}
              />


              <div className='text-left mt-3'>
                <small>State</small>
              </div>
              <RegionDropdown
                className='form-control form-control-lg bg-transparent text-dark'
                country={country}
                value={region}
                onChange={setRegion}
                style={{ borderRadius: '10px' }}
              />
            </div>

            {/*  */}

            <div className="col-md-6 mb-2">
              <div className='text-left mt-4'>
                <small>Reciever's Name</small>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder='Your name...'
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ height: '3rem', borderRadius: '10px' }}
                disabled={isDeactivated}
              />

              <div className='text-left mt-3'>
                <small>Receiver's Phone</small>
              </div>
              <PhoneInput
                inputStyle={{ width: '100%', height: '3rem', borderRadius: '10px' }}
                country={'ng'}
                value={phone}
                onChange={setPhone}
                enableSearch={true}
                disabled={isDeactivated}
              />

              {/* <div className='input-group rounded'>
                <input
                  type="input"
                  className="form-control"
                  placeholder='Enter your address...'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  style={{ height: '3rem', borderRadius: '10px' }}
                  disabled={isDeactivated}
                />
              </div> */}

              <div className='text-left mt-3'>
                <small>Email</small>
              </div>
              <div className='input-group rounded'>
                <input
                  type="email"
                  className="form-control"
                  placeholder='Your email...'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ height: '3rem', borderRadius: '10px' }}
                  disabled={isDeactivated}
                />
              </div>

              <div className='container-fluid'>
                <div className='row mt-5'>
                  <div
                    className='col-md-5 mr-auto mb-3 btn button-style text-dark'
                    style={{ height: '3rem', borderRadius: '10px' }}
                    onClick={onCancelHandler}
                  >
                    <p className='mt-1'>Back</p>
                  </div>
                  
                  {
                    isOnline
                      ? <div
                        className='col-md-5 ml-auto mb-3 btn btn-color text-white'
                        style={{ height: '3rem', borderRadius: '10px' }}
                        onClick={onNextHandler}
                      >
                        <p className='mt-1'>Next</p>
                      </div>
                      : <div
                        className='col-md-5 ml-auto mb-3 btn button-style text-dark'
                        style={{ height: '3rem', borderRadius: '10px' }}
                      >
                        <p className='mt-1'>Network Error</p>
                      </div>
                  }
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </motion.div>
  )
}

export default ShippingAddress
import { motion } from 'framer-motion'
import Navbar from '../layout/Navbar'
import PageWithSidebar from '../../common/PageWithSidebar'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setNewUser, setSimClicked, setSimItemDropdown, setUserItem, setUsers } from '../../features/appSlice'
import { useSelector } from 'react-redux'
import AllUsers from './userActivities/AllUsers'
import CreateNewUser from './userActivities/CreateNewUser'

const User = () => {
    
    const { usersStyle, createNewUserStyle, allUsersClicked, createNewUsersClicked } = useSelector(state => state.app.value)

    const dispatch = useDispatch()

    const onUsersClickHandler = () => {
        dispatch(setUsers())
    }
    const onCreateNewUserClickHandler = () => {
        dispatch(setNewUser())
    }

    useEffect(() => {
        dispatch(setSimItemDropdown(false))
        dispatch(setSimClicked(false))
        dispatch(setUserItem())
        
        dispatch(setUsers())
    }, [])

    return (
        <div>
            <Navbar key='nav' />
            <div className="container-fluid">
                <PageWithSidebar>

                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                        exit={{ opacity: 0 }}
                        className='text-left mt-5 ml-3 mb-5'
                    >
                        <div className='container-fluid'>
                            <div className='container-fluid'>
                                <h5 className="font-weight-bold" style={{ fontSize: '28px' }}>User</h5>
                            </div>
                            
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='continer-fluid mt-5 ml-3 mr-auto'>
                                        <button
                                            className={usersStyle}
                                            onClick={onUsersClickHandler}
                                        >
                                            <span><i className="fs-4 bi-people"></i></span>
                                            {/* <img className='mr-3' src={users} alt='icon' width='25px' /> */}
                                            <span> ALL USERS</span>
                                        </button>

                                        <button
                                            className={createNewUserStyle}
                                            onClick={onCreateNewUserClickHandler}
                                        >
                                            <span><i className="fs-4 bi-pen"></i></span>
                                            <span> CREATE NEW USER</span>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {allUsersClicked && <AllUsers />}
                            {createNewUsersClicked && <CreateNewUser />}

                        </div>
                    </motion.div>

                </PageWithSidebar>
            </div>
        </div>
    )
}

export default User
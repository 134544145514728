import React from 'react'
import { motion } from 'framer-motion'
import SimInventoryData from '../../data/SimInventoryData'
import { purchasedSimData } from '../../data/dataJson'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setSimInventoryItem } from '../../../features/appSlice'
import { useState } from 'react'
import Checkout from './purchaseSimActivities/Checkout'
import simInventory from '../../../assets/sim-inventory.png'

const SimInventory = () => {

  const dispatch = useDispatch()

  const [value, setValue] = useState('')

  useEffect(() => {
    dispatch(setSimInventoryItem())
  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className="container-fluid"
    >
      <div className='container-fluid'>
        <div className='container-fluid'>
          <div className='p-1 mb-1 bg-light text-dark rounded mt-5' style={{ height: '3rem' }}>
            <div className='row'>
              <button className='ml-3 mr-5 btn btn-color btn-control text-light'>
                <img src={simInventory} alt='icon' width='27px' />
                <span>SIM INVENTORY</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid'>
        <div className='container-fluid'>
          <div className='shadow-sm p-1 mb-1 mt-3 bg-white text-dark rounded mt-1' style={{ height: '100%' }}>
            <div className="row mr-auto ml-auto mt-3">
              <div className='container-fluid px-5 ml-3'>
                <div className='text-left mt-2 mb-3'>
                  <h5 className="font-weight-bold">SIM Inventory  Summary</h5>
                </div>

                

                {/* <div className='container-fluid'>
                
                <div className='row mb-4 text-center'>
                  <div className='col-md-2 shadow-sm p-3 mb-3 bg-white rounded' style={{ height: '6rem' }}>
                    <h4 className='font-weight-bold'>120</h4>
                    <small>Total Gentro SIMs</small>
                  </div>
                  
                  <div className='mb-3 px-5 btn-white' />
                  
                  <div className='col-md-2 shadow-sm p-3 mb-3 bg-white text-success rounded' style={{ height: '6rem' }}>
                    <h4 className='font-weight-bold'>100</h4>
                    <small>Active SIMs</small>
                  </div>
                  
                  <div className='mb-3 ml-1 px-5 btn-white' />
                  
                  <div className='col-md-2 shadow-sm p-3 mb-3 bg-white text-muted rounded' style={{ height: '6rem' }}>
                    <h4 className='font-weight-bold'>15</h4>
                    <small>Inactive SIMs</small>
                  </div>
                  
                  <div className='mb-3 px-5 btn-white' />
                  
                  <div className='col-md-2 shadow-sm p-3 mb-3 bg-white text-danger rounded' style={{ height: '6rem' }}>
                    <h4 className='font-weight-bold'>5</h4>
                    <small>Deactivated SIMs</small>
                  </div>
                </div>
              
                </div> */}
                

                
                
                
                <div className='container-fluid'>
                  <div className="row mt-5 mb-4 text-center">
                    <div className="mr-auto">
                      <div className="shadow-sm p-3 mb-3 bg-white rounded" style={{ height: '6rem', width: '11rem' }}>
                        <h5>
                          <span className="col-md-12 font-weight-bold" style={{ fontSize: '38px' }}>120</span><p style={{ fontSize: '16px' }}>Total Gentro SIMs</p>
                        </h5>
                      </div>
                    </div>
                    
                    <div className="mr-auto">
                      <div className="shadow-sm p-3 mb-3 bg-white text-success rounded" style={{ height: '6rem', width: '11rem' }}>
                        <h5>
                          <span className="col-md-12 font-weight-bold" style={{ fontSize: '38px' }}>100</span>
                          <p style={{ fontSize: '16px' }}>Active SIMs </p>
                        </h5>
                      </div>
                    </div>

                    <div className="mr-auto">
                      <div className="shadow-sm p-3 mb-3 bg-white text-muted rounded" style={{ height: '6rem', width: '11rem' }}>
                        <h5>
                          <span className="col-md-12 font-weight-bold" style={{ fontSize: '38px' }}>15</span>
                          <p style={{ fontSize: '16px' }}>Inactive SIMs </p>
                        </h5>
                      </div>
                    </div>
                    
                    <div className="mr-auto">
                      <div className="shadow-sm p-3 mb-3 bg-white text-danger rounded" style={{ height: '6rem', width: '11rem' }}>
                        <h5>
                          <span className="col-md-12 font-weight-bold" style={{ fontSize: '38px' }}>5</span>
                          <p style={{ fontSize: '16px' }}>Deactivated SIMs </p>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>





                {/* <div className='shadow-sm p-1 mb-1 bg-white text-dark rounded mt-3' style={{ height: '3rem' }}>
                  <div className='row'>
                    <div className='col-md-2 mt-2 d-none d-sm-inline'>INVOICE NO</div>
                    <div className='col-md-2 mt-2 d-none d-sm-inline'>NO OF SIMs</div>
                    <div className='col-md-2 mt-2 d-none d-sm-inline'>AMOUNT(USD)</div>
                    <div className='col-md-2 mt-2 d-none d-sm-inline'>DATE</div>
                    <div className='col-md-3 mt-2 d-none d-sm-inline'>PAYMENT STATUS</div>
                    <div className='col-md-1 mt-2 d-none d-sm-inline'></div>
                  </div>
                </div>
                <SimInventoryData data={purchasedSimData} /> */}




                <table className='mt-3 table shadow-sm p-1 mb-1 bg-white text-dark rounded'>
                  <thead>
                    <tr>
                      <th scope='col'>INVOICE NO</th>
                      <th scope='col'>NO OF SIMs</th>
                      <th scope='col'>AMOUNT(USD)</th>
                      <th scope='col'>DATE</th>
                      <th scope='col'>PAYMENT STATUS</th>
                      <th scope='col'></th>
                    </tr>
                  </thead>

                  {
                    purchasedSimData.map(datum => (
                      <tbody key={datum.id}>
                        <tr>
                          <td>{datum.invoiceNumber}</td>
                          <td>{datum.numberOfSim}</td>
                          <td>{datum.amount}</td>
                          <td>{datum.date}</td>
                          <td>
                            {
                              datum.paymentStatus === 'PAID' &&
                              <div className='col-md-3 mt-2 text-success d-none d-sm-inline'>{datum.paymentStatus}</div>
                            }
                            {
                              datum.paymentStatus === 'PENDING' &&
                              <div className='col-md-3 mt-2 text-danger d-none d-sm-inline'>{datum.paymentStatus}</div>
                            }
                          </td>
                          <td>
                            <div className='btn button-style' style={{ height: '35px', width: '6rem', fontSize: '15px' }}>VIEW</div>
                          </td>
                        </tr>
                      </tbody>
                    ))
                  }

                </table>
              
              </div>

            </div>
          </div>

        </div>
      </div>
    </motion.div>
  )
}

export default SimInventory
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import logo from '../../assets/logo_green.png'
import noImage from '../../assets/no_image.png'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { setProfileDropdown } from '../../features/appSlice'

import editProfile from '../../assets/edit-profile.png'
import logout from '../../assets/logout.png'


const Navbar = () => {

    const isProfileDropdown = useSelector(state => state.app.value.isProfileDropdown)

    const dispatch = useDispatch()

    const onCancelHandler = () => {
    
    }
    const onEditHandler = () => {
    
    }
    const onLogoutHandler = () => {
    
    }
    
    const onProfileClickHandler = () => {
        dispatch(setProfileDropdown(!isProfileDropdown))
    }

    return (
        <nav className="sticky-top navbar navbar-expand-sm navbar-dark bg-white elevation">
            <div className="container-fluid">
                <Link
                    className="navbar-brand"
                    to={'/dashboard'}
                    onClick={() => { }}
                >
                    <img src={logo} alt='connect' className='logo' />
                </Link>

                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mobile-nav">
                    <span className="navbar-toggler-icon"></span>
                </button> */}



                <ul className="navbar-nav ml-auto">
                    {/* <li
                        className="nav-item dropdown"
                        onClick={onProfileClickHandler}
                    >
                        <Link to="#" className="nav-link text-dark">
                            <img
                                className='rounded-circle'
                                src={noImage}
                                alt='company logo'
                                style={{ width: '30px', marginRight: '8px' }}
                                title='Company logo'
                            />
                            Gentro Limited
                        </Link>
                    </li> */}

                    <li className="nav-item dropdown">
                        <Link
                            className="nav-link dropdown-toggle text-dark mr-4"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        // onClick={onProfileClickHandler}
                        >
                            <img
                                className='rounded-circle'
                                src={noImage}
                                alt='profile image'
                                style={{ width: '30px', marginRight: '8px' }}
                                title='Profile Image'
                            />
                            Gentro Limited
                        </Link>
                        <div className="dropdown-menu px-3" aria-labelledby="navbarDropdown" style={{ width: '110%' }}>
                            {/* <Link className="dropdown-item" to="#">Action</Link>
                            <Link className="dropdown-item" to="#">Another action</Link>
                            <div className="dropdown-divider"></div>
                            <Link className="dropdown-item" to="#">Something else here</Link> */}

                            <div className='row'>
                                <div className='mr-auto ml-3'>
                                    <img
                                        className='rounded-circle mt-3'
                                        src={noImage}
                                        alt='profile image'
                                        style={{ width: '30px', marginRight: '8px' }}
                                        title='Profile Image'
                                    />
                                </div>
                                <i className="mt-3 btn bi bi-x-lg d-none d-sm-inline text-light mr-3" style={{ border: '0px', backgroundColor: '#DD9696' }} onClick={onCancelHandler}></i>
                            </div>
                            
                            <div className='text-left mt-2'>Pax</div>
                            <div className='text-left mt-2 text-truncate'>paxian.solutions@gmail.com</div>

                            <hr />

                            <div className='row btn mr-2' onClick={onEditHandler}>
                                <img
                                    className='rounded-circle'
                                    src={editProfile}
                                    alt='edit'
                                    style={{ width: '30px', marginRight: '8px' }}
                                    title='Edit Profile'
                                />
                                Edit Profile
                            </div>

                            <div className='row btn' onClick={onLogoutHandler}>
                                <img
                                    className='rounded-circle'
                                    src={logout}
                                    alt='logout'
                                    style={{ width: '30px', marginRight: '8px' }}
                                    title='Logout'
                                />
                                Logout
                            </div>

                        </div>
                    </li>
                
                </ul>

                {
                    // // !isLogoClicked &&
                    // <div className="collapse navbar-collapse" id="mobile-nav">

                    //     {/* <ul className="navbar-nav mr-auto">
                    //         <li className="nav-item">
                    //             <Link className="nav-link" to={'/profiles'}>

                    //             </Link>
                    //         </li>
                    //     </ul> */}

                    //     <ul className="navbar-nav ml-auto">
                    //         {!isCreateFootballQuestionsLinkClicked
                    //             ? <li className="nav-item">
                    //                 <Link
                    //                     className="nav-link navbar_text_color"
                    //                     to={'/football-questions'}
                    //                     // style={{ color: '#FFFFFF' }}
                    //                     onClick={() => {
                    //                         dispatch(setCreateFootballQuestionsLinkClicked(true))
                    //                         dispatch(setLogoClickedState(false))
                    //                     }}
                    //                 >
                    //                     Create Football Question
                    //                 </Link>
                    //             </li>
                    //             : <li className="nav-item">
                    //                 <Link
                    //                     className="nav-link navbar_text_color"
                    //                     to={'/sport-types'}
                    //                     // style={{ color: '#FFFFFF' }}
                    //                     onClick={() => {
                    //                         dispatch(setCreateFootballQuestionsLinkClicked(false))
                    //                         dispatch(setLogoClickedState(false))
                    //                     }}
                    //                 >
                    //                     Create Sport Type
                    //                 </Link>
                    //             </li>
                    //         }
                    //     </ul>

                    // </div>
                }

            </div>
        </nav>

    )
}

export default Navbar
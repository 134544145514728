import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Chart } from "react-google-charts"
import { barData, barOptions, data, dataOptions } from '../../data/dataJson'
import { useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { setFundWallet, setPaynowClicked } from '../../../features/appSlice'

const MyWallet = () => {

    const { exchangeRate, currency } = useSelector(state => state.app.value)

    const dispatch = useDispatch()

    const [isCheckedNaira, setNairaChecked] = useState(true)
    const [isCheckedDollar, setDollarChecked] = useState(false)

    const [avaialbleFundsHasFraction, setAvailableFundsHasFraction] = useState(true)
    const [outstandingFundsHasFraction, setOutstandingFundsHasFraction] = useState(true)
    // const [currency, setCurrency] = useState('₦')
    const [availableFunds, setAvailableFunds] = useState(100067)    // Dollar value rendered to 2 decumal place
    const [outstandingDebts, setOutstandingDebts] = useState(8500)     // Dollar value rendered to 2 decumal place

    const [isSelectCurrencyDropdown, setSelectCurrencyDropdown] = useState(false)
    const [dropdownText, setDropdownText] = useState('Select Currency')

    const wholeAvailableFunds = parseInt(Math.round(((availableFunds * exchangeRate) / 100) * 100) / 100)
    const availableFundsWithFractions = Math.round(((availableFunds * exchangeRate) / 100) * 100) / 100

    const wholeOutstandingFunds = parseInt(Math.round(((outstandingDebts * exchangeRate) / 100) * 100) / 100)
    const outstandingFundsWithFractions = Math.round(((outstandingDebts * exchangeRate) / 100) * 100) / 100


    // console.log(outstandingDebts.toString().slice(-2) == '50')
    // console.log(process.env.REACT_APP_EXCHANGE_RATE_API_KEY)



    // const onStatusFIlterTapHandler = () => {
    //     setSelectCurrencyDropdown(!isSelectCurrencyDropdown)
    // }

    // const onWalletClickHandler = () => {
    //     dispatch(setWalletStyle())
    // }
    // const onFundWalletClickHandler = () => {
    //     dispatch(setFundWalletStyle())
    // }
    // const onHistoryClickHandler = () => {
    //     dispatch(setHistoryStyle())
    // }
    // const onNairaClicked = () => {
    //     setCurrency('₦')
    //     setDropdownText('Currency: NGN')
    // }
    // const onDollarClicked = () => {
    //     setCurrency('$')
    //     setDropdownText('Currency: USD')
    // }

    const onPayNowHandler = () => {
        dispatch(setFundWallet('PAY OUTSTANDING DEBT'))
        dispatch(setPaynowClicked(true))
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
        >
            <div className='container-fluid'>
                <div className="row mt-5 mb-4 text-center">

                    <div className="col-md-6">
                        <div
                            className="shadow-sm p-3 mb-3 bg-white"
                            style={{ height: '7rem', borderRadius: '10px' }}
                            onClick={() => setSelectCurrencyDropdown(false)}
                        >
                            <h5>
                                <span className="font-weight-bold" style={{ fontSize: '38px' }}>
                                    {/* <small><small>{currency}</small></small> */}
                                    {/* {currency == '₦' ? availableFunds * exchangeRate : availableFunds} */}
                                    <CurrencyFormat
                                        value={
                                            currency == '₦'
                                                ? Math.round(((availableFunds * exchangeRate) / 100) * 100) / 100
                                                : availableFunds / 100
                                        }
                                        displayType={'text'}
                                        isNumericString={true}
                                        thousandSeparator={true}
                                        renderText={
                                            (value) =>
                                                <div>
                                                    <small><small>{currency}</small></small>
                                                    {value}
                                                    {/* {
                                                                        availableFunds.toString().slice(-2) == '50'
                                                                            ? value
                                                                            : <>{avaialbleFundsHasFraction ? value : `${value}.00`}</>
                                                                    } */}
                                                </div>
                                        }
                                    />
                                </span>
                                <p className='txt-color-light' style={{ fontSize: '16px' }}>Available Funds</p>
                            </h5>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div
                            className="shadow-sm p-3 mb-3 bg-white text-danger"
                            style={{ height: '7rem', borderRadius: '10px' }}
                            onClick={() => setSelectCurrencyDropdown(false)}
                        >
                            <h5>
                                <span className="font-weight-bold" style={{ fontSize: '38px' }}>
                                    <CurrencyFormat
                                        value={
                                            currency == '₦'
                                                ? Math.round(((outstandingDebts * exchangeRate) / 100) * 100) / 100
                                                : outstandingDebts / 100
                                        }
                                        displayType={'text'}
                                        isNumericString={true}
                                        thousandSeparator={true}
                                        renderText={
                                            (value) =>
                                                <div>
                                                    <small><small>{currency}</small></small>
                                                    {value}
                                                </div>
                                        }
                                    />
                                </span>
                                <p className='txt-light-red' style={{ fontSize: '16px' }}>Outstanding Debt</p>
                            </h5>
                        </div>
                    </div>
                </div>

                <div
                    className="form-control"
                    style={{
                        height: '5rem',
                        borderRadius: '10px',
                        background: 'linear-gradient(to right, rgba(77, 146, 0, 0.5), rgba(168, 251, 207, 0.5))'
                    }}
                >
                    <div className='row mt-3'>
                        <div className="col-md-6 text-white px-3 ml-auto mr-auto btn">

                            <CurrencyFormat
                                value={
                                    currency == '₦'
                                        ? Math.round(((outstandingDebts * exchangeRate) / 100) * 100) / 100
                                        : outstandingDebts / 100
                                }
                                displayType={'text'}
                                isNumericString={true}
                                thousandSeparator={true}
                                renderText={
                                    (value) =>
                                        <div className='text-left'>
                                            Outstanding Debt of <small className='txt-dark-grey'>{currency}</small>
                                            <span className='txt-dark-grey'>{value}</span>
                                        </div>
                                }
                            />
                        </div>
                        <button
                            className='col-md-4 mr-auto btn btn-color text-light shadow-sm'
                            onClick={onPayNowHandler}
                        >
                            <span>Pay Now</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className='col-md-12 mt-5'>
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={data}
                    options={dataOptions}
                />
            </div>

            <div className='col-md-8 ml-auto mr-auto mt-5 mb-3'>
                <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={barData}
                    options={barOptions}
                />
            </div>
        </motion.div>
    )
}

export default MyWallet
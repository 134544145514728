import React from 'react'
import { motion } from 'framer-motion'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import FloatingLabelTextInput from '../../../common/FloatingLabelTextInput'
import SelectListGroup from '../../../common/SelectListGroup'
import { CountryDropdown } from 'react-country-region-selector'
import CheckBox from '../../../common/CheckBox'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Loading from '../../../common/Loading'
import { setCheckedGateway, setMyPlans, setViewClicked, setViewPlanData } from '../../../features/appSlice'
import SuccessModal from '../../../common/SuccessModal'
import { gatewayItem } from '../../data/dataJson'
// import { networkData } from '../../data/dataJson'
import { MultiSelect } from 'react-multi-select-component'
// import "./styles.css";
import Select from "react-select"
import MultiSelectDropdown from '../../../common/MultiSelectDropdown'
import { getGateway, getNetworks, modifyCheckedGateway, modifytNetworks } from '../../../methods/appMethods'
import CustomMultiSelect from '../../../common/CustomMultiSelect'
import CustomMultiSelectGroup from '../../../common/CustomMultiSelectGroup'

const CreateOrEditPlans = ({ actionTitle, planType }) => {

  const { windowDimension, exchangeRate, gatewayArray, networkData, checkedGateway } = useSelector(state => state.app.value)

  console.log(checkedGateway)

  const dispatch = useDispatch()

  const [selectedOptions, setSelectedOptions] = useState()
  const [selected, setSelected] = useState([])

  const [dataAllocation, setDataAllocation] = useState(0)
  const [messages, setMessages] = useState('')
  const [dataSize, setDataSize] = useState('MB')
  const [cost, setCost] = useState(0)
  // const [exchangeRate, setExchangeRate] = useState(420)
  const [createPlan, setCreatePlan] = useState(false)
  const [successModal, showSuccessModal] = useState(false)
  const [isDeactivated, setDeactivated] = useState(false)
  const [canDIsmissModal, setCanDismissModal] = useState(false)
  const [planName, setPlanName] = useState('')
  const [limit, setLimit] = useState('')
  const [costPerMonth, setCostPerMonth] = useState('')
  const [country, setCountry] = useState('')

  const [isNetworkDropdown, setNetworkDropdown] = useState(false)
  const [networkBorderRadius, setNetworkBorderRadius] = useState('10px')
  const [networkHeight, setNetworkHeight] = useState('3rem')

  const [gateWay, setGateWay] = useState(false)
  const [gateWay2, setGateWay2] = useState(false)
  const [subGateWay, setSubGateWay] = useState(false)
  const [checkedCity, setCheckedCity] = useState('')

  const [ikeja, setIkeja] = useState(false)
  const [surulere, setSurulere] = useState(false)
  const [lekki, setLekki] = useState(false)

  const [ibadan, setIbadan] = useState(false)
  const [bodija, setBodija] = useState(false)
  const [sango, setSango] = useState(false)

  const [isChecked, setChecked] = useState(false)
  const [value, setValue] = useState('')
  const [airtel, setAirtel] = useState(false)
  const [glo, setGlo] = useState(false)
  const [nineMobile, setNineMobile] = useState(false)

  const [networkField, setNetworkField] = useState('')
  const [gateWayField, setGateWayField] = useState('Select Gateway')

  const messagesOptions = [
    { label: 'Number of Messages', value: 'null' },
    { label: '10,000 messages per Month', value: '10,000 messages per Month' },
    { label: '20,000 messages per Month', value: '20,000 messages per Month' },
    { label: '30,000 messages per Month', value: '30,000 messages per Month' },
    { label: '40,000 messages per Month', value: '40,000 messages per Month' }
  ]

  const dataAllocationOptions = [
    { label: 'Allocate', value: 'null' },
    { label: '10', value: '10' },
    { label: '20', value: '20' },
    { label: '30', value: '30' },
    { label: '40', value: '40' }
  ]

  const sizeOptions = [
    { label: 'KB', value: 'KB' },
    { label: 'MB', value: 'MB' },
    { label: 'GB', value: 'GB' }
  ]

  const myData = [
    { text: 'Atlanta', group: 'US', value: 'atl' },
    { text: 'Boston', group: 'US', value: 'bos' },
    { text: 'Bath', group: 'UK', value: 'bat' },
    { text: 'Bristol', group: 'UK', value: 'bri' },
  ]

  const options = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry", disabled: true },
  ]

  const optionList = [
    { value: "red", label: "Red" },
    { value: "green", label: "Green" },
    { value: "yellow", label: "Yellow" },
    { value: "blue", label: "Blue" },
    { value: "white", label: "White" }
  ]

  const styles = {
    select: {
      // height: '3rem',
      borderRadius: '10px',
      borderColor: '#106437'
    }
  }

  const handleSelect = (data) => {
    setSelectedOptions(data);
  }

  const onGateWayChecked = (gatewayData) => {
    // if (ikeja || surulere || lekki) {
    //   return
    // }

    // setIkeja(!ikeja)
    // setSurulere(!surulere)
    // setLekki(!lekki)

    console.log(gatewayData.groupName)

    // setGateWay(!gateWay)
    setCheckedCity(gatewayData.groupName)

    if (checkedCity == gatewayData.groupName) setSubGateWay(true)

  }

  const onGateWay2Checked = (id) => {
    if (ibadan || bodija || sango) {
      return
    }

    setGateWay2(!gateWay2)
    // setSubGateWay2(!subGateWay2)

    setIbadan(!ibadan)
    setBodija(!bodija)
    setSango(!sango)
  }



  const [networkList, setNetworkList] = useState({
    list: ['Select Network'],
  });

  const multiElements = []

  const onCheckHandler = (data) => {

    // const networkData = {
    //   label: data.label,
    //   isChecked: !data.isChecked
    // }

    // modifytNetworks(dispatch, networkData)

    setValue(data.label)
    // setChecked(!isChecked)

    if (value != data.label) {

      multiElements.push(data.label)

      setNetworkList((prevState) => ({
        list: [...prevState.list, ...multiElements],
      }))

      return
    }

    // Remove un-checked element from the array
    const filtered = networkList.list.filter(item => item != data.label)

    setNetworkList((prevState) => ({
      list: [...filtered],
    }))
  }










  const onAirtelChecked = () => {
    setAirtel(!airtel)

    if (!airtel) {
      multiElements.push('Airtel Ng')

      setNetworkList((prevState) => ({
        list: [...prevState.list, ...multiElements],
      }))
    }
    else {
      const filtered = networkList.list.filter(item => item !== 'Airtel Ng')

      setNetworkList((prevState) => ({
        list: [...filtered],
      }))
    }

  }

  const onGloChecked = () => {
    setGlo(!glo)

    if (!glo) {
      multiElements.push('Glo')

      setNetworkList((prevState) => ({
        list: [...prevState.list, ...multiElements],
      }))
    }
    else {
      const filtered = networkList.list.filter(item => item !== 'Glo')

      setNetworkList((prevState) => ({
        list: [...filtered],
      }))
    }

  }

  const onNineMobileChecked = () => {
    setNineMobile(!nineMobile)

    if (!nineMobile) {
      multiElements.push('9Mobile')

      setNetworkList((prevState) => ({
        list: [...prevState.list, ...multiElements],
      }))
    }
    else {
      const filtered = networkList.list.filter(item => item !== '9Mobile')

      setNetworkList((prevState) => ({
        list: [...filtered],
      }))
    }

  }

  const onClearHandler = () => {
    setValue(false)
    setAirtel(false)
    setGlo(false)
    setNineMobile(false)

    multiElements.push('Select Network')

    setNetworkList((prevState) => ({
      list: [...multiElements],
    }));
  }

  const onNetworkTapHandler = () => {
    setNetworkDropdown(!isNetworkDropdown)
    // setCanDismissModal(true)
  }

  const onCancelHandler = () => {
    if (actionTitle === 'Edit Plan') {
      dispatch(setViewClicked(true))
    }

    dispatch(setMyPlans())
  }

  const onCreateHandler = () => {
    setCreatePlan(true)

    setTimeout(() => {
      setCreatePlan(false)
      showSuccessModal(true)
    }, 3000);
  }

  const onModalHideHandler = () => {
    showSuccessModal(false)
    dispatch(setMyPlans())
  }

  // useEffect(() => {
  //   setGateWay(true)
  // }, [ikeja, surulere, lekki])

  // useEffect(() => {
  //   setGateWay2(true)
  // }, [ibadan, bodija])

  useEffect(() => {
    if (value) setNetworkField(networkList.list.join(', ').slice(15))
    else setNetworkField('Select Network')

    console.log(networkList.list.join(', ').slice(15))

  }, [value])

  useEffect(() => {
    getNetworks(dispatch)
    getGateway(dispatch)
  }, [])

  useEffect(() => {
    if (isNetworkDropdown) setNetworkBorderRadius('10px 10px 0px 0px')
    else setNetworkBorderRadius('10px')

  }, [isNetworkDropdown])

  useEffect(() => {
    if (networkList.list.length > 3) {
      setNetworkHeight('auto')
      setNetworkBorderRadius('10px')
    }
    else setNetworkHeight('3rem')

  }, [networkList.list.length])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className='container-fluid'
    // onClick={() => {
    //   if (canDIsmissModal) {
    //     setTimeout(() => {
    //       setNetworkDropdown(false)
    //       setCanDismissModal(false)
    //     }, 300);
    //   }
    // }}
    >
      {createPlan && <Loading />}

      <SuccessModal
        message={
          actionTitle === 'Create Plan'
            ? `${planName != '' ? planName : 'Arbitrary Plan'} Created Successfully!`
            : `${planName != '' ? planName : 'Arbitrary Plan'} Successfully Updated!`
        }
        show={successModal}
        onHideHandler={onModalHideHandler}
      />

      <div className='container-fluid'>
        <div className='shadow-sm p-2 mb-1 bg-white text-dark rounded mt-1' style={{ height: '100%' }}>

          <div className='text-left mt-3 ml-3'>
            <h5 className="font-weight-bold" style={{ fontSize: '25px' }}>{actionTitle}</h5>
          </div>

          <div className="row mr-auto ml-auto">

            <div className="col-md-6 mb-2">
              <div className='text-left mt-4'>
                <small>Plan Name</small>
              </div>
              <div className='input-group rounded'>
                <input
                  type="input"
                  className="form-control"
                  placeholder='Enter plan name...'
                  value={planName}
                  onChange={(e) => setPlanName(e.target.value)}
                  style={{ height: '3rem', borderRadius: '10px' }}
                  disabled={isDeactivated}
                />
              </div>


              <div className='row mt-3'>

                <div className={planType === 'sim' ? 'col-md-8' : 'col-md-12'}>
                  <div className='text-left'>
                    <small>{planType === 'sim' ? 'Data Allocation' : 'Message'}</small>
                  </div>
                  <SelectListGroup
                    borderRadius='10px'
                    bootstrapClass='form-control form-control-lg bg-transparent text-dark'
                    placeholder={planType === 'sim' ? 'Allocate' : 'Number of Messages'}
                    name={planType === 'sim' ? 'dataAllocation' : 'messages'}
                    value={planType === 'sim' ? dataAllocation : messages}
                    onChange={(e) => {
                      if (e.target.value === 'null') {
                        if (planType === 'sim') setDataAllocation('Allocate')
                        else setMessages('Number of Messages')

                        return
                      }

                      if (planType === 'sim') {
                        if (e.target.value === '10') setDataAllocation('10')
                        if (e.target.value === '20') setDataAllocation('20')
                        if (e.target.value === '30') setDataAllocation('30')
                        if (e.target.value === '40') setDataAllocation('40')
                      }
                      else {
                        if (e.target.value === '10,000 messages per Month') setMessages('10,000 messages per Month')
                        if (e.target.value === '20,000 messages per Month') setMessages('20,000 messages per Month')
                        if (e.target.value === '30,000 messages per Month') setMessages('30,000 messages per Month')
                        if (e.target.value === '40,000 messages per Month') setMessages('40,000 messages per Month')
                      }

                    }}
                    options={planType === 'sim' ? dataAllocationOptions : messagesOptions}
                    // info={answerInfo}
                    disabled={isDeactivated}
                  />
                </div>

                {
                  planType === 'sim' &&
                  <div className='col-md-4'>
                    <div className='text-left'>
                      <small>Size</small>
                    </div>
                    <SelectListGroup
                      borderRadius='10px'
                      bootstrapClass='form-control form-control-lg bg-transparent text-dark'
                      placeholder='Size'
                      name='size'
                      value={dataSize}
                      onChange={(e) => {

                        if (e.target.value === 'KB') setDataSize('KB')
                        if (e.target.value === 'MB') setDataSize('MB')
                        if (e.target.value === 'GB') setDataSize('GB')
                      }}
                      options={sizeOptions}
                      // info={answerInfo}
                      disabled={isDeactivated}
                    />
                  </div>
                }

              </div>

              <div className='text-left'>
                <small>{planType === 'sim' ? 'Limit' : 'Cost per Device per Month'}</small>
              </div>
              <div className='input-group rounded'>
                <input
                  type="input"
                  className="form-control"
                  placeholder={planType === 'sim' ? 'Enter limit...' : cost}
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  style={{ height: '3rem', borderRadius: '10px 0px 0px 10px' }}
                  disabled={planType === 'sim' ? isDeactivated : true}
                />
                <button
                  type="button"
                  className="btn btn-color-dark"
                  style={{ height: '3rem', borderRadius: '0px 10px 10px 0px' }}
                >
                  {planType === 'sim' ? dataSize : '$'}
                </button>
              </div>
              {
                planType === 'lora' &&
                <div className='row'>
                  <div className='text-left ml-3 text-success'>
                    <small>Equivalence</small>
                  </div>
                  <div className='text-right ml-auto mr-3 text-success'>
                    <small>{`${cost * exchangeRate} (NGN)`}</small>
                  </div>
                </div>
              }

            </div>

            {/*  */}

            <div className="col-md-6 mb-2">
              <div className='text-left mt-4'>
                <small>Country</small>
              </div>
              <CountryDropdown
                className='form-control form-control-lg bg-transparent text-dark'
                value={country}
                disabled={isDeactivated}
                onChange={setCountry}
                style={{ borderRadius: '10px' }} />

              <div className='text-left mt-3'>
                <small>{planType === 'sim' ? 'Network' : 'Gateway'}</small>
              </div>
              {
                planType === 'sim' &&

                <CustomMultiSelect networkData={networkData} />
              }

              {
                planType === 'lora' &&

                // <CustomMultiSelectGroup gatewayData={gatewayData} />

                <div className='shadow-sm network mr-auto' style={{ height: networkHeight, width: 'auto', borderRadius: networkBorderRadius }}>
                  <div className='container-fluid' onClick={onNetworkTapHandler}>
                    <div className='row d-flex'>

                      <div className='mt-2 py-1 px-1 ml-3'>{gateWayField}</div>
                      <div className='mt-2 py-1 ml-auto mr-1'>
                        {
                          isNetworkDropdown
                            ? <i className="ml-4 px-3 bi bi-chevron-up d-none d-sm-inline"></i>
                            : <i className="ml-4 px-3 bi bi-chevron-down d-none d-sm-inline"></i>
                        }
                      </div>
                    </div>
                  </div>

                  {
                    isNetworkDropdown &&
                    <motion.div
                      initial={{ height: '0rem' }}
                      animate={{ height: 'auto', transition: { duration: 0.5 } }}
                      exit={{ y: window.innerHeight }}
                      className='shadow networkDropdown'
                      style={{ borderRadius: '0px 0px 10px 10px' }}
                    >
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1.5 } }}
                        exit={{ opacity: 0 }}
                        className='container-fluid mt-2'
                      >
                        {
                          gatewayArray.map(gatewayData => (
                            <div key={gatewayData._id} className='row d-flex'>
                              <div className='mt-3 mb-3 ml-5'>
                                <CheckBox
                                  id={gatewayData._id}
                                  label={gatewayData.groupName}
                                  // value={checkedValue}
                                  onCheckedHandler={() => onGateWayChecked(gatewayData)}
                                />
                                {
                                  gatewayData.location.map(loc => (
                                    <div key={loc._id} className='ml-5'>
                                      <CheckBox
                                        label={loc.city}
                                        id={loc._id}
                                        // checked={checkedGatewayData.city == loc.city? checkedGatewayData.isChecked : null}
                                        // onCheckedHandler={() => modifyCheckedGateway(dispatch, { city: loc.city, isChecked: !loc.isChecked})}
                                        onCheckedHandler={() => dispatch(setCheckedGateway(loc.city))}
                                      />
                                    </div>
                                  ))
                                }

                              </div>
                            </div>
                          ))
                        }




                        {/* <div className='row d-flex'>
                          <div className='mt-2 mb-3 ml-5'>
                            <CheckBox
                              label='Lagos'
                              id='lagos'
                              checked={gateWay}
                              onCheckedHandler={onGateWayChecked}
                            />
                            <div className='ml-5'>
                              <CheckBox
                                label='Ikeja'
                                id='ikeja'
                                checked={ikeja}
                                onCheckedHandler={() => {
                                  setIkeja(!ikeja)

                                  if (gateWay && !lekki && !surulere) setGateWay(false)
                                  else setGateWay(true)
                                }}
                              />
                              <CheckBox
                                label='Lekki'
                                id='lekki'
                                checked={lekki}
                                onCheckedHandler={() => {
                                  setLekki(!lekki)
                                  
                                  if (gateWay && !ikeja && !surulere) setGateWay(false)
                                  else setGateWay(true)
                                }}
                              />
                              <CheckBox
                                label='Yaba'
                                id='yaba'
                                checked={surulere}
                                onCheckedHandler={() => {
                                  setSurulere(!surulere)

                                  if (gateWay && !ikeja && !lekki) setGateWay(false)
                                  else setGateWay(true)
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='row d-flex'>
                          <div className='mt-2 mb-3 ml-5'>
                            <CheckBox
                              label='Oyo'
                              id='oyo'
                              checked={gateWay2}
                              onCheckedHandler={onGateWay2Checked}
                            />
                            <div className='ml-5'>
                              <CheckBox
                                label='Ibadan'
                                id='ibadan'
                                checked={ibadan}
                                onCheckedHandler={() => {
                                  setIbadan(!ibadan)
                                  
                                  if (gateWay2 && !bodija && !sango) setGateWay2(false)
                                  else setGateWay2(true)
                                }}
                              />
                              <CheckBox
                                label='Bodija'
                                id='bodija'
                                checked={bodija}
                                onCheckedHandler={() => {
                                  setBodija(!bodija)
                                  
                                  if (gateWay2 && !ibadan && !sango) setGateWay2(false)
                                  else setGateWay2(true)
                                }}
                              />
                              <CheckBox
                                label='Sango'
                                id='sango'
                                checked={sango}
                                onCheckedHandler={() => {
                                  setSango(!sango)
                                  
                                  if (gateWay2 && !ibadan && !bodija) setGateWay2(false)
                                  else setGateWay2(true)
                                }}
                              />
                            </div>
                          </div>
                        </div> */}

                      </motion.div>
                    </motion.div>
                  }
                </div>

              }

              <div style={{ visibility: planType === 'sim' ? 'visible' : 'hidden' }}>
                <div className='text-left mt-3'>
                  <small>Cost per SIM per Month</small>
                </div>
                <div className='input-group rounded'>
                  <input
                    type="input"
                    className="form-control"
                    placeholder='0'
                    value={costPerMonth}
                    onChange={(e) => setCostPerMonth(e.target.value)}
                    style={{ height: '3rem', borderRadius: '10px 0px 0px 10px' }}
                    disabled={true}
                  />
                  <button
                    type="button"
                    className="btn btn-color-dark"
                    style={{ height: '3rem', borderRadius: '0px 10px 10px 0px' }}
                  >
                    $
                  </button>
                </div>
              </div>

              <div className='container-fluid'>
                <div className='row mt-5'>
                  <div
                    className='col-md-5 mr-auto mb-3 btn color-border-inactive text-dark'
                    style={{ height: '3rem', borderRadius: '10px' }}
                    onClick={onCancelHandler}
                  >
                    <p className='mt-1'>Cancel</p>
                  </div>
                  <div
                    className='col-md-5 ml-auto mb-3 btn btn-color text-white'
                    style={{ height: '3rem', borderRadius: '10px' }}
                    onClick={onCreateHandler}
                  >
                    <p className='mt-1'>{actionTitle === 'Create Plan' ? 'Create' : 'Save Changes'}</p>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>

    </motion.div>
  )
}

export default CreateOrEditPlans
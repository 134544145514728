import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { groupData } from '../../data/dataJson'
import { useSelector } from 'react-redux'
import active from '../../../assets/active.png'
import inactive from '../../../assets/inactive.png'
import suspended from '../../../assets/suspended.png'
import { useDispatch } from 'react-redux'
import { setDeleteGroup, setMyGroup, setViewGroupClicked } from '../../../features/appSlice'
import warningInfo from '../../../assets/warning-info.png'
import DialogModal from '../../../common/DialogModal'
import SuccessModal from '../../../common/SuccessModal'

const ViewGroup = () => {

    const { viewGroupData, isDeleteGroup } = useSelector(state => state.app.value)

    const [showWarningDialog, setWarningDialog] = useState(false)
    const [groupName, setGroupName] = useState('')
    const [successModal, showSuccessModal] = useState(false)

    const dispatch = useDispatch()

    const onCancelClickHandler = () => {
        setWarningDialog(false)
    }
    const onConfirmRemoveOrDeleteHandler = () => {
        setWarningDialog(false)
        showSuccessModal(true)
    }

    const onRemoveGroupHandler = (data) => {
        dispatch(setDeleteGroup(false))
        setWarningDialog(true)

        setGroupName(data.name)
    }
    
    const onBackClickHandler = () => {
        dispatch(setViewGroupClicked(false))
    }

    const onModalHideHandler = () => {
        showSuccessModal(false)
        setTimeout(() => dispatch(setMyGroup()), 1000);
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
        >
            <SuccessModal
                message={`${groupName} was successfully removed!`}
                show={successModal}
                onHideHandler={onModalHideHandler}
            />

            <DialogModal
                show={showWarningDialog}
            >
                <img src={warningInfo} alt='success logo' width='30%' />
                <div>You are about to remove {groupName} from {viewGroupData.planName}!</div>

                <div className='continer-fluid mt-3 ml-3 mr-auto'>
                    <button
                        className='mr-3 mb-3 btn btn-light text-danger text-white'
                        style={{ borderColor: 'red' }}
                        onClick={onCancelClickHandler}
                    >
                        <span>Cancel</span>
                    </button>

                    <button
                        className='mr-3 mb-3 btn btn-danger text-white'
                        onClick={onConfirmRemoveOrDeleteHandler}
                    >
                        <span>Remove</span>
                    </button>
                </div>
            </DialogModal>

            <div className='p-3 mb-1 bg-white text-dark rounded' style={{ height: '100%' }}>
                <div className='container-fluid'>
                    <div className='container-fluid mt-4'>
                        <div className='row'>
                            <h5 className='font-weight-bold mr-auto ml-3'>{viewGroupData.planName}</h5>
                            <h5 className='ml-3 mr-3 text-success'>Total SIM Cards: {viewGroupData.numberOfsims}</h5>
                        </div>
                        <small className='mr-auto'>{viewGroupData.description}</small>
                    </div>

                    {/*  */}

                    <div className='container-fluid mt-5'>
                        <table className='table shadow-sm p-1 mb-1 bg-white text-dark rounded'>
                            <thead>
                                <tr>
                                    <th scope='col'></th>
                                    <th scope='col'></th>
                                    <th scope='col'>NAME</th>
                                    <th scope='col'>ICCID</th>
                                    <th scope='col'>IMSI</th>
                                    <th scope='col'></th>

                                </tr>
                            </thead>

                            {
                                groupData.map(datum => (
                                    <tbody key={datum.id}>
                                        <tr>
                                            <td></td>
                                            {
                                                datum.status === 'active' &&
                                                <td>
                                                    <img
                                                        className='rounded-circle'
                                                        src={active}
                                                        alt='company logo'
                                                        style={{ width: '20px', marginRight: '8px' }}
                                                        title='status'
                                                    />
                                                </td>
                                            }
                                            {
                                                datum.status === 'inactive' &&
                                                <td>
                                                    <img
                                                        className='rounded-circle'
                                                        src={inactive}
                                                        alt='company logo'
                                                        style={{ width: '20px', marginRight: '8px' }}
                                                        title='status'
                                                    />
                                                </td>
                                            }
                                            {
                                                datum.status === 'suspended' &&
                                                <td>
                                                    <img
                                                        className='rounded-circle'
                                                        src={suspended}
                                                        alt='company logo'
                                                        style={{ width: '20px', marginRight: '8px' }}
                                                        title='status'
                                                    />
                                                </td>
                                            }
                                            <td>{datum.name}</td>
                                            <td>{datum.iccid}</td>
                                            <td>{datum.imsi}</td>
                                            <td>
                                                <div
                                                    className='mr-3 btn text-danger button-hover'
                                                    style={{ borderColor: 'red' }}
                                                    onClick={() => onRemoveGroupHandler(datum)}
                                                >
                                                    <span>Remove</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                ))
                            }
                        </table>

                    </div>

                    {/*  */}

                    <div className='container-fluid'>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='mr-auto'></div>
                                <button
                                    className='mb-3 mt-3 ml-auto btn btn-color text-light'
                                    style={{ width: '10rem' }}
                                    onClick={onBackClickHandler}
                                >
                                    <span>Back</span>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </motion.div>
    )
}

export default ViewGroup
import { configureStore } from "@reduxjs/toolkit";
import errorReducer from '../features/errorSlice'
import authReducer from '../features/authSlice'
import appReducer from '../features/appSlice'

const store = configureStore({
    reducer: {
        error: errorReducer,
        auth: authReducer,
        app: appReducer
    }
})

export default store
import { motion } from 'framer-motion'
import Navbar from '../layout/Navbar'
import PageWithSidebar from '../../common/PageWithSidebar'
import { useSelector } from 'react-redux'
import ManageSIMs from './simActivities/ManageSIMs'
import PurchaseSIMs from './simActivities/PurchaseSIMs'
import { useEffect } from 'react'
import { setManageSimItem, setManageSimSelected, setSimClicked, setSimItem } from '../../features/appSlice'
import { useDispatch } from 'react-redux'
import SimInventory from './simActivities/SimInventory'
import filter from '../../assets/filter.png'
import chevronUp from '../../assets/circle-chevron-up.png'
import chevronDown from '../../assets/circle-chevron-down.png'
import { useState } from 'react'
import CheckBox from '../../common/CheckBox'
import SelectListGroup from '../../common/SelectListGroup'

const Sims = () => {

    const { isManageSimSelected, isSimPurchaseSelected, isSimInventorySelected } = useSelector((state) => state.app.value)

    const dispatch = useDispatch()

    const [isStatusFilterDropdown, setStatusDropdown] = useState(false)
    const [isFIlterByDropdown, setFilterBYDropdown] = useState(false)

    const [isDeactivated, setDeactivated] = useState(false)
    const [plan, setPlan] = useState('')
    
    const [value, setValue] = useState('')
    
    const onStatusFIlterTapHandler = () => {
        setStatusDropdown(!isStatusFilterDropdown)
    }

    const onFilterByTapHandler = () => {
        setFilterBYDropdown(!isFIlterByDropdown)
    }

    const onCancelHandler = () => {
        setFilterBYDropdown(false)
    }

    const onApplyHandler = () => {
        setFilterBYDropdown(false)
    }

    const planOptions = [
        { label: 'Choose Plan', value: 'null' },
        { label: 'Regular', value: 'regular' },
        { label: 'Premium', value: 'premium' }
    ]

    useEffect(() => {
        dispatch(setSimClicked(true))
        dispatch(setManageSimSelected(true))

        dispatch(setSimItem())
        dispatch(setManageSimItem())
    }, [])

    return (
        <div>
            <Navbar key='nav' />
            <div className="container-fluid">
                <PageWithSidebar>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                        exit={{ opacity: 0 }}
                    >
                        <div className='container-fluid'>
                            <div className='container-fluid'>
                                <div className='container-fluid'>

                                    <div className='container-fluid' style={{ visibility: isManageSimSelected ? 'visible' : 'hidden' }}>
                                        <div className='row d-flex'>

                                            <div className='shadow-sm filterStatusButton mr-auto'>
                                                <div className='container-fluid' onClick={onStatusFIlterTapHandler}>
                                                    <div className='row d-flex'>
                                                        <div className='mt-1 ml-2'><img src={filter} alt='icon' width='20px' /></div>
                                                        <div className='mt-2 ml-2'>Filter Status</div>
                                                        <div className='mt-1 ml-auto mr-2'>
                                                            {isStatusFilterDropdown
                                                                ? <img src={chevronUp} alt='icon' width='25px' />
                                                                : <img src={chevronDown} alt='icon' width='25px' />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    isStatusFilterDropdown &&
                                                    <motion.div
                                                        initial={{ height: '0rem' }}
                                                        animate={{ height: '10rem', transition: { duration: 0.5 } }}
                                                        exit={{ y: window.innerHeight }}
                                                        className='shadow-sm filterStatusDropdown'
                                                    >
                                                        <motion.div
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1, transition: { duration: 1.5 } }}
                                                            exit={{ opacity: 0 }}
                                                            className='container-fluid mt-2'
                                                        >
                                                            <div className='row d-flex'>
                                                                <div className='mt-3 ml-5'>
                                                                    <CheckBox
                                                                        id='allDevices'
                                                                        label='All Devices'
                                                                        value={value}
                                                                        onCheckedHandler={() => setValue('')}
                                                                    />
                                                                </div>
                                                                {/* <div className='mt-3 mr-auto'>All Devices</div> */}
                                                            </div>
                                                            <div className='row d-flex'>
                                                                <div className='mt-2 ml-5'>
                                                                    <CheckBox
                                                                        id='active'
                                                                        label='Active'
                                                                        value={value}
                                                                        onCheckedHandler={() => setValue('')}
                                                                    />
                                                                </div>
                                                                {/* <div className='mt-2 mr-auto text-success'>Active</div> */}
                                                            </div>
                                                            <div className='row d-flex'>
                                                                <div className='mt-2 ml-5'>
                                                                    <CheckBox
                                                                        id='inactive'
                                                                        label='Inactive'
                                                                        value={value}
                                                                        onCheckedHandler={() => setValue('')}
                                                                    />
                                                                </div>
                                                                {/* <div className='mt-2 mr-auto text-muted'>Inactive</div> */}
                                                            </div>
                                                            <div className='row d-flex'>
                                                                <div className='mt-2 ml-5'>
                                                                    <CheckBox
                                                                        id='suspended'
                                                                        label='Suspended'
                                                                        value={value}
                                                                        onCheckedHandler={() => setValue('')}
                                                                    />
                                                                </div>
                                                                {/* <div className='mt-2 mr-auto text-danger'>Suspended</div> */}
                                                            </div>
                                                        </motion.div>
                                                    </motion.div>
                                                }
                                            </div>

                                            {/* <div className=' mr-auto mb-3 px-0 btn-empty-light' /> */}

                                            <div className='shadow-sm filterBy ml-auto'>
                                                <div className='container-fluid' onClick={onFilterByTapHandler}>
                                                    <div className='row d-flex'>
                                                        <div className='mt-2 ml-2'>Filter By</div>
                                                        <div className='mt-2 ml-auto mr-2'>
                                                            {isFIlterByDropdown
                                                                ? <img src={chevronUp} alt='icon' width='25px' />
                                                                : <img src={chevronDown} alt='icon' width='25px' />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    isFIlterByDropdown &&
                                                    <motion.div
                                                        initial={{ height: 0 }}
                                                        animate={{ height: '22rem', transition: { duration: 0.5 } }}
                                                        exit={{ y: window.innerHeight }}
                                                        className='shadow-sm filterByDropdown'
                                                    >
                                                        <motion.div
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1, transition: { duration: 1.5 } }}
                                                            exit={{ opacity: 0 }}
                                                            className='container-fluid mt-2'
                                                        >
                                                            <div className='row d-flex m-auto'>
                                                                <div className='text-left mt-3'>ICCID</div>
                                                                <div className='input-group rounded'>
                                                                    <input
                                                                        type="search"
                                                                        className="form-control"
                                                                        placeholder="Search..."
                                                                        style={{ height: '3rem', borderRadius: '10px 0px 0px 10px' }}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-color-dark"
                                                                        style={{ height: '3rem', borderRadius: '0px 10px 10px 0px' }}
                                                                    >
                                                                        <i className="fas fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>


                                                            <div className='row d-flex m-auto'>
                                                                <div className='text-left mt-3'>IMSI</div>
                                                                <div className='input-group rounded'>
                                                                    <input
                                                                        type="search"
                                                                        className="form-control"
                                                                        placeholder="Search..."
                                                                        style={{ height: '3rem', borderRadius: '10px 0px 0px 10px' }}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-color-dark"
                                                                        style={{ height: '3rem', borderRadius: '0px 10px 10px 0px' }}
                                                                    >
                                                                        <i className="fas fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className='mt-3'>
                                                                <div className='text-left'>PLANS</div>
                                                                <SelectListGroup
                                                                    bootstrapClass='form-control bg-transparent text-dark'
                                                                    borderRadius='10px'
                                                                    placeholder='Plans'
                                                                    name='plans'
                                                                    value={plan}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === 'null') {
                                                                            setPlan('Choose Plan')

                                                                            return
                                                                        }

                                                                        if (e.target.value === 'regular') setPlan('Regular')
                                                                        if (e.target.value === 'premium') setPlan('premium')
                                                                    }}
                                                                    options={planOptions}
                                                                    // info={answerInfo}
                                                                    disabled={isDeactivated}
                                                                />
                                                            </div>

                                                            <div className="row mr-auto">
                                                                <div className="ml-auto mt-3">
                                                                    <button
                                                                        className="mb-3 btn btn-color-light btn-control text-dark"
                                                                        onClick={onCancelHandler}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                                <div className="ml-3 mt-3">
                                                                    <button
                                                                        className="mb-3 btn btn-color btn-control text-light"
                                                                        onClick={onApplyHandler}
                                                                    >
                                                                        Apply
                                                                    </button>
                                                                </div>
                                                            </div>


                                                        </motion.div>
                                                    </motion.div>
                                                }
                                            </div>

                                        </div>
                                    </div>

                                </div>


                                <div className='text-left mt-5 ml-3 mb-5'>
                                    <h5 className="font-weight-bold" style={{ fontSize: '28px' }}>SIMs</h5>
                                </div>
                            </div>
                        </div>

                        {isManageSimSelected && <ManageSIMs />}
                        {isSimPurchaseSelected && <PurchaseSIMs />}
                        {isSimInventorySelected && <SimInventory />}
                    </motion.div>

                </PageWithSidebar>
            </div>
        </div>
    )
}

export default Sims
import { motion } from 'framer-motion'
import Navbar from '../layout/Navbar'
import PageWithSidebar from '../../common/PageWithSidebar'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setCreateNewPlans, setDeletePlan, setEditPlan, setMyPlans, setGroupItem, setSimClicked, setSimPlansItem, setViewClicked, setMyGroup, setCreateNewGroup, setViewGroupClicked, setDeleteGroup, setSimItemDropdown } from '../../features/appSlice'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import MyPlans from './planActivities/MyPlans'
import CreateOrEditPlans from './planActivities/CreateOrEditPlans'
import MyGroups from './groupActivities/MyGroups'
import CreateNewGroup from './groupActivities/CreateNewGroup'
import DialogModal from '../../common/DialogModal'
import warningInfo from '../../assets/warning-info.png'
import SuccessModal from '../../common/SuccessModal'
import ModalAction from '../../common/ModalAction'



const Groups = () => {

    const { myGroupStyle, createNewGroupStyle, isGroupClicked, isCreateNewGroupClicked, isViewGroupClicked, editGroup, viewGroupData, isDeleteGroup } = useSelector(state => state.app.value)

    const dispatch = useDispatch()


    const [showWarningDialog, setWarningDialog] = useState(false)
    const [successModal, showSuccessModal] = useState(false)
    const [alertMsg, setAlertMsg] = useState('')
    const [alertModal, setAlertModal] = useState(false)

    const onGroupClickHandler = () => {
        dispatch(setMyGroup())
    }

    const onCreateNewGroupHandler = () => {
        dispatch(setCreateNewGroup())
        dispatch(setViewGroupClicked(false))
    }

    const onDeleteGroupHandler = () => {
        dispatch(setDeleteGroup(true))
        setWarningDialog(true)
    }

    const onEditNewGroupHandler = () => {
        setAlertModal(true)
        setTimeout(() => setAlertModal(false), 4000)
    }
    
    const onCancelClickHandler = () => {
        setWarningDialog(false)
    }
    const onConfirmRemoveOrDeleteHandler = () => {
        setWarningDialog(false)
        showSuccessModal(true)
    }

    const onModalHideHandler = () => {
        showSuccessModal(false)
        setTimeout(() => dispatch(setMyGroup()), 1000);
    }

    useEffect(() => {
        dispatch(setSimItemDropdown(false))
        dispatch(setSimClicked(false))
        dispatch(setGroupItem())

        dispatch(setMyGroup())
    }, [])

    return (
        <div>
            <Navbar key='nav' />
            <div className="container-fluid">
                <PageWithSidebar>

                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                        exit={{ opacity: 0 }}
                        className='text-left mt-5 ml-3 mb-5'
                    >
                        <ModalAction
                            body='UI not available!'
                            show={alertModal}
                            onHideHandler={() => setAlertModal(false)}
                        />
                        <SuccessModal
                            message={`${viewGroupData.planName} was deleted successfully!`}
                            show={successModal}
                            onHideHandler={onModalHideHandler}
                        />

                        <DialogModal
                            show={showWarningDialog}
                        >
                            <img src={warningInfo} alt='success logo' width='30%' />
                            <div>You are about to delete {viewGroupData.planName}!</div>

                            <div className='continer-fluid mt-3 ml-3 mr-auto'>
                                <button
                                    className='mr-3 mb-3 btn btn-light text-danger text-white'
                                    style={{ borderColor: 'red' }}
                                    onClick={onCancelClickHandler}
                                >
                                    <span>Cancel</span>
                                </button>

                                <button
                                    className='mr-3 mb-3 btn btn-danger text-white'
                                    onClick={onConfirmRemoveOrDeleteHandler}
                                >
                                    <span>Delete</span>
                                </button>
                            </div>
                        </DialogModal>

                        <div className='container-fluid'>
                            <div className='container-fluid'>
                                <h5 className="font-weight-bold" style={{ fontSize: '28px' }}>Groups</h5>
                            </div>

                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='continer-fluid mt-5 ml-3 mr-auto'>
                                        <button
                                            className={myGroupStyle}
                                            onClick={onGroupClickHandler}
                                        >
                                            <span><i className="fs-4 bi-option"></i></span>
                                            <span> MY GROUP</span>
                                        </button>

                                        <button
                                            className={createNewGroupStyle}
                                            style={{ visibility: editGroup ? 'hidden' : 'visible' }}
                                            onClick={onCreateNewGroupHandler}
                                        >
                                            <span><i className="fs-4 bi-pen"></i></span>
                                            <span> CREATE NEW GROUP</span>
                                        </button>
                                    </div>

                                    {isViewGroupClicked && <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1, transition: { duration: 1 } }}
                                        exit={{ opacity: 0 }}
                                        // style={{ visibility: isViewGroupClicked ? 'visible' : 'hidden' }}
                                        className='continer-fluid mt-5 ml-3'
                                    >
                                        <button
                                            className='mr-3 mb-3 btn btn-light text-danger button-hover'
                                            style={{ borderColor: 'red' }}
                                            onClick={onDeleteGroupHandler}
                                        >
                                            <span>Delete Group</span>
                                        </button>

                                        <button
                                            className='mr-3 mb-3 btn btn-color text-light'
                                            onClick={onEditNewGroupHandler}
                                        >
                                            <span>Edit Group</span>
                                        </button>
                                    </motion.div>}
                                </div>
                            </div>
                            
                            {isGroupClicked && <MyGroups />}
                            {isCreateNewGroupClicked && <CreateNewGroup />}
                        
                        </div>
                    </motion.div>

                </PageWithSidebar>
            </div>
        </div>
    )
}

export default Groups
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuthenticated: false,
    currentUser: {}
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: { value: initialState },
    reducers: {
        setCurrentUser: (state, action) => {
            state.value.isAuthenticated = true;
            state.value.currentUser = action.payload
        }
    }
})

export const { setCurrentUser } = authSlice.actions

export default authSlice.reducer
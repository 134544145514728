import React, { useState } from 'react'
import { MultiSelect } from 'react-multi-select-component'

const CustomSelect = ({ networkData }) => {

    const [selected, setSelected] = useState([])

    const overrrideStrings = {
        "allItemsAreSelected": `${selected.map(({ label }) => ' ' + label)}`,
        "clearSearch": "Clear Search",
        "clearSelected": "Clear Selected",
        "noOptions": "No options",
        "search": "Search...",
        "selectAll": "Select All",
        "selectAllFiltered": "Select All (Filtered)",
        "selectSomeItems": "Select Network",
        "create": "Create",
    }
    
    const customValueRenderer = (selected, _options) => {
        return selected.length
            ? selected.map(({ label }) => "✔️ " + label)
            : "Select Network";
    }

    const filterOptionsHandler = (options, filter) => {
        if (!filter) {
            return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ value }) => value && value.match(re));
    }

    return (
        <div>
            {/* <h2>Select Fruits</h2>
                  <pre>{JSON.stringify(selected)}</pre> */}

            <MultiSelect
                // className="dark-style"
                options={networkData}
                value={selected}
                overrideStrings={overrrideStrings}
                onChange={setSelected}
            // labelledBy="Select"
            // filterOptions={filterOptionsHandler}
            // valueRenderer={customValueRenderer}
            />
        </div>
    )
}

export default CustomSelect
import React from 'react'
import { useState } from 'react'
import { motion } from 'framer-motion'
import SelectListGroup from '../../../common/SelectListGroup'
import { useDispatch } from 'react-redux'
import useNetworkStatus from '../../../common/NetworkHook'
import { setUsers } from '../../../features/appSlice'
import Spinner from '../../../common/Spinner'
import SuccessModal from '../../../common/SuccessModal'

const CreateNewUser = () => {
    
    const { isOnline } = useNetworkStatus()
    
    console.log(isOnline)
    
    const dispatch = useDispatch()
    
    const [isDeactivated, setDeactivated] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [firstname, setFirstname] = useState('')
    const [selectedRole, setRole] = useState('')
    const [isUserCreated, setUserCreated] = useState(false)
    const [successModal, showSuccessModal] = useState(false)

    const userRightsOptions = [
        { label: 'Select Role', value: 'null' },
        { label: 'Super Admin', value: 'Super Admin' },
        { label: 'Admin', value: 'Admin' },
        { label: 'Staff', value: 'Staff' }
    ]
    
    const onCancelHandler = () => {
        if (isDeactivated) return
        dispatch(setUsers())
    }
    
    const onCreateUserHandler = () => {
        setDeactivated(true)
        setUserCreated(true)
        
        setTimeout(() => {
            showSuccessModal(true)
            setUserCreated(false)
            setDeactivated(false)
        }, 3000);
    }
    
    const onModalHideHandler = () => {
        showSuccessModal(false)
        setTimeout(() => dispatch(setUsers()), 1000);
    }
    
    const CreateUserButton = () => {
        return (
            <>
                {
                    isUserCreated
                        ?
                        <motion.button
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1 } }}
                            exit={{ opacity: 0 }}
                            className="col-md-5 ml-auto mb-3 btn btn-light text-white"
                            disabled={isDeactivated}
                            style={{ height: '3rem', borderRadius: '10px' }}
                        >
                            <Spinner />
                        </motion.button>
                        :
                        <div
                            className='col-md-5 ml-auto mb-3 btn btn-color text-white'
                            style={{ height: '3rem', borderRadius: '10px' }}
                            onClick={onCreateUserHandler}
                        >
                            <p className='mt-1'>Create User</p>
                        </div>
                }
            </>
        )
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
        >
            <SuccessModal
                message='User created successfully!'
                show={successModal}
                onHideHandler={onModalHideHandler}
            />

            <div className='container-fluid'>
                <div className='shadow-sm p-2 mb-1 bg-white text-dark rounded mt-3' style={{ height: '100%' }}>

                    <div className='text-left mt-3 ml-3'>
                        <h5 className="font-weight-bold" style={{ fontSize: '20px' }}>Create New User</h5>
                    </div>

                    <div className="row mr-auto ml-auto">

                        <div className="col-md-6 mb-2">


                            <div className='text-left mt-4'>
                                <small>First Name</small>
                            </div>
                            <div className='input-group rounded'>
                                <input
                                    type="name"
                                    className="form-control"
                                    placeholder='Enter First Name'
                                    value={firstname}
                                    onChange={(e) => setFirstname(e.target.value)}
                                    style={{ height: '3rem', borderRadius: '10px' }}
                                    disabled={isDeactivated}
                                />
                            </div>

                            <div className='text-left mt-3'>
                                <small>Last Name</small>
                            </div>
                            <input
                                type="name"
                                className="form-control"
                                placeholder='Enter Last Name'
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                style={{ height: '3rem', borderRadius: '10px' }}
                                disabled={isDeactivated}
                            />


                            <div className='text-left mt-3'>
                                <small>User Role</small>
                            </div>
                            <SelectListGroup
                                borderRadius='10px'
                                bootstrapClass='form-control form-control-lg bg-transparent text-dark'
                                placeholder='Select Role'
                                name='selectRole'
                                value={selectedRole}
                                onChange={(e) => {
                                    if (e.target.value === 'null') {
                                        setRole(null)

                                        return
                                    }

                                    if (e.target.value === 'Super Admin') setRole('Super Admin')
                                    if (e.target.value === 'Admin') setRole('Admin')
                                    if (e.target.value === 'Staff') setRole('Staff')
                                }}
                                options={userRightsOptions}
                                disabled={isDeactivated}
                            />
                        </div>

                        {/*  */}

                        <div className="col-md-6 mb-2">
                            <div className='text-left mt-4'>
                                <small>Email</small>
                            </div>
                            <input
                                type="email"
                                className="form-control"
                                placeholder='Enter Email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                style={{ height: '3rem', borderRadius: '10px' }}
                                disabled={isDeactivated}
                            />

                            <div className='text-left mt-3'>
                                <small>Password</small>
                            </div>
                            <input
                                type="password"
                                className="form-control"
                                placeholder='Enter Password'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ height: '3rem', borderRadius: '10px' }}
                                disabled={isDeactivated}
                            />

                            <div className='text-left mt-3'>
                                <small>Confirm Password</small>
                            </div>
                            <div className='input-group rounded'>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder='Confirm Password'
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    style={{ height: '3rem', borderRadius: '10px' }}
                                    disabled={isDeactivated}
                                />
                            </div>
                            
                            <div className='container-fluid'>
                                <div className='row mt-5'>
                                    <div
                                        className='col-md-5 mr-auto mb-3 btn button-style text-dark'
                                        style={{ height: '3rem', borderRadius: '10px' }}
                                        onClick={onCancelHandler}
                                    >
                                        <p className='mt-1' style={{ cursor: isDeactivated && 'wait' }}>Cancel</p>
                                    </div>

                                    {
                                        isOnline
                                            ? <CreateUserButton />
                                            : <div
                                                className='col-md-5 ml-auto mb-3 btn button-style text-dark'
                                                style={{ height: '3rem', borderRadius: '10px' }}
                                            >
                                                <p className='mt-1'>Network Error</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                
                </div>
            </div>

        </motion.div>
    )
}

export default CreateNewUser
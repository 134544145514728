import { motion } from 'framer-motion'
import Navbar from '../layout/Navbar'
import PageWithSidebar from '../../common/PageWithSidebar'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setCreateNewPlans, setDeletePlan, setEditPlan, setMyPlans, setSimClicked, setSimItemDropdown, setSimPlansItem, setViewClicked } from '../../features/appSlice'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import MyPlans from './planActivities/MyPlans'
import CreateOrEditPlans from './planActivities/CreateOrEditPlans'

const SimPlan = () => {

    const { mySimPlanStyle, createNewPlanStyle, myPlansClicked, createNewPlansClicked, editPlan, isViewClicked } = useSelector(state => state.app.value)

    // const [editPlan, setEditPlan] = useState(false)
    
    const dispatch = useDispatch()

    const onPlansClickHandler = () => {
        dispatch(setMyPlans())
        dispatch(setViewClicked(false))
    }

    const onCreateNewPlansHandler = () => {
        dispatch(setCreateNewPlans())
        dispatch(setViewClicked(false))
    }

    const onDeletePlanHandler = () => {
        dispatch(setDeletePlan(true))
    }

    const onEditNewPlansHandler = () => {
        dispatch(setEditPlan())
        dispatch(setViewClicked(false))
    }

    useEffect(() => {
        dispatch(setSimItemDropdown(false))
        dispatch(setSimClicked(false))
        dispatch(setSimPlansItem())

        dispatch(setMyPlans())
    }, [])

    return (
        <div>
            <Navbar key='nav' />
            <div className="container-fluid">
                <PageWithSidebar>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                        exit={{ opacity: 0 }}
                        className='text-left mt-5 ml-3 mb-5'
                    >
                        <div className='container-fluid'>
                            <div className='container-fluid'>
                                <h5 className="font-weight-bold" style={{ fontSize: '28px' }}>SIM Plans</h5>
                            </div>
                        </div>
                        
                        <div className='container-fluid'>
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='continer-fluid mt-5 ml-3 mr-auto'>
                                        <button
                                            className={mySimPlanStyle}
                                            onClick={onPlansClickHandler}
                                        >
                                            <span><i className="fs-4 bi-option"></i></span>
                                            <span> MY PLANS</span>
                                        </button>

                                        <button
                                            className={createNewPlanStyle}
                                            style={{ visibility: editPlan ? 'hidden' : 'visible' }}
                                            // disabled={ editPlan ? true : false }
                                            onClick={onCreateNewPlansHandler}
                                        >
                                            <span><i className="fs-4 bi-pen"></i></span>
                                            <span> CREATE NEW PLANS</span>
                                        </button>
                                    </div>

                                    <div
                                        className='continer-fluid mt-5 ml-3'
                                        style={{ visibility: isViewClicked ? 'visible' : 'hidden' }}
                                    >
                                        <button
                                            className='mr-3 mb-3 btn btn-light text-danger button-hover'
                                            style={{ borderColor: 'red' }}
                                            onClick={onDeletePlanHandler}
                                        >
                                            <span>Delete Plan</span>
                                        </button>

                                        <button
                                            className='mr-3 mb-3 btn btn-color text-light'
                                            onClick={onEditNewPlansHandler}
                                        >
                                            <span>Edit Plan</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {myPlansClicked && <MyPlans planType='sim' />}
                        {createNewPlansClicked && <CreateOrEditPlans actionTitle='Create Plan' planType='sim' />}
                        {editPlan && <CreateOrEditPlans actionTitle='Edit Plan' planType='sim' />}

                    </motion.div>
                </PageWithSidebar>
            </div>
        </div>
    )
}

export default SimPlan
import { motion } from 'framer-motion'
import Navbar from '../layout/Navbar'
import PageWithSidebar from '../../common/PageWithSidebar'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import ManageSIMs from './simActivities/ManageSIMs'
import PurchaseSIMs from './simActivities/PurchaseSIMs'
import { setLoraItem, setManageSimItem, setManageSimSelected, setSimClicked, setSimItem, setSimItemDropdown } from '../../features/appSlice'
import SimInventory from './simActivities/SimInventory'
import filter from '../../assets/filter.png'
import chevronUp from '../../assets/circle-chevron-up.png'
import chevronDown from '../../assets/circle-chevron-down.png'
import { useState } from 'react'
import CheckBox from '../../common/CheckBox'
import SelectListGroup from '../../common/SelectListGroup'

import { simData } from '../data/dataJson'
import active from '../../assets/active.png'
import inactive from '../../assets/inactive.png'
import suspended from '../../assets/suspended.png'
import plus from '../../assets/plus.png'
import { useNavigate } from 'react-router-dom'
import DialogModal from '../../common/DialogModal'


import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import PhoneInput from 'react-phone-input-2'
import SuccessModal from '../../common/SuccessModal'
import Spinner from '../../common/Spinner'




const LoRa = () => {

    const { isManageSimSelected, isSimPurchaseSelected, isSimInventorySelected } = useSelector((state) => state.app.value)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [isStatusFilterDropdown, setStatusDropdown] = useState(false)
    const [isFIlterByDropdown, setFilterBYDropdown] = useState(false)
    
    const [isDeactivated, setDeactivated] = useState(false)
    const [plan, setPlan] = useState('')
    const [showAddDeviceDialog, setAddDeviceDialog] = useState(false)

    const [value, setValue] = useState('')
    
    const [selectedPlan, setSelectedPlan] = useState(null)

    const [successModal, showSuccessModal] = useState(false)
    const [isConfirmed, setConfirmed] = useState(false)


    const [country, setCountry] = useState('')
    const [region, setRegion] = useState('')
    const [deviceName, setDeviceName] = useState('')
    const [euidNumber, setEuidNumber] = useState('')
    const [apiKey, setApiKey] = useState('')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')

    const planOptions = [
        { label: 'Choose Plan', value: 'null' },
        { label: 'Regular Plan', value: 'Regular Plan' },
        { label: 'Premium Plan', value: 'Premium Plan' }
    ]
    
    const onCancelModal = () => {
        if(isDeactivated) return
        setAddDeviceDialog(false)
    }

    
    const onConfirmAddDeviceHandler = () => {
        setConfirmed(true)
        setDeactivated(true)

        setTimeout(() => {
            setDeactivated(false)
            setAddDeviceDialog(false)
            setConfirmed(false)
            showSuccessModal(true)
        }, 3000);
    }

    const onAddDeviceHandler = () => {
        // navigate('/send-sms')
        setAddDeviceDialog(true)
    }


    const onStatusFIlterTapHandler = () => {
        setStatusDropdown(!isStatusFilterDropdown)
    }

    const onFilterByTapHandler = () => {
        setFilterBYDropdown(!isFIlterByDropdown)
    }

    const onCancelHandler = () => {
        setFilterBYDropdown(false)
    }

    const onApplyHandler = () => {
        setFilterBYDropdown(false)
    }

    const onModalHideHandler = () => {
        showSuccessModal(false)
        setSelectedPlan(null)
    }

    const AddDeviceButton = () => {
        return (
            <div>
                {isConfirmed
                    ?
                    <motion.button
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 1 } }}
                        exit={{ opacity: 0 }}
                        className="ml-auto mb-3 btn btn-light text-white"
                        disabled={isDeactivated}
                        style={{ height: '3rem', width: '7rem', borderRadius: '10px' }}
                    >
                        <Spinner />
                    </motion.button>
                    :
                    // <button
                    //     className='mr-3 mb-3 btn btn-color text-white'
                    //     style={{ width: '8rem', borderRadius: '5px' }}
                    //     onClick={onConfirmAddDeviceHandler}
                    // >
                    //     <span>Add Device</span>
                    // </button>

                    <div
                        className='ml-auto mb-3 btn btn-color text-white'
                        style={{ height: '3rem', borderRadius: '10px' }}
                        onClick={onConfirmAddDeviceHandler}
                    >
                        <p className='mt-1'>Add Device</p>
                    </div>
                }
            </div>
        )
    }

    useEffect(() => {
        dispatch(setSimItemDropdown(false))
        dispatch(setSimClicked(false))
        dispatch(setLoraItem())
    }, [])

    return (
        <div>
            <Navbar key='nav' />
            <div className="container-fluid">
                <PageWithSidebar>

                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5 } }}
                        exit={{ opacity: 0 }}
                    >
                        <SuccessModal
                            message='Device added successfully!'
                            show={successModal}
                            onHideHandler={onModalHideHandler}
                        />

                        <DialogModal
                            show={showAddDeviceDialog}
                            onHideHandler={onCancelModal}
                        >
                            <div className="row mr-auto ml-auto">

                                <div className="col-md-6 mb-2">


                                    <div className='text-left mt-4'>
                                        <small>NAME</small>
                                    </div>
                                    <div className='input-group rounded'>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='Device Name...'
                                            value={deviceName}
                                            onChange={(e) => setDeviceName(e.target.value)}
                                            style={{ height: '3rem', borderRadius: '10px' }}
                                            disabled={isDeactivated}
                                        />
                                    </div>

                                    <div className='text-left mt-3'>
                                        <small>PLANS</small>
                                    </div>
                                    <SelectListGroup
                                        borderRadius='10px'
                                        bootstrapClass='form-control form-control-lg bg-transparent text-dark'
                                        placeholder='Select Plan'
                                        name='select Plan'
                                        value={selectedPlan}
                                        onChange={(e) => {
                                            if (e.target.value === 'null') {
                                                setSelectedPlan(null)

                                                return
                                            }

                                            if (e.target.value === 'Regular Plan') setSelectedPlan('Regular Plan')
                                            if (e.target.value === 'Premium Plan') setSelectedPlan('Premium Plan')
                                        }}
                                        options={planOptions}
                                        // info={answerInfo}
                                        disabled={isDeactivated}
                                    />


                                    <div className='text-left mt-3'>
                                        <small>EUID NUMBER</small>
                                    </div>
                                    <div className='input-group rounded'>
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder='EUID Number...'
                                            value={euidNumber}
                                            onChange={(e) => setEuidNumber(e.target.value)}
                                            style={{ height: '3rem', borderRadius: '10px' }}
                                            disabled={isDeactivated}
                                        />
                                    </div>
                                </div>

                                {/*  */}

                                <div className="col-md-6 mb-2">
                                    <div className='text-left mt-4'>
                                        <small>APPEUI</small>
                                    </div>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder='APPEUI Number...'
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        style={{ height: '3rem', borderRadius: '10px' }}
                                        disabled={isDeactivated}
                                    />

                                    <div className='text-left mt-3'>
                                        <small>APP KEY</small>
                                    </div>
                                    <div className='input-group rounded'>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder='API Key...'
                                            value={apiKey}
                                            onChange={(e) => setApiKey(e.target.value)}
                                            style={{ height: '3rem', borderRadius: '10px' }}
                                            disabled={isDeactivated}
                                        />
                                    </div>
                                    
                                    <div className='mt-3' style={{ visibility: 'hidden' }}>
                                        <small>Hidden Component</small>
                                        <input
                                            placeholder='This is a delibrate hidden component!'
                                            style={{ height: '3rem' }}
                                        />
                                    </div>

                                    <div className='container-fluid'>
                                        <div className='row mt-5'>
                                            <div
                                                className='mr-auto mb-3 btn button-style text-dark'
                                                style={{ height: '3rem', borderRadius: '10px' }}
                                                onClick={onCancelModal}
                                            >
                                                <p className='mt-1' style={{ cursor: isDeactivated && 'wait' }}>Cancel</p>
                                            </div>
                                            <AddDeviceButton />
                                            {/* <div
                                                className='ml-auto mb-3 btn btn-color text-white'
                                                style={{ height: '3rem', borderRadius: '10px' }}
                                                onClick={onConfirmAddDeviceHandler}
                                            >
                                                <p className='mt-1'>Add Device</p>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </DialogModal>


                        {/*  */}

                        
                        <div className='container-fluid'>
                            <div className='container-fluid'>
                                <div className='container-fluid'>
                                    
                                    <div className='container-fluid' style={{ visibility: 'visible' }}>
                                        <div className='row d-flex'>

                                            <div className='shadow-sm filterStatusButton mr-auto'>
                                                <div className='container-fluid' onClick={onStatusFIlterTapHandler}>
                                                    <div className='row d-flex'>
                                                        <div className='mt-1 ml-2'><img src={filter} alt='icon' width='20px' /></div>
                                                        <div className='mt-2 ml-2'>Filter Status</div>
                                                        <div className='mt-1 ml-auto mr-2'>
                                                            {isStatusFilterDropdown
                                                                ? <img src={chevronUp} alt='icon' width='25px' />
                                                                : <img src={chevronDown} alt='icon' width='25px' />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    isStatusFilterDropdown &&
                                                    <motion.div
                                                        initial={{ height: '0rem' }}
                                                        animate={{ height: '10rem', transition: { duration: 0.5 } }}
                                                        exit={{ y: window.innerHeight }}
                                                        className='shadow-sm filterStatusDropdown'
                                                    >
                                                        <motion.div
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1, transition: { duration: 1.5 } }}
                                                            exit={{ opacity: 0 }}
                                                            className='container-fluid mt-2'
                                                        >
                                                            <div className='row d-flex'>
                                                                <div className='mt-3 ml-5'>
                                                                    <CheckBox
                                                                        id='allDevices'
                                                                        label='All Devices'
                                                                        value={value}
                                                                        onCheckedHandler={() => setValue('')}
                                                                    />
                                                                </div>
                                                                {/* <div className='mt-3 mr-auto'>All Devices</div> */}
                                                            </div>
                                                            <div className='row d-flex'>
                                                                <div className='mt-2 ml-5'>
                                                                    <CheckBox
                                                                        id='active'
                                                                        label='Active'
                                                                        value={value}
                                                                        onCheckedHandler={() => setValue('')}
                                                                    />
                                                                </div>
                                                                {/* <div className='mt-2 mr-auto text-success'>Active</div> */}
                                                            </div>
                                                            <div className='row d-flex'>
                                                                <div className='mt-2 ml-5'>
                                                                    <CheckBox
                                                                        id='inactive'
                                                                        label='Inactive'
                                                                        value={value}
                                                                        onCheckedHandler={() => setValue('')}
                                                                    />
                                                                </div>
                                                                {/* <div className='mt-2 mr-auto text-muted'>Inactive</div> */}
                                                            </div>
                                                            <div className='row d-flex'>
                                                                <div className='mt-2 ml-5'>
                                                                    <CheckBox
                                                                        id='suspended'
                                                                        label='Suspended'
                                                                        value={value}
                                                                        onCheckedHandler={() => setValue('')}
                                                                    />
                                                                </div>
                                                                {/* <div className='mt-2 mr-auto text-danger'>Suspended</div> */}
                                                            </div>
                                                        </motion.div
                                                        >
                                                    </motion.div>
                                                }
                                            </div>

                                            {/* <div className=' mr-auto mb-3 px-0 btn-empty-light' /> */}

                                            <div className='shadow-sm filterBy ml-auto'>
                                                <div className='container-fluid' onClick={onFilterByTapHandler}>
                                                    <div className='row d-flex'>
                                                        <div className='mt-2 ml-2'>Filter By</div>
                                                        <div className='mt-2 ml-auto mr-2'>
                                                            {isFIlterByDropdown
                                                                ? <img src={chevronUp} alt='icon' width='25px' />
                                                                : <img src={chevronDown} alt='icon' width='25px' />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {
                                                    isFIlterByDropdown &&
                                                    <motion.div
                                                        initial={{ height: 0 }}
                                                        animate={{ height: '22rem', transition: { duration: 0.5 } }}
                                                        exit={{ y: window.innerHeight }}
                                                        className='shadow-sm filterByDropdown'
                                                    >
                                                        <motion.div
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1, transition: { duration: 1.5 } }}
                                                            exit={{ opacity: 0 }}
                                                            className='container-fluid mt-2'
                                                        >
                                                            <div className='row d-flex m-auto'>
                                                                <div className='text-left mt-3'>ICCID</div>
                                                                <div className='input-group rounded'>
                                                                    <input
                                                                        type="search"
                                                                        className="form-control"
                                                                        placeholder="Search..."
                                                                        style={{ height: '3rem', borderRadius: '10px 0px 0px 10px' }}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-color-dark"
                                                                        style={{ height: '3rem', borderRadius: '0px 10px 10px 0px' }}
                                                                    >
                                                                        <i className="fas fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>


                                                            <div className='row d-flex m-auto'>
                                                                <div className='text-left mt-3'>IMSI</div>
                                                                <div className='input-group rounded'>
                                                                    <input
                                                                        type="search"
                                                                        className="form-control"
                                                                        placeholder="Search..."
                                                                        style={{ height: '3rem', borderRadius: '10px 0px 0px 10px' }}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-color-dark"
                                                                        style={{ height: '3rem', borderRadius: '0px 10px 10px 0px' }}
                                                                    >
                                                                        <i className="fas fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            <div className='mt-3'>
                                                                <div className='text-left'>PLANS</div>
                                                                <SelectListGroup
                                                                    bootstrapClass='form-control bg-transparent text-dark'
                                                                    borderRadius='10px'
                                                                    placeholder='Plans'
                                                                    name='plans'
                                                                    value={plan}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === 'null') {
                                                                            setPlan('Choose Plan')

                                                                            return
                                                                        }

                                                                        if (e.target.value === 'Regular Plan') setPlan('Regular Plan')
                                                                        if (e.target.value === 'Premium Plan') setPlan('Premium Plan')
                                                                    }}
                                                                    options={planOptions}
                                                                    // info={answerInfo}
                                                                    disabled={isDeactivated}
                                                                />
                                                            </div>

                                                            <div className="row mr-auto">
                                                                <div className="ml-auto mt-3">
                                                                    <button
                                                                        className="mb-3 btn btn-color-light btn-control text-dark"
                                                                        onClick={onCancelHandler}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                                <div className="ml-3 mt-3">
                                                                    <button
                                                                        className="mb-3 btn btn-color btn-control text-light"
                                                                        onClick={onApplyHandler}
                                                                    >
                                                                        Apply
                                                                    </button>
                                                                </div>
                                                            </div>


                                                        </motion.div>
                                                    </motion.div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                <div className='text-left mt-5 ml-3 mb-5'>
                                    <h5 className="font-weight-bold" style={{ fontSize: '28px' }}>LoRa</h5>
                                </div>
                            </div>


                            <div className='container-fluid'>
                                <div className='container-fluid'>
                                    <div className='mb-1 bg-light text-dark rounded mt-5' style={{ height: '100%' }}>
                                        <div className='container-fluid'>
                                            <div className='row d-flex h-100'>
                                                <button
                                                    className='mr-auto mb-3 mr-5 btn btn-color btn-control text-light'
                                                    onClick={onAddDeviceHandler}
                                                >
                                                    {/* <i class="bi bi-plus"></i> */}
                                                    <img className='mr-3 mb-1' src={plus} alt='icon' width='18px' />
                                                    <span> ADD DEVICE</span>
                                                </button>

                                                <div className='ml-auto mr-1 mb-3 px-0 btn-empty-light' />

                                                {/* <button
                                                    className='ml-1 mb-3 btn btn-color-dark btn-control text-light'
                                                    onClick={onSendSMSHandler}
                                                >
                                                    <span>Send SMS</span>
                                                </button> */}
                                                <button className='ml-1 mb-3 btn btn-success btn-control text-light'>
                                                    <span>Activate</span>
                                                </button>
                                                <button className='ml-1 mb-3 btn btn-danger btn-control text-light'>
                                                    <span>Suspend</span>
                                                </button>
                                                <button className='ml-1 mb-3 btn btn-color btn-control text-light'>
                                                    <span>Create Plan</span>
                                                </button>
                                                <button className='ml-1 mb-3 btn btn-color-dark btn-control text-light'>
                                                    <span>Detarch Plan</span>
                                                </button>
                                                <button className='ml-1 mb-3 btn btn-color btn-control text-light'>
                                                    <span>Download As File</span>
                                                </button>

                                                {/*  */}

                                                <div className='btn-block'>
                                                    <table className='table shadow-sm p-1 mb-1 bg-white text-dark rounded'>
                                                        <thead>
                                                            <tr>
                                                                <th scope='col'></th>
                                                                <th scope='col'></th>
                                                                <th scope='col'>STATUS</th>
                                                                <th scope='col'>ICCID</th>
                                                                <th scope='col'>IMSI</th>
                                                                <th scope='col'>IMEI</th>
                                                                <th scope='col'>PLAN</th>
                                                                <th scope='col'>USAGE</th>
                                                                <th scope='col'>LABEL</th>
                                                            </tr>
                                                        </thead>

                                                        {
                                                            simData.map(datum => (
                                                                <tbody key={datum.id}>
                                                                    <tr>
                                                                        <td></td>
                                                                        <td scope='row'>
                                                                            <CheckBox
                                                                                label={datum.label}
                                                                                id={datum.id}
                                                                                value={value}
                                                                                onCheckedHandler={() => setValue(datum.id)}
                                                                            />
                                                                        </td>
                                                                        {
                                                                            datum.status === 'active' &&
                                                                            <td>
                                                                                <img
                                                                                    className='rounded-circle'
                                                                                    src={active}
                                                                                    alt='company logo'
                                                                                    style={{ width: '20px', marginRight: '8px' }}
                                                                                    title='status'
                                                                                />
                                                                            </td>
                                                                        }
                                                                        {
                                                                            datum.status === 'inactive' &&
                                                                            <td>
                                                                                <img
                                                                                    className='rounded-circle'
                                                                                    src={inactive}
                                                                                    alt='company logo'
                                                                                    style={{ width: '20px', marginRight: '8px' }}
                                                                                    title='status'
                                                                                />
                                                                            </td>
                                                                        }
                                                                        {
                                                                            datum.status === 'suspended' &&
                                                                            <td>
                                                                                <img
                                                                                    className='rounded-circle'
                                                                                    src={suspended}
                                                                                    alt='company logo'
                                                                                    style={{ width: '20px', marginRight: '8px' }}
                                                                                    title='status'
                                                                                />
                                                                            </td>
                                                                        }

                                                                        <td>{datum.iccid}</td>
                                                                        <td>{datum.imsi}</td>
                                                                        <td>{datum.imei}</td>
                                                                        <td>{datum.plan}</td>
                                                                        <td>{datum.usage}</td>
                                                                        <td>{datum.label}</td>
                                                                    </tr>
                                                                </tbody>
                                                            ))
                                                        }
                                                    </table>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </motion.div>

                </PageWithSidebar>
            </div>
        </div>
    )
}

export default LoRa
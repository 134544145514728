import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
// import styled from 'styled-components'
import Navbar from '../layout/Navbar'
import PageWithSidebar from '../../common/PageWithSidebar'
import { Chart } from "react-google-charts"
import DashboardData from '../data/DashboardData'
import { barData, barOptions, data, dataOptions, geoData, pieData, pieOptions, usedData } from '../data/dataJson'
import { getDimensions, setDashItem, setSimClicked, setSimItemDropdown } from '../../features/appSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import CheckBox from '../../common/CheckBox'
import ProfileDropdown from '../../common/ProfileDropdown'

const Dashboard = () => {
  
  const { windowDimension } = useSelector(state => state.app.value)

  const [value, setValue] = useState('')

  const dispatch = useDispatch()

  const setDimensions = () => {
    dispatch(getDimensions({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    }))
  }

  useEffect(() => {
    // setDimensions()
    dispatch(setSimItemDropdown(false))
    dispatch(setSimClicked(false))
    dispatch(setDashItem())
  }, [])

  return (
    <div>
      <Navbar key='nav' />
      <div className="container-fluid">
        <PageWithSidebar>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
            className='container-fluid'
          >
            {/* <div className='row'>
              <div className='ml-auto'>
                <ProfileDropdown />
              </div>
            </div> */}

            <div className='container-fluid'>
              <div className='container-fluid'>
                <div className='text-left mt-5 mb-5'>
                  <h5 className="font-weight-bold" style={{ fontSize: '28px' }}>Dashboard</h5>
                </div>
              </div>

              <div className='container-fluid'>
                <div className='container-fluid h-100 mb-5'>
                  
                  {
                    windowDimension.winWidth < 541 &&
                    <div className='row d-flex h-100'>
                      <div className='mr-auto'>
                        <div className='shadow-sm p-3 mb-3 bg-white rounded' style={{ height: '6rem', width: '13rem' }}>
                          <h5>
                            <small style={{ fontSize: '21px' }}>All SIMs</small>
                            <div className='col-md-12 font-weight-bold' style={{ fontSize: '38px' }}>120</div>
                          </h5>
                        </div>
                      </div>

                      <div className='mr-auto'>
                        <div className='shadow-sm p-3 mb-3 bg-white text-success rounded' style={{ height: '6rem', width: '13rem' }}>
                          <h5>
                            <small style={{ fontSize: '21px' }}>Active SIMs</small>
                            <div className='col-md-12 font-weight-bold' style={{ fontSize: '38px' }}>100</div>
                          </h5>
                        </div>
                      </div>


                      {/* <div className="ml-auto mr-auto"></div> */}

                      <div className='mr-auto'>
                        <div className='shadow-sm p-3 mb-3 bg-white text-muted rounded' style={{ height: '6rem', width: '13rem' }}>
                          <h5>
                            <small style={{ fontSize: '21px' }}>Inactive SIMs</small>
                            <div className='col-md-12 font-weight-bold' style={{ fontSize: '38px' }}>15</div>
                          </h5>
                        </div>
                      </div>

                      <div className='mr-auto'>
                        <div className='shadow-sm p-3 mb-3 bg-white text-danger rounded' style={{ height: '6rem', width: '13rem' }}>
                          <h5>
                            <small style={{ fontSize: '21px' }}>Deactivated SIMs</small>
                            <div className='col-md-12 font-weight-bold' style={{ fontSize: '38px' }}>5</div>
                          </h5>
                        </div>
                      </div>
                    </div>
                  }

                  {
                    windowDimension.winWidth >= 541 &&
                    <div className='row d-flex h-100'>
                      <div className='mr-auto'>
                        <div className='shadow-sm p-3 mb-3 bg-white rounded' style={{ height: '6rem', width: '13rem' }}>
                          <h5>
                            <small style={{ fontSize: '21px' }}>All SIMs</small>
                            <div className='col-md-12 font-weight-bold' style={{ fontSize: '38px' }}>120</div>
                          </h5>
                        </div>
                      </div>

                      <div className='ml-auto'>
                        <div className='shadow-sm p-3 mb-3 bg-white text-success rounded' style={{ height: '6rem', width: '13rem' }}>
                          <h5>
                            <small style={{ fontSize: '21px' }}>Active SIMs</small>
                            <div className='col-md-12 font-weight-bold' style={{ fontSize: '38px' }}>100</div>
                          </h5>
                        </div>
                      </div>

                      <div className="ml-auto mr-auto"></div>

                      <div className='mr-auto'>
                        <div className='shadow-sm p-3 mb-3 bg-white text-muted rounded' style={{ height: '6rem', width: '13rem' }}>
                          <h5>
                            <small style={{ fontSize: '21px' }}>Inactive SIMs</small>
                            <div className='col-md-12 font-weight-bold' style={{ fontSize: '38px' }}>15</div>
                          </h5>
                        </div>
                      </div>

                      <div className='ml-auto'>
                        <div className='shadow-sm p-3 mb-3 bg-white text-danger rounded' style={{ height: '6rem', width: '13rem' }}>
                          <h5>
                            <small style={{ fontSize: '21px' }}>Deactivated SIMs</small>
                            <div className='col-md-12 font-weight-bold' style={{ fontSize: '38px' }}>5</div>
                          </h5>
                        </div>
                      </div>
                    </div>
                  }

                </div>
              </div>

              {/* <Chart
                chartType="ScatterChart"
                data={[["Age", "Weight"], [4, 5.5], [8, 12]]}
                width="100%"
                height="400px"
                legendToggle
              /> */}

              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-7 mb-3'>
                    <Chart
                      chartType="ColumnChart"
                      width="100%"
                      height="400px"
                      data={barData}
                      options={barOptions}
                    />
                  </div>
                  <div className='col-md-5 mb-3'>
                    <Chart
                      chartType="PieChart"
                      width="100%"
                      height="400px"
                      data={pieData}
                      options={pieOptions}
                    />
                  </div>
                </div>
              </div>

              <div className='container-fluid mt-3'>
                <table className='table shadow-sm p-1 mb-1 bg-white text-dark rounded'>
                  <thead>
                    <tr>
                      <th scope='col'></th>
                      <th scope='col'></th>
                      <th scope='col'>#</th>
                      <th scope='col'>SIM Name</th>
                      <th scope='col'>ICCID Number</th>
                      <th scope='col'>Status</th>
                    </tr>
                  </thead>

                  {
                    usedData.map(datum => (
                      <tbody key={datum.id}>
                        <tr>
                          <td></td>
                          <td scope='row'>
                            <CheckBox
                              id={datum.id}
                              value={value}
                              onCheckedHandler={() => setValue(datum.id)}
                            />
                          </td>
                          <td>{datum.id}</td>
                          <td>{datum.deviceName}</td>
                          <td>{datum.iccid}</td>
                          <td>
                            {
                              datum.status === 'Active' && <div className="card color-border-active text-success">Active</div>
                            }
                            {
                              datum.status === 'Inactive' && <div className="card color-border-inactive text-muted">Inactive</div>
                            }
                            {
                              datum.status === 'Deactivated' && <div className="card color-border-deactivated text-danger">Deactivated</div>
                            }
                          </td>
                        </tr>
                      </tbody>
                    ))
                  }

                </table>
              </div>

              <div className='col-md-12 mt-5'>
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={data}
                  options={dataOptions}
                />
              </div>

              <div className='col-md-12 mt-5'>
                <Chart
                  chartEvents={[
                    {
                      eventName: "select",
                      callback: ({ chartWrapper }) => {
                        const chart = chartWrapper.getChart();
                        const selection = chart.getSelection();
                        if (selection.length === 0) return;
                        const region = geoData[selection[0].row + 1];
                        console.log("Selected : " + region);
                      },
                    },
                  ]}
                  chartType="GeoChart"
                  width="100%"
                  height="400px"
                  data={geoData}
                // options={dataOptions}

                />
              </div>
            </div>

          </motion.div>

        </PageWithSidebar>
      </div>
    </div>
  )
}

// const CardStyle = styled.div`
//   margin: 4rem, 0rem;
// `;

export default Dashboard
import React from 'react'
import Spinner from '../../src/common/Spinner'

const Loading = () => {
    return (
        <div className='overlay-effect d-flex flex-wrap justify-content-center align-items-center'>
            <Spinner useSpinnarLarge={true} />
        </div>
    )
}

export default Loading
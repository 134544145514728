import React from 'react'
import classnames from 'classnames'
import { motion } from 'framer-motion'

const SelectListGroup = ({
    name,
    value,
    bootstrapClass,
    borderRadius,
    height,
    refInput,
    error,
    info,
    onChange,
    onCLick,
    options,
    disabled
}) => {
    const selectOptions = options.map(option => (
        <option key={option.label} value={option.value}>{option.label}</option>
    ))

    return (
        <div className="form-group">
            <select
                name={name}
                className={classnames(bootstrapClass, { "is-invalid": error })}
                value={value}
                onChange={onChange}
                onClick={onCLick}
                ref={refInput}
                disabled={disabled}
                style={{ height: height ? height : '3rem', borderRadius: borderRadius}}
            >
                {selectOptions}
            </select>
            {
                info &&
                <motion.small
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { duration: 1 } }}
                    exit={{ opacity: 0 }}

                    className='form-text text_color_warning'>
                    {info}
                </motion.small>
            }
            {error && <div className='invalid-feedback'>{error}</div>}
        </div>
    )
}

export default SelectListGroup
import React from 'react'
import { motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import { setNetworkStatus, setSimSpecsStyle } from '../../../../features/appSlice'
import { usePaystackPayment } from "react-paystack"
import { useEffect } from 'react'
import ModalAction from '../../../../common/ModalAction'
import { useState } from 'react'
import useNetworkStatus from '../../../../common/NetworkHook'

const Checkout = () => {

  const { networkStatus } = useSelector(state => state.app.value)
  const { isOnline } = useNetworkStatus()

  // console.log(isOnline)

  const dispatch = useDispatch()


  const [alertModal, setAlertModal] = useState(false)
  const [errorButton, setErrorButton] = useState(false)

  const config = {
    reference: 'gentroconnect_' + (new Date()).getTime().toString(),
    metadata: {
      custom_fields: [
        {
          'display_name': 'Description',
          'variable_name': 'Description',
          'value': 'SIM Purchase'
        },

        {
          'display_name': 'Phone Number',
          'variable_name': 'Phone Number',
          'value': '+2348093530000'
        },

        {
          'display_name': 'Email',
          'variable_name': 'Email',
          'value': 'paxian.pi@gmail.com'
        }
      ]
    },
    email: 'paxian.pi@gmail.com',
    amount: 50000000,
    publicKey: 'pk_test_c6654b538da3016e1c45af299bd86f97f2401750'
    // publicKey: 'pk_test_49be27292bc7c5cc6f1959257d5e2a8056658510'    // For Paxian Solutions
  };

  // name this function whatever you want
  const onSuccess = (reference) => {
    console.log(reference)

    setTimeout(() => {
      dispatch(setSimSpecsStyle())
    }, 1500)
  }

  // name this function whatever you want
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log('closed')
  }

  const PaystackHook = () => {

    const initializePayment = usePaystackPayment(config)

    return (
      <div
        className="mb-4 btn btn-color text-white"
        style={{ height: '3rem', width: '10rem', borderRadius: '10px' }}
        onClick={() => { initializePayment(onSuccess, onClose) }}
      >
        <p className="mt-1">Confirm</p>
      </div>
    )
  }



  const onBackClickHandler = () => {
    dispatch(setSimSpecsStyle())
  }

  const onaConfirmHandler = () => {
    // Impliment paystack
  }

  useEffect(() => {

    // if (!isOnline) {
    //   dispatch(setNetworkStatus(navigator.onLine))
    //   setErrorButton(true)
    // }
    // else {
    //   setErrorButton(false)
    // }

  }, [])

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className="container-fluid"
    >
      <ModalAction
        body='Internet connection error!'
        show={alertModal}
        onHideHandler={() => setAlertModal(false)}
      />

      <div className='container-fluid'>
        <div className="shadow-sm p-2 mb-1 bg-white text-dark rounded mt-1" style={{ height: '100%' }}>
          <div className="row d-flex ml-4 mr-4">
            <div className="container">
              <div className="container mb-5">

                <div className="text-left mt-5">
                  <h5 className='font-weight-bold'>Please review your entries</h5>
                </div>

                {/*  */}

                <div className="text-left mt-5">
                  <h6 className='font-weight-bold'>SIM Specification</h6>
                </div>
                <div className="row text-left mt-4">
                  <div className="col-md-3 txt-color-light">
                    <h5>Country</h5>
                  </div>
                  <div className="col-md-2">
                    <h5>Nigeria NG</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Selected SIM</h5>
                  </div>
                  <div className="col-md-2">
                    <h5>MTN NG</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Quantity</h5>
                  </div>
                  <div className="col-md-2">
                    <h5>2</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Selected Plan</h5>
                  </div>
                  <div className="col-md-2">
                    <h5>Regular</h5>
                  </div>
                </div>
                
                {/*  */}
                
                <div className="text-left mt-5">
                  <h6 className='font-weight-bold'>Delivery Details</h6>
                </div>
                <div className="row text-left mt-4">
                  <div className="col-md-3 txt-color-light">
                    <h5>Country</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>Nigeria NG</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>City</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>Lagos LA</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Street</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>Simpson's Street Victoria Island</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>House Number</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>Nigeria NG</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>ZIP Code</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>100010</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Company Name</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>Gentro IoT</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Company Phone</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>012345678</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Email</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>gentroiot@gmail.com</h5>
                  </div>
                </div>
                <div className="row text-left">
                  <div className="col-md-3 txt-color-light">
                    <h5>Personal Phone</h5>
                  </div>
                  <div className="col-md-6">
                    <h5>+2348091234567</h5>
                  </div>
                </div>
                
                {/*  */}
                
                <div className="text-left mt-5">
                  <div
                    className="mb-4 mr-4 btn button-style text-dark"
                    style={{ height: '3rem', width: '10rem', borderRadius: '10px' }}
                    onClick={onBackClickHandler}
                  >
                    <p className="mt-1">Cancel</p>
                  </div>
                  
                  {
                    isOnline
                      ? <PaystackHook />
                      : <div
                        className="mb-4 mr-4 btn button-style text-dark"
                        style={{ height: '3rem', width: '10rem', borderRadius: '10px' }}
                      >
                        <p className="mt-1">Network Error</p>
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </motion.div>
  )
}

export default Checkout
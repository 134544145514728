import React from 'react'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setViewGroupClicked, setViewGroupData } from '../../../features/appSlice'
import ViewGroup from './ViewGroup'
import { groups } from '../../data/dataJson'

const MyGroups = () => {

  const { isViewGroupClicked } = useSelector(state => state.app.value)

  const dispatch = useDispatch()

  const onViewHandler = (data) => {
    dispatch(setViewGroupClicked(true))
    dispatch(setViewGroupData({ planName: data.planName, description: data.desc, numberOfsims: data.numberOfsims }))
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
    >
      <div className='container-fluid'>
        {
          isViewGroupClicked
            ? <ViewGroup />
            : <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.5 } }}
              exit={{ opacity: 0 }}
              className='p-3 mb-1 bg-white text-dark rounded' style={{ height: '100%' }}
            >
              <div className='container-fluid'>
                <div className='container-fluid'>
                  <h5 className="font-weight-bold">My Groups</h5>
                </div>
                
                <div className='container-fluid m-5 text-center'>
                  <div className='row'>
                    
                    {
                      groups.map(datum =>
                        <div key={datum.id} className='mb-2 mr-auto'>
                          <div
                            className='shadow-sm py-3 mt-3 mb-1 bg-white text-dark form-control scale-transition'
                            style={{ height: '12rem', width: '16rem', cursor: 'pointer' }}
                            onClick={() => onViewHandler(datum)}
                          >
                            <h5 className='font-weight-bold mt-4'>{datum.planName}</h5>
                            <div>
                              <small>{datum.desc}</small>
                            </div>
                            <div className='mt-3'>
                              <small className='text-success'><small>TOTAL SIM CARDS: {datum.numberOfsims}</small></small>
                            </div>
                          </div>
                        </div>)
                    }

                  </div>
                </div>
              </div>
            </motion.div>
        }
      </div>
    </motion.div>
  )
}

export default MyGroups

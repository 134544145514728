import React from 'react'
import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { setViewClicked, setViewPlanData } from '../../../features/appSlice'
import { useSelector } from 'react-redux'
import ViewPlan from './ViewPlan'
import { plans } from '../../data/dataJson'

const MyPlans = ({ planType }) => {

    const { isViewClicked, viewData } = useSelector(state => state.app.value)

    // console.log(viewData.planName)

    const dispatch = useDispatch()
    
    const onViewHandler = (planName) => {
        dispatch(setViewClicked(true))
        dispatch(setViewPlanData({ planName: planName }))
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
            className='container-fluid'
        >
            <div className='container-fluid'>
                {
                    isViewClicked
                        ? <ViewPlan planType={planType} />
                        : <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 0.5 } }}
                            exit={{ opacity: 0 }}
                            className='p-3 mb-1 bg-white text-dark rounded' style={{ height: '100%' }}
                        >
                            <div className='container-fluid'>
                                <div className='container-fluid mt-4'>
                                    <div className='container-fluid'>
                                        <h5 className="font-weight-bold">My Plans</h5>
                                    </div>
                                </div>

                                <div className='container-fluid m-3 text-center'>
                                    <div className='row'>
                                        {
                                            plans.map(datum =>
                                                <div key={datum.id} className='mb-2 mr-auto'>
                                                    <div
                                                        className='shadow-sm p-1 mb-1 bg-white text-dark rounded mt-5'
                                                        style={{ height: '12rem', width: '10rem' }}
                                                    >
                                                        <h5 className='font-weight-bold text-center mt-4'>
                                                            {datum.size}<small><small>{datum.units}</small></small>
                                                        </h5>
                                                        <p>{datum.subscription}</p>
                                                        <h5>{datum.type}</h5>
                                                        <button
                                                            className='btn btn-color btn-block btn-control text-light'
                                                            onClick={() => onViewHandler(datum.type)}
                                                        >
                                                            View
                                                        </button>
                                                    </div>
                                                </div>)
                                        }

                                    </div>
                                </div>
                            </div>
                        </motion.div>
                }
            </div>
        </motion.div>
    )
}

export default MyPlans
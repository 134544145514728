import React from 'react'
import { useState } from 'react'
import { motion } from 'framer-motion'
import SelectListGroup from '../../../common/SelectListGroup'
import { useDispatch } from 'react-redux'
import useNetworkStatus from '../../../common/NetworkHook'
import { setMyGroup, setUsers } from '../../../features/appSlice'
import Spinner from '../../../common/Spinner'
import SuccessModal from '../../../common/SuccessModal'
import TextArea from '../../../common/TextArea'
import warningInfo from '../../../assets/warning-info.png'
import { useSelector } from 'react-redux'
import DialogModal from '../../../common/DialogModal'


const CreateNewGroup = () => {

    const { isOnline } = useNetworkStatus()

    console.log(isOnline)

    const dispatch = useDispatch()

    const [isDeactivated, setDeactivated] = useState(false)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [description, setDescription] = useState('')
    const [email, setEmail] = useState('')
    const [groupName, setGroupName] = useState('')
    const [addSIM, setAddSIM] = useState('')
    const [isGroupCreated, setGroupCreated] = useState(false)
    const [successModal, showSuccessModal] = useState(false)

    const addSIMOptions = [
        { label: 'Add SIM', value: 'null' },
        { label: 'SIM1', value: 'SIM1' },
        { label: 'SIM2', value: 'SIM2' },
        { label: 'SIM3', value: 'SIM3' }
    ]

    const onCancelHandler = () => {
        if (isDeactivated) return
        dispatch(setMyGroup())
    }

    const onCreateGroupHandler = () => {
        setDeactivated(true)
        setGroupCreated(true)

        setTimeout(() => {
            setGroupCreated(false)
            showSuccessModal(true)
            setDeactivated(false)
        }, 3000);
    }


    const onModalHideHandler = () => {
        showSuccessModal(false)
        setTimeout(() => dispatch(setMyGroup()), 1000);
    }

    const CreateGroupButton = () => {
        return (
            <>
                {
                    isGroupCreated
                        ?
                        <motion.button
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1, transition: { duration: 1 } }}
                            exit={{ opacity: 0 }}
                            className="col-md-5 ml-auto mb-3 btn btn-light text-white"
                            disabled={isDeactivated}
                            style={{ height: '3rem', borderRadius: '10px' }}
                        >
                            <Spinner />
                        </motion.button>
                        :
                        <div
                            className='col-md-5 ml-auto mb-3 btn btn-color text-white'
                            style={{ height: '3rem', borderRadius: '10px' }}
                            onClick={onCreateGroupHandler}
                        >
                            <p className='mt-1'>Create Group</p>
                        </div>
                }
            </>
        )
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
        >
            <SuccessModal
                message='Group created successfully!'
                show={successModal}
                onHideHandler={onModalHideHandler}
            />



            <div className='container-fluid'>
                <div className='shadow-sm p-2 mb-1 bg-white text-dark rounded' style={{ height: '100%' }}>

                    <div className='text-left mt-3 ml-3'>
                        <h5 className="font-weight-bold" style={{ fontSize: '20px' }}>Create New User</h5>
                    </div>

                    <div className="row mr-auto ml-auto">
                        <div className="col-md-6 mb-2">

                            <div className='text-left mt-4'>
                                <small>Group Name</small>
                            </div>
                            <div className='input-group rounded'>
                                <input
                                    type="name"
                                    className="form-control"
                                    placeholder='Enter First Name'
                                    value={groupName}
                                    onChange={(e) => setGroupName(e.target.value)}
                                    style={{ height: '3rem', borderRadius: '10px' }}
                                    disabled={isDeactivated}
                                />
                            </div>

                            <div className='text-left mt-3'>
                                <small>Focus</small>
                            </div>
                            <TextArea
                                className='form-control'
                                placeholder='Description...'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                disabled={isDeactivated}
                            />


                            <div style={{ visibility: 'hidden' }}>
                                <small>Hidden</small>
                                <div className='input-group rounded' />
                            </div>

                        </div>

                        {/*  */}

                        <div className="col-md-6 mb-2">
                            <div className='text-left mt-4'>
                                <small>SIM</small>
                            </div>
                            <SelectListGroup
                                borderRadius='10px'
                                bootstrapClass='form-control form-control-lg bg-transparent text-dark'
                                placeholder='Add SIM'
                                name='addSim'
                                value={addSIM}
                                onChange={(e) => {
                                    if (e.target.value === 'null') {
                                        setAddSIM(null)

                                        return
                                    }

                                    if (e.target.value === 'SIM1') setAddSIM('SIM1')
                                    if (e.target.value === 'SIM2') setAddSIM('SIM2')
                                    if (e.target.value === 'SIM3') setAddSIM('SIM3')
                                }}
                                options={addSIMOptions}
                                disabled={isDeactivated}
                            />

                            <div className='mt-5' style={{ visibility: 'hidden' }}>
                                <div style={{ visibility: 'hidden' }}>
                                    <small>Hidden</small>
                                    <div className='input-group rounded mt-5' />
                                </div>
                            </div>
                            
                            <div className='container-fluid'>
                                <div className='row mt-5'>
                                    <div
                                        className='col-md-5 mr-auto mb-3 btn button-style text-dark'
                                        style={{ height: '3rem', borderRadius: '10px' }}
                                        onClick={onCancelHandler}
                                    >
                                        <p className='mt-1' style={{ cursor: isDeactivated && 'wait' }}>Cancel</p>
                                    </div>
                                    
                                    {
                                        isOnline
                                            ? <CreateGroupButton />
                                            : <div
                                                className='col-md-5 ml-auto mb-3 btn button-style text-dark'
                                                style={{ height: '3rem', borderRadius: '10px' }}
                                            >
                                                <p className='mt-1'>Network Error</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </motion.div>

    )
}

export default CreateNewGroup
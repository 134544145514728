import React from 'react'
import SimData from '../../data/SimData'
import { simData } from '../../data/dataJson'
import { motion } from 'framer-motion'
import CheckBox from '../../../common/CheckBox'
import { useState } from 'react'
import active from '../../../assets/active.png'
import inactive from '../../../assets/inactive.png'
import suspended from '../../../assets/suspended.png'
import menu from '../../../assets/menu.png'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { setManageSimItem, setManageSimSelected } from '../../../features/appSlice'
import { useNavigate } from 'react-router-dom'

const ManageSIMs = () => {

    const [value, setValue] = useState('')

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const onSendSMSHandler = () => {
        navigate('/send-sms')
    }

    useEffect(() => {
        dispatch(setManageSimItem())
    }, [])

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
            exit={{ opacity: 0 }}
        >
            <div className='container-fluid'>
                <div className='container-fluid'>
                    <div className=''>
                        <div className='container-fluid'>
                            <div className='mb-1 bg-light text-dark rounded mt-5' style={{ height: '100%' }}>
                                <div className='container-fluid'>
                                    <div className='row d-flex h-100'>
                                        <button className='mr-auto mb-3 mr-5 btn btn-color btn-control text-light'>
                                            {/* <i class="bi bi-list"></i> */}
                                            <img className='mr-3 mb-1' src={menu} alt='icon' width='18px' />
                                            <span> MANAGE SIMs</span>
                                        </button>

                                        <div className='ml-auto mr-1 mb-3 px-0 btn-empty-light' />

                                        <button
                                            className='ml-1 mb-3 btn btn-color-dark btn-control text-light'
                                            onClick={onSendSMSHandler}
                                        >
                                            <span>Send SMS</span>
                                        </button>
                                        <button className='ml-1 mb-3 btn btn-success btn-control text-light'>
                                            <span>Activate</span>
                                        </button>
                                        <button className='ml-1 mb-3 btn btn-danger btn-control text-light'>
                                            <span>Suspend</span>
                                        </button>
                                        <button className='ml-1 mb-3 btn btn-color btn-control text-light'>
                                            <span>Create Plan</span>
                                        </button>
                                        <button className='ml-1 mb-3 btn btn-color-dark btn-control text-light'>
                                            <span>Detarch Plan</span>
                                        </button>
                                        <button className='ml-1 mb-3 btn btn-color btn-control text-light'>
                                            <span>Download As File</span>
                                        </button>

                                        {/*  */}

                                        <div className='btn-block'>
                                            <table className='table shadow-sm p-1 mb-1 bg-white text-dark rounded'>
                                                <thead>
                                                    <tr>
                                                        <th scope='col'></th>
                                                        <th scope='col'></th>
                                                        <th scope='col'>STATUS</th>
                                                        <th scope='col'>ICCID</th>
                                                        <th scope='col'>IMSI</th>
                                                        <th scope='col'>IMEI</th>
                                                        <th scope='col'>PLAN</th>
                                                        <th scope='col'>USAGE</th>
                                                        <th scope='col'>LABEL</th>
                                                    </tr>
                                                </thead>

                                                {simData.map(datum => (
                                                    <tbody key={datum.id}>
                                                        <tr>
                                                            <td></td>
                                                            <td scope='row'>
                                                                <CheckBox
                                                                    label={datum.label}
                                                                    id={datum.id}
                                                                    value={value}
                                                                    onCheckedHandler={() => setValue(datum.id)}
                                                                />
                                                            </td>
                                                            {
                                                                datum.status === 'active' &&
                                                                <td>
                                                                    <img
                                                                        className='rounded-circle'
                                                                        src={active}
                                                                        alt='company logo'
                                                                        style={{ width: '20px', marginRight: '8px' }}
                                                                        title='status'
                                                                    />
                                                                </td>
                                                            }
                                                            {
                                                                datum.status === 'inactive' &&
                                                                <td>
                                                                    <img
                                                                        className='rounded-circle'
                                                                        src={inactive}
                                                                        alt='company logo'
                                                                        style={{ width: '20px', marginRight: '8px' }}
                                                                        title='status'
                                                                    />
                                                                </td>
                                                            }
                                                            {
                                                                datum.status === 'suspended' &&
                                                                <td>
                                                                    <img
                                                                        className='rounded-circle'
                                                                        src={suspended}
                                                                        alt='company logo'
                                                                        style={{ width: '20px', marginRight: '8px' }}
                                                                        title='status'
                                                                    />
                                                                </td>
                                                            }

                                                            <td>{datum.iccid}</td>
                                                            <td>{datum.imsi}</td>
                                                            <td>{datum.imei}</td>
                                                            <td>{datum.plan}</td>
                                                            <td>{datum.usage}</td>
                                                            <td>{datum.label}</td>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </motion.div>
    )
}

export default ManageSIMs